import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  value: string | number | JSX.Element;
};

export const ProductInfoBlock: React.FC<Props> = ({ label, value }) => (
  <>
    <Typography variant='body5Light'>
      {label}
    </Typography>
    <Typography variant='body5Dark' fontSize='10px' fontWeight={'normal'}>
      {value}
    </Typography>
  </>
);
