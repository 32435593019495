import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';

export const Report: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <RestrictedComponent
      permission="create_reports"
      message={t('main:errors.notPermitted')}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1056}
        height="60vh"
        mx="auto"
        justifyContent="center"
        gap={theme.spacing(5)}
        p={theme.spacing(5, 0)}
      >
        {t('main:report.title')}
      </Box>
    </RestrictedComponent>
  );
};
