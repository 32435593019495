import React, { useEffect, useState } from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { Lock } from '@mui/icons-material';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInputField } from '../ui-components/formik/FormikInputField';
import { resetPassword } from '../utils/loginFunctions';
import { Button } from '../ui-components/Button';


export const ResetPassword: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [token, setToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [responseStatus, setResponseStatus] = useState<number | null>();
  
  const validationSchema = yup.object({
    email: yup.string().required(),
    token: yup.string().required(),
    password: yup.string().min(8).required(),
    password_confirmation: yup.string()
      .oneOf([yup.ref('password'), null], t('main:errors.passwordConfirmation') ?? ''),
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setToken(params.get('token') ?? '');
    setEmail(params.get('email') ?? '');
  }, []);


  return (
    <Formik
      initialValues={{
        email: email,
        token: token,
        password: '',
        password_confirmation: '',
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async data => {
        await resetPassword(data).then(response => setResponseStatus(response.status));
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>

          <Box 
            display="flex"
            flexDirection="column"
            maxWidth={theme.custom.maxContentWidth}
            sx={{
              mx: 'auto',
              py: theme.spacing(6),
              gap: theme.spacing(3),
            }}
          >
            <Typography
              variant="h2"
            >
              {t('main:resetPassword.title')}
            </Typography>
      
            <Snackbar
              open={!!responseStatus}
              autoHideDuration={2000}
              onClose={() => setResponseStatus(null)}
              message={responseStatus === 204 ? 'Password Reset Successful' : 'Error while resetting password'}
            />

            <FormikInputField
              name="password"
              icon={<Lock />}
              autoFocus={true}
              fullWidth={true}
              sx={{
                maxWidth: '300px',
              }}
              type="password"
              placeholder={t<string>('main:resetPassword.password1')}
            />

            <FormikInputField
              name="password_confirmation"
              icon={<Lock />}
              autoFocus={true}
              fullWidth={true}
              sx={{
                maxWidth: '300px',
              }}
              type="password"
              placeholder={t<string>('main:resetPassword.password2')}
            />

            <Button
              variant="actionButton"
              isLoading={isSubmitting}
              onClick={submitForm}
            >
              {t('main:buttons.resetPassword')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
