import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '../../../ui-components/Button';
import theme from '../../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { SnackBar, SnackBarProps } from './SnackBar';
import ClientService from '../../../services/ClientService';
import { Address } from '../../../types/Address';
import { InputField } from './InputField';
import { FormikReactSelect } from './FormikReactSelect';
import { countryOptions } from '../../../utils/constants';

type AddAltAddressProps = {
  openModal: boolean;
  setOpenModal(openModal: boolean): void;
  alternativeDeliveryAddresses: Address[];
  setAlternativeDeliveryAddresses(
    alternativeDeliveryAddresses: Address[]
  ): void;
  companyId: number;
  handleClickCloseAddAddress?: () => void;
};

export const AddAlternativeAddressModal: React.FC<AddAltAddressProps> = ({
  openModal,
  setOpenModal,
  alternativeDeliveryAddresses,
  setAlternativeDeliveryAddresses,
  companyId,
  handleClickCloseAddAddress,
}) => {
  const { t } = useTranslation(['main']);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });
  const validationSchema = yup.object({
    deliveryAlternativeCompanyName: yup.string().max(255).required(),
    deliveryAlternativeAddressStreet: yup.string().max(255).required(),
    deliveryAlternativeAddressStreetNumber: yup.string().max(255).required(),
    deliveryAlternativeAddressPostCode: yup.string().max(255).required(),
    deliveryAlternativeAddressCity: yup.string().max(255).required(),
    deliveryAlternativeAddressCountry: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Paper elevation={2}>
      <Formik
        initialValues={{
          deliveryAlternativeCompanyName: '',
          deliveryAlternativeAddressStreet: '',
          deliveryAlternativeAddressStreetNumber: '',
          deliveryAlternativeAddressCity: '',
          deliveryAlternativeAddressPostCode: '',
          deliveryAlternativeAddressCountry: countryOptions[0].label,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { resetForm }) => {
          try {
            const submitAlternativeDeliveryAddress: Address = {
              company_id: companyId,
              company_name: data.deliveryAlternativeCompanyName,
              street_name: data.deliveryAlternativeAddressStreet,
              street_number: data.deliveryAlternativeAddressStreetNumber,
              city: data.deliveryAlternativeAddressCity,
              post_code: data.deliveryAlternativeAddressPostCode,
              country: data.deliveryAlternativeAddressCountry,
              type_id: 2,
            };
            const newAlternativeDeliveryAddress: Address =
              await ClientService.createDeliveryAddress(
                submitAlternativeDeliveryAddress,
              );
            setAlternativeDeliveryAddresses([
              ...alternativeDeliveryAddresses,
              newAlternativeDeliveryAddress,
            ]);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'success',
              message: t('main:profile.successfulEdit') || '',
            });
            if (handleClickCloseAddAddress) {
              handleClickCloseAddAddress();
            } else {
              setOpenModal(false);
            }
            setTimeout(() => {
              setOpenModal(false);
            }, 2000);
            resetForm();
          } catch (error) {
            console.log(error);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'error',
              message: t('main:profile.errorEdit') || '',
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Dialog
              open={openModal}
              onClose={handleCloseModal}
              maxWidth='md'
              fullScreen={fullScreen}
              PaperProps={{
                style: {
                  minHeight: '30%',
                  minWidth: '40%',
                  padding: '2rem',
                  paddingTop: '1rem',
                  gap: theme.spacing(4),
                },
              }}
            >
              <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
                <DialogTitle
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0',
                  }}
                >
                  {t('main:profile.addDeliveryAlternativeAddress')}
                  <IconButton aria-label='close' onClick={handleCloseModal}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </DialogTitle>
                <Box display='flex' gap={theme.spacing(1)}>
                  <InputField
                    name={'deliveryAlternativeCompanyName'}
                    fullWidth
                  />
                </Box>
                <Box display='flex' gap={theme.spacing(1)}>
                  <InputField
                    name={'deliveryAlternativeAddressStreet'}
                    fullWidth
                  />
                  <InputField name={'deliveryAlternativeAddressStreetNumber'} />
                </Box>
                <Box display='flex' gap={theme.spacing(2)} width={'100%'}>
                  <Box>
                    <FormikReactSelect
                      name={'deliveryAlternativeAddressCountry'}
                      options={countryOptions}
                      initialValue={countryOptions[0].label}
                    />
                  </Box>
                  <Box width={'100%'}>
                    <InputField
                      name={'deliveryAlternativeAddressCity'}
                      fullWidth
                    />
                  </Box>
                  <InputField name={'deliveryAlternativeAddressPostCode'} fullWidth />
                </Box>
              </Box>
              <Button
                variant='actionButtonGreen'
                isLoading={isSubmitting}
                onClick={submitForm}
                sx={{
                  p: theme.spacing(1.5, 9),
                }}
              >
                {t('main:profile.submitButton')}
              </Button>
            </Dialog>
            {openSnackBar.openSnackBar && (
              <SnackBar
                type={openSnackBar.type}
                openSnackBar={openSnackBar.openSnackBar}
                handleCloseSnackbar={handleCloseSnackbar}
                message={openSnackBar.message}
              />
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
