import ProductTableRow from '../pages/ProductConfigurationPage/ProductTableRow';
import { RollData, RowData } from '../pages/ProductConfigurationPage/interfaces';

type NewRow = {
  key: string;
  _itemId: number;
  id: number;
  materialNumber: string;
  rollWidth: number;
  rollCount: number;
  rollHeight: number;
  total: number;
}

export const multiplyRows = (rows: ProductTableRow[]) => rows.reduce((res: NewRow[], row: ProductTableRow) => {
  if (row.rollCount) {
    res = [
      ...res,
      ...new Array(row.rollCount)
        .fill(row)
        .map(row => ({
          ...row,
          key: crypto.randomUUID(),
        })),
    ];
  }
  return res;
}, []);

export const addOffcut = (newRows: NewRow[], usableWidth: number ) => {

  const rolls: RollData[] = [];

  newRows.sort((a, b) => b.rollWidth - a.rollWidth);
  newRows.forEach((row: RowData) => {
    const roll: RollData | undefined = rolls.find((roll: RollData) => roll.rollWidth + row.rollWidth <= usableWidth + 0.001);
    if (roll) {
      roll.rows.push(row);
      roll.rollWidth = Math.round((roll.rollWidth + row.rollWidth) * 1000) / 1000;
    } else {
      rolls.push({ key: crypto.randomUUID(), rollWidth: row.rollWidth, rows: [row], rollHeight: row.rollHeight });
    }
  });
  rolls.forEach((roll: RollData) => {
    if (roll.rollWidth !== usableWidth) {
      roll.rows.push({
        key: crypto.randomUUID(),
        materialNumber: 'offcut',
        rollCount: 0,
        rollHeight: 0,
        rollWidth: usableWidth - (roll.rollWidth || 0),
        total: 0,
      });
    }
  });
  return rolls;
};
