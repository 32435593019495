import React from 'react'
import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses, Typography } from "@mui/material";
import theme from '../../theme';

interface Text {
  text: string | number | null | undefined;
}

export const StyledCell = ({text}: Text) => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.lightGray,
      color: theme.palette.primary.darkGrey,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <StyledTableCell align="center">
        <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }}>
          {text}
        </Typography>
      </StyledTableCell>
    </>
  )
}
