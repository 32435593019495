import React, { useState, useEffect } from 'react';
import {
  Box,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import theme from '../../../theme';
import { InputField } from './InputField';
import { Address } from '../../../types/Address';
import { useTranslation } from 'react-i18next';
import { FormikReactSelect } from './FormikReactSelect';
import { countryOptions } from '../../../utils/constants';

type AlternativeAddressFieldsProps = {
  address: Address;
  handleDelete(): void;
};

export const AlternativeAddressFields: React.FC<
  AlternativeAddressFieldsProps
> = ({ address, handleDelete }) => {
  const { t } = useTranslation(['main']);
  const deviceWidth = useMediaQuery(theme.breakpoints.down('md'));

  const [initialValues, setInitialValues] = useState<FormikValues>({
    deliveryAlternativeCompanyName: '',
    deliveryAlternativeAddressStreet: '',
    deliveryAlternativeAddressStreetNumber: '',
    deliveryAlternativeAddressPostCode: '',
    deliveryAlternativeAddressCity: '',
    deliveryAlternativeAddressCountry: '',
  });

  const validationSchema = yup.object({
    deliveryAlternativeCompanyName: yup.string().max(255).required(),
    deliveryAlternativeAddressStreet: yup.string().max(255).required(),
    deliveryAlternativeAddressStreetNumber: yup.string().max(255).required(),
    deliveryAlternativeAddressPostCode: yup.string().max(255).required(),
    deliveryAlternativeAddressCity: yup.string().max(255).required(),
    deliveryAlternativeAddressCountry: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setInitialValues({
      deliveryAlternativeCompanyName: address.company_name,
      deliveryAlternativeAddressStreet: address.street_name,
      deliveryAlternativeAddressStreetNumber: address.street_number,
      deliveryAlternativeAddressPostCode: address.post_code,
      deliveryAlternativeAddressCity: address.city,
      deliveryAlternativeAddressCountry: address.country,
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={data => console.log(data)}
      >
        <Form>
          <Box
            display='flex'
            flexDirection='column'
            sx={{
              paddingBottom: theme.spacing(2),
            }}
          >
            <Box display='flex' justifyContent='flex-end'>
              <DialogTitle
                sx={{
                  padding: '0px',
                }}
              >
                <IconButton aria-label='close' onClick={handleDelete}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </DialogTitle>
            </Box>
            {!deviceWidth && (
              <Box display='flex' gap={theme.spacing(1)}>
                <Box display='flex' gap={theme.spacing(2)} width={'100%'}>
                  <InputField
                    name={'deliveryAlternativeCompanyName'}
                    fullWidth={true}
                    disabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressStreet'}
                    fullWidth={true}
                    disabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressStreetNumber'}
                    disabled
                  />
                </Box>
                <Box display='flex' gap={theme.spacing(2)} width={'70%'}>
                  <FormikReactSelect
                    name={'deliveryAlternativeAddressCountry'}
                    options={countryOptions}
                    initialValue={address.country}
                    isDisabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressCity'}
                    fullWidth={true}
                    disabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressPostCode'}
                    fullWidth={true}
                    disabled
                  />
                </Box>
              </Box>
            )}
            {deviceWidth && (
              <Box
                display='flex'
                flexDirection={'column'}
                gap={theme.spacing(2)}
              >
                <Box display='flex' gap={theme.spacing(2)} width={'100%'}>
                  <InputField
                    name={'deliveryAlternativeCompanyName'}
                    fullWidth={true}
                    disabled
                  />
                </Box>
                <Box display='flex' gap={theme.spacing(2)} width={'100%'}>
                  <InputField
                    name={'deliveryAlternativeAddressStreet'}
                    fullWidth={true}
                    disabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressStreetNumber'}
                    disabled
                  />
                </Box>
                <Box display='flex' gap={theme.spacing(2)}>
                  <FormikReactSelect
                    name={'deliveryAlternativeAddressCountry'}
                    options={countryOptions}
                    initialValue={address.country}
                    isDisabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressCity'}
                    fullWidth={true}
                    disabled
                  />
                  <InputField
                    name={'deliveryAlternativeAddressPostCode'}
                    fullWidth={true}
                    disabled
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Form>
      </Formik>

      <Divider />
    </>
  );
};
