import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, AccordionProps, AccordionSummaryProps, AccordionDetailsProps, styled } from '@mui/material';
import ArrowIcon from '@mui/icons-material/ArrowForwardIosSharp';
import theme from '../../theme';

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} sx={{ width: '100%' }} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem', stroke: 'black', strokeWidth: '1', transform: 'rotate(90deg)' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0, 0, 0, 1),
  },
}));

const CustomAccordionDetails = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export const Faq: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxWidth={theme.custom.maxPageContentWidth}
      mx='auto'
      justifyContent='center'
      alignItems='center'
      p={theme.spacing(5, 0)}
    >
      <Typography variant='h4' component='h4' mb={5}>
        {t('main:faq.title')}
      </Typography>
      <CustomAccordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <CustomAccordionSummary
          id='panel1-header'
          area-controls='panel1-content'
        >
          <Typography variant='h6'>
            {t('main:faq.accordion1Summary')}
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography
            variant='body2'
            lineHeight={2}
          >
            {t('main:faq.accordion1Details')}
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <CustomAccordionSummary
          id='panel2-header'
          area-controls='panel2-content'
        >
          <Typography variant='h6'>
            {t('main:faq.accordion2Summary')}
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography
            variant='body2'
            lineHeight={2}
          >
            {t('main:faq.accordion2Details')}
          </Typography>
          <Typography variant='body2'>
            {t('main:faq.accordion2SubDetails')}
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <CustomAccordionSummary
          id='panel3-header'
          area-controls='panel3-content'
        >
          <Typography variant='h6'>
            {t('main:faq.accordion3Summary')}
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography
            variant='body2'
            lineHeight={2}
          >
            {t('main:faq.accordion3Details')}
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <CustomAccordionSummary
          id='panel4-header'
          area-controls='panel4-content'
        >
          <Typography variant='h6'>
            {t('main:faq.accordion4Summary')}
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography
            variant='body2'
            lineHeight={2}
          >
            {t('main:faq.accordion4Details')}
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  );
};
