import { Box } from '@mui/material';
import React from 'react';
import { RowData } from '../interfaces';

type RowProps = {
  row: RowData,
  total: number,
}

export const RowOffcut: React.FC<RowProps> = ({
  row,
  total,
}) => (
  <Box
    component='span'
    sx={{
      border: 'solid 1px #979797',
      backgroundColor: 'rgba(244, 6, 6, 0.62)',
      width: `calc(${row.rollWidth} / ${total} * 100%)`,
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      component='span'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        transform: 'rotate(-90deg)',
        whiteSpace: 'nowrap',
        width: 0,
      }}
    >
      {`${row.rollWidth.toLocaleString('de-DE')} mm`}
    </Box>
  </Box>
);
