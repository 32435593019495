import { AxiosResponse } from 'axios';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LabelAPI from '../labelAPI';
import ClientService from '../services/ClientService';
import { ContentPermissions, User } from '../types/User';
import { STORAGE_TOKEN } from '../utils/constants';
import Client from './Client';

export type AuthContext = {
  isAuthFinished: boolean;
  isAuthLoading: boolean;
  user: User | null;
  setUser: (value: User | null) => void;
  lang: string;
  setLang: (value: string) => void;
  userPermissions: ContentPermissions[];
  userLevel: string;
  userCompanyId: number;
  login: (
    email: string,
    password: string
  ) => Promise<AxiosResponse<unknown, unknown>>;
  logout: () => void;
};
export const AuthContext = createContext<AuthContext>({} as AuthContext);

export type Props = {
  children: React.ReactNode;
};

const fetchProfileInfo = () => {
  try {
    return ClientService.getProfileInfo();
  } catch (e) {
    console.log('Error getting profile info');
  }
};

// need to type the user data at some point and not store it in state because it will be lost on page refresh
export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthFinished, setIsAuthFinished] = useState(false);
  const [lang, setLang] = useState<string>('en');
  const [userPermissions, setUserPermissions] = useState<ContentPermissions[]>([]);
  const [userLevel, setUserLevel] = useState<string>('');
  const [userCompanyId, setUserCompanyId] = useState(0);

  const authToken = Client.getToken();

  useEffect(() => {
    if (authToken) {
      (async () => {
        const profileInfo = await fetchProfileInfo();
        if (profileInfo) {
          setLang(profileInfo.data.userInfo.locale);
          setUser(profileInfo);
          setUserPermissions(profileInfo.data.permissions[0].permissions);
          setUserLevel(profileInfo.data.permissions[0].name);
        }
      })();
      setIsAuthFinished(true);
      setIsAuthLoading(false);
    }

  }, [authToken]);

  useEffect(() => {
    if(user) {
      setUserCompanyId(user.data.userInfo.company_id);
    }
  }, [user]);

  // call this function when you want to authenticate the user
  const login = async (email: string, password: string) => {
    const response = await LabelAPI.post('auth/login', {
      email,
      password,
    });

    const token = response.data.token;

    if (token) {
      localStorage.setItem(STORAGE_TOKEN, token);
      (async () => {
        const profileInfo = await fetchProfileInfo();
        if (profileInfo) {
          setLang(profileInfo.data.userInfo.locale);
          setUser(profileInfo);
          setUserPermissions(profileInfo.data.permissions[0].permissions);
        }
      })();
      setIsAuthFinished(true);
      setIsAuthLoading(false);
    }

    return response;
  };

  // call this function to sign out logged-in user
  const logout = () => {
    setUser(null);
    setUserPermissions([]);
    setUserLevel('');
    localStorage.removeItem('authToken');
  };

  const value: AuthContext = useMemo(
    () => ({
      isAuthFinished,
      isAuthLoading,
      user,
      setUser,
      lang,
      setLang: (value: string) => setLang(value),
      userPermissions,
      userLevel,
      userCompanyId,
      login,
      logout,
    }),
    [user, lang],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>);
};

export const useAuth = () => useContext(AuthContext);

