import React from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';


type StyledInputProps = {
  label: string | undefined
  onChange: (value: string) => void;
}
export const StyledInput: React.FC<StyledInputProps> = ({ label, onChange } ) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="outlined-adornment-amount">
      {label}
    </InputLabel>
    <OutlinedInput
      id="outlined-adornment-amount"
      label={label}
      onChange={e => onChange(e.target.value)}
    />
  </FormControl>
);
