import styled from '@emotion/styled';
import { Tabs } from '@mui/material';
import theme from '../../theme';

export const StyledTabs = styled(Tabs)({
  color: theme.palette.primary.darkGrey,
  textTransform: 'none',
  borderBottom: `1px solid ${theme.custom.borderGrey}`,
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});
