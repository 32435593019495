import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import theme from '../theme';

type Props = {
  openDescription: boolean;
  title?: string;
  description?: string;
  handleClose: () => void;
};

export const DescriptionModal: React.FC<Props> = props => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={props.openDescription}
      onClose={props.handleClose}
      maxWidth='md'
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          maxHeight: '35%',
          maxWidth: '35%',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '5px',
        }}
      >
        {props.title}
        <IconButton aria-label='close' onClick={props.handleClose}>
          <CancelIcon
            sx={{
              height: '16px',
              width: '16px',
              color: theme.palette.primary.red,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {props.description}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
