import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import theme from '../../theme';
import { Link, Typography } from '@mui/material';
import { Logo } from '../../ui-components/Logo';
import { t } from 'i18next';
import { Phone } from '@mui/icons-material';

export const Footer: React.FC = () => (
  <Box
    width='100%'
    sx={{
      backgroundColor: theme.custom.footerBackground,
      padding: theme.spacing(5, 0),
    }}
  >
    <Box
      display='flex'
      mx='auto'
      justifyContent='space-between'
      maxWidth={theme.custom.maxContentWidth}
      width='100%'
      flexWrap='wrap'
      rowGap={theme.spacing(5)}
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        alignContent: { xs: 'center', sm: 'flex-start' },
        '.MuiBox-root': {
          px: { xs: '10px', sm: '10px' },
        },
        '& > .MuiTypography-root': {
          px: { xs: '10px', sm: '10px' },
        },
      }}
    >
      <FooterContacts />
      <FooterLinksSection
        sectionName={t('main:footer.company')}
        links={[
          {
            name: t('main:footer.resources'),
            url: 'https://www.papier-plus.com/Impressum',
          },
          {
            name: t('main:footer.careers'),
            url: 'https://www.papier-plus.com/WerWirSind',
          },
        ]}
      />
      <FooterLinksSection
        sectionName={t('main:footer.legal')}
        links={[
          {
            name: t('main:footer.privacyPolicy'),
            url: 'https://test.etikettenorder24.com/faq',
          },
          {
            name: t('main:footer.termsOfUse'),
            url: 'https://www.papier-plus.com/Datenschutzerklärung',
          },
        ]}
      />
      <FooterEndSection />
      <FooterAllRightsReserved />
    </Box>
  </Box>
);

const FooterContacts: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <Box display='flex' flexDirection='column'>
      <Typography color={theme.custom.footerColorPrimary}>
        {t('main:footer.scheduleConversation')}
      </Typography>
      <Link href='mailto:hello@labelorder24.com' underline={'none'}>
        <Typography
          sx={{
            color: 'primary.red',
            fontWeight: 600,
          }}
        >
          hello@labelorder24.com
        </Typography>
      </Link>
      <Link
        href='tel:+49 89 5505 3610'
        underline={'none'}
        color={theme.custom.footerColorSecondary}
        display='flex'
        margin={theme.spacing(1.5, 0)}
        gap={theme.spacing(0.5)}
        alignItems='center'
      >
        <Phone sx={{ color: theme.custom.footerColorPrimary }} />
        <Typography display='inline-block' fontSize='18px'>
          +49 89 5505 3610
        </Typography>
      </Link>
      {/* <Box */}
      {/*  display='flex' */}
      {/*  gap={theme.spacing(2)} */}
      {/* > */}
      {/*  <SocialIcon  */}
      {/*    src={TwitterSvg} */}
      {/*    alt='Twitter Icon' */}
      {/*    link='twitter link' */}
      {/*  /> */}
      {/*  <SocialIcon  */}
      {/*    src={LinkedInSvg} */}
      {/*    alt='LinkedIn Icon' */}
      {/*    link='linked in link' */}
      {/*  /> */}
      {/* </Box> */}
    </Box>
  );
};

type FooterLinksSectionProps = {
  sectionName: string;
  links: {
    url: string;
    name: string;
  }[];
};
const FooterLinksSection: React.FC<FooterLinksSectionProps> = ({
  sectionName,
  links,
}) => (
  <Box display='flex' flexDirection='column'>
    <Typography variant='navigationLink' mb={theme.spacing(2)}>
      {sectionName}
    </Typography>
    {links.map((link, index) => (
      <Link
        key={index}
        href={link.url}
        sx={{
          textDecoration: 'none',
          color: theme.custom.footerColorSecondary,
        }}
      >
        <Typography variant='body2'>
          {link.name}
        </Typography>
      </Link>
    ))}
  </Box>
);

const FooterEndSection: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      maxWidth='350px'
      gap={theme.spacing(2)}
    >
      <Logo height='42px' width='auto' />
      <Typography variant='body2' color={theme.custom.footerColorPrimary}>
        {t('main:footer.mainText')}
      </Typography>
    </Box>
  );
};

const FooterAllRightsReserved: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <Typography
      flexBasis='100%'
      variant='body1'
      color={theme.custom.footerColorPrimary}
    >
      {t('main:footer.allRIghtsReserved')}
    </Typography>
  );
};
