import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../theme';
import ClientService from '../../../services/ClientService';
import { Address } from '../../../types/Address';
import { InputField } from '../Components/InputField';
import { AddressFields } from '../Components/AddressFields';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';
import { countryOptions } from '../../../utils/constants';

interface HandleClose {
  handleClose: () => void;
  refetchTableCompanies: () => void;
}

export const NewCompaniesForm: React.FC<HandleClose> = ({
  handleClose,
  refetchTableCompanies,
}): JSX.Element => {
  const { t } = useTranslation(['main']);

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });

  const [billingAddress, setBillingAddress] = useState<Address>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    companyName: '',
    companyAddressStreet: '',
    companyAddressStreetNumber: '',
    companyAddressCountry: countryOptions[0].value,
    companyAddressCity: '',
    companyAddressPostCode: '',
    companyEmail: '',
    companyTaxId: '',
    labelOrderCode: '',
  });
  const validationSchema = yup.object({
    companyName: yup.string().max(255).required(),
    companyTaxId: yup.string().max(255).required(),
    companyEmail: yup.string().max(255).required(),
    labelOrderCode: yup.string().max(255).required(),
    companyAddressStreet: yup.string().max(255).required(),
    companyAddressStreetNumber: yup.string().max(255).required(),
    companyAddressPostCode: yup.string().max(255).required(),
    companyAddressCity: yup.string().max(255).required(),
    companyAddressCountry: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setBillingAddress({
      company_name: initialValues.companyName || '',
      street_name: initialValues.companyAddressStreet || '',
      street_number: initialValues.companyAddressStreetNumber || '',
      post_code: initialValues.companyAddressPostCode || '',
      city: initialValues.companyAddressCity || '',
      country: initialValues.companyAddressCountry || 'Germany',
    });
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingBottom: '0',
        }}
      >
        {t('main:companies.addNewCompanyTitle')}
        <IconButton aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async data => {
          try {
            const newCompany: any = {
              name: data.companyName,
              tax_id: data.companyTaxId,
              email: data.companyEmail,
              label_order_code: data.labelOrderCode,
              street_name: data.companyAddressStreet,
              street_number: data.companyAddressStreetNumber,
              post_code: data.companyAddressPostCode,
              city: data.companyAddressCity,
              country: data.companyAddressCountry,
            };
            await ClientService.sendNewCompany(newCompany);
            refetchTableCompanies();
            setOpenSnackBar({
              openSnackBar: true,
              type: 'success',
              message: t('main:profile.successfulEdit') || '',
            });
            setTimeout(() => {
              handleClose();
            }, 1200);
          } catch (error) {
            console.log(error);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'error',
              message: t('main:profile.errorEdit') || '',
            });
          }
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Box 
              display='flex' 
              paddingX='2rem'
              sx={{
                flexDirection: {
                  xs: 'row',
                  sm: 'column',
                },
              }}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                gap={theme.spacing(4)}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  width: {
                    xs: '100%',
                    md: '100%',
                  },
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(4)}
                  sx={{
                    width: {
                      sm: '100%',
                      md: '50%',
                    },
                  }}
                >
                  <InputField
                    name='companyName'
                    category='companies'
                    fullWidth
                  />
                  <AddressFields
                    addressType='companyAddress'
                    address={billingAddress}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(4)}
                  sx={{
                    width: {
                      sm: '100%',
                      md: '50%',
                    },
                  }}
                >
                  <InputField
                    name='companyEmail'
                    category='companies'
                    fullWidth
                  />
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                  >
                    <Typography variant='body2' component='p' margin={'1px'}>
                      {t('main:companies.otherDetails')}
                    </Typography>
                    <InputField
                      name='companyTaxId'
                      category='companies'
                      fullWidth
                    />
                    <InputField
                      name='labelOrderCode'
                      category='companies'
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box> 
            <Box
              sx={{
                padding: '2rem',
              }}
            >
              <Button
                type='submit'
                variant='actionButtonRed'
                onClick={submitForm}
                sx={{
                  p: theme.spacing(1.5, 9),
                }}
                disabled={isSubmitting}
              >
                {t('main:companies.createCompany')}
              </Button>
            </Box>
            
          </Form>
        )}
      </Formik>
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </Box>
  );
};
