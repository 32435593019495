import { createTheme } from '@mui/material';
import React from 'react';

type CustomProperties = {
  navigationBackground: React.CSSProperties['color'];
  navigationLink: React.CSSProperties['color'];
  maxContentWidth: React.CSSProperties['width'];
  footerBackground: React.CSSProperties['color'];
  footerColorPrimary: React.CSSProperties['color'];
  footerColorSecondary: React.CSSProperties['color'];
  borderGrey: React.CSSProperties['color'];
  whiteBackground: React.CSSProperties['color'];
  bodyBackground: React.CSSProperties['color'];
  maxPageContentWidth: React.CSSProperties['width'];
  tableInputBackground: React.CSSProperties['color'];
  dividerColor: React.CSSProperties['color'];
  tableTextColor: React.CSSProperties['color'];
  maxContentPageWidth: React.CSSProperties['width'];
  tableHeaderBackgroundColor: React.CSSProperties['color'];
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomProperties;
  }
  interface ThemeOptions {
    custom: CustomProperties;
  }

  interface PaletteColor {
    darker?: string;
    grey?: string;
    red?: string;
    darkGrey?: string;
    lightGray?: string;
    lightGrey?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    grey?: string;
    red?: string;
    darkGrey?: string;
    lightGray?: string;
    lightGrey?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    actionButton: true;
    actionButtonGreen: true;
    actionButtonNewSet: true;
    actionButtonGreenBig: true;
    actionButtonRed: true;
    actionButtonNoBackground: true;
    actionButtonNoBG: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    navigationLink: true;
    body3Light: true;
    body3Dark: true;
    body4Light: true;
    body4Dark: true;
    body5Light: true;
    body5Dark: true;
    error: true;
    pageTitle: true;
    bigTitle: true;
    yellowBackground: true;
    checkbox: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#C1C1C1',
      red: '#E2000A',
      grey: '#6a7c92',
      darker: 'rgb(0, 0, 255)',
      darkGrey: '#212b36',
      lightGray: '#ecedf0',
      lightGrey: '#6d7277',
    },
    text: {
      secondary: '#69939D',
      primary: '#212b36',
    },
  },
  typography: {
    fontFamily: 'ArialRounded,Poppins',
    body1: {
      fontSize: '12px',
      fontFamily: 'Poppins',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontFamily: 'Poppins',
    },
    h1: {
      fontSize: '48px',
      color: '#454f5b',
    },
    h2: {
      fontSize: '40px',
      color: '#454f5b',
    },
    h3: {
      fontSize: '32px',
      color: '#454f5b',
    },
    h4: {
      fontSize: '28px',
      color: '#454f5b',
    },
    h5: {
      fontSize: '20px',
      color: '#454f5b',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '32px',
      color: '#454f5b',
      wordBreak: 'break-all',
    },
  },
  custom: {
    navigationBackground: '#ECF4FA',
    footerBackground: '#f4f6f8',
    navigationLink: '#69939D',
    maxContentWidth: '1056px',
    maxPageContentWidth: '1140px',
    maxContentPageWidth: '1200px',
    footerColorPrimary: '#919eab',
    footerColorSecondary: '#454f5b',
    borderGrey: '#C4CDD5',
    whiteBackground: '#FFFFFF',
    bodyBackground: '#f9fafb',
    tableInputBackground: '#f6f4fc',
    dividerColor: '#979797',
    tableTextColor: '#212b36',
    tableHeaderBackgroundColor: '#dfe3e8',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'actionButton' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#E2000A',
            borderRadius: '2px',
            border: '1px solid #E2000A',
            padding: '8px 32px',
            textDecoration: 'uppercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: '#E2000A',
              filter: 'brightness(85%)',
            },
          },
        },
        {
          props: { variant: 'actionButtonGreen' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#95cdb1',
            borderRadius: '2px',
            border: '1px solid #95cdb1',
            padding: '8px 32px',
            textDecoration: 'uppercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: '#95cdb1',
              filter: 'brightness(85%)',
            },
            ':disabled': {
              backgroundColor: '#808080',
              color: '#c7c7c7',
              border: '1px solid #808080',
            },
          },
        },
        {
          props: { variant: 'actionButtonNewSet' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#ffc48b',
            borderRadius: '2px',
            padding: '8px 32px',
            textDecoration: 'uppercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: '#95cdb1',
              filter: 'brightness(85%)',
            },
            ':disabled': {
              backgroundColor: '#808080',
              color: '#c7c7c7',
              border: '1px solid #808080',
            },
          },
        },
        {
          props: { variant: 'actionButtonGreenBig' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#95cdb1',
            borderRadius: '2px',
            border: '1px solid #95cdb1',
            padding: '8px 52px',
            textDecoration: 'uppercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: '#95cdb1',
              filter: 'brightness(85%)',
            },
          },
        },
        {
          props: { variant: 'actionButtonRed' },
          style: {
            color: '#FFFFFF',
            backgroundColor: '#e2000a',
            borderRadius: '2px',
            border: '1px solid #e2000a',
            padding: '8px 32px',
            textDecoration: 'uppercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: '#e2000a',
              filter: 'brightness(85%)',
            },
          },
        },
        {
          props: { variant: 'actionButtonNoBackground' },
          style: {
            fontSize: '0.8rem',
            color: '#000',
            backgroundColor: 'transparent',
            borderRadius: '2px',
            padding: '8px 32px',
            textDecoration: 'lowercase',
            width: 'fit-content',
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'actionButtonNoBG' },
          style: {
            fontSize: '20px',
            color: '#454f5b',
            backgroundColor: 'transparent',
            borderRadius: '0',
            padding: '0',
            textTransform: 'none',
            justifyContent: 'flex-start',
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'navigationLink' },
          style: {
            fontFamily: 'ArialRounded',
            fontSize: '12px',
            textDecoration: 'none',
            textTransform: 'uppercase',
            color: '#69939d',
          },
        },
        {
          props: { variant: 'body3Light' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '14px',
            textDecoration: 'none',
            color: '#6d7277',
          },
        },
        {
          props: { variant: 'body3Dark' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '14px',
            textDecoration: 'none',
            color: '#212b36',
          },
        },
        {
          props: { variant: 'body4Light' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '10px',
            textDecoration: 'none',
            color: '#6d7277',
          },
        },
        {
          props: { variant: 'body4Dark' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '10px',
            textDecoration: 'none',
            color: '#212b36',
          },
        },
        {
          props: { variant: 'body5Light' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '8px',
            textDecoration: 'none',
            color: '#6d7277',
          },
        },
        {
          props: { variant: 'body5Dark' },
          style: {
            display: 'block',
            fontFamily: 'ArialRounded',
            fontSize: '8px',
            fontWeight: 'bold',
            textDecoration: 'none',
            color: '#212b36',
          },
        },
        {
          props: { variant: 'error' },
          style: {
            display: 'block',
            fontFamily: 'Poppins',
            fontSize: '10px',
            textDecoration: 'none',
            color: '#E2000A',
          },
        },
        {
          props: { variant: 'pageTitle' },
          style: {
            display: 'block',
            fontSize: '36px',
            color: '#212b36',
            textAlign: 'center',
          },
        },
        {
          props: { variant: 'bigTitle' },
          style: {
            display: 'block',
            fontSize: '36px',
            color: '#212b36',
            textAlign: 'left',
          },
        },
        {
          props: { variant: 'yellowBackground' },
          style: {
            fontSize: '14px',
            color: '#212b36',
            fontWeight: 'bold',
            backgroundColor: '#ffc48b',
            padding: '12px 40px',
          },
        },
        {
          props: { variant: 'checkbox' },
          style: {
            fontFamily: 'ArialRounded',
            fontSize: '14px',
            lineHeight: '31px',
            textDecoration: 'none',
            color: '#6d7277',
          },
        },
      ],
    },
  },
});

export default theme;
