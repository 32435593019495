import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Page = {
  link: string;
  pageTitle: string;
}

type NavigationListItemProps = {
  page: Page;
}

export const NavigationListItem = ({ page }: NavigationListItemProps) => {
  const { t } = useTranslation(['main']);

  return (
    <ListItem>
      <ListItemButton
        disableGutters
        disableRipple
        sx={{
          ml: 1,
          py: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Link to={page.link} style={{ textDecoration: 'none' }}>
          <Typography variant="navigationLink" >
            {t(`${page.pageTitle}`)}
          </Typography>
        </Link>
      </ListItemButton>
    </ListItem>
  );
};
