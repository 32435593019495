import LabelAPI from '../labelAPI';
import { AllProductsData } from '../types/AllProductsData';
import { Filters } from '../types/Filters';
import { Orders } from '../types/Orders';
import { Product } from '../types/Product';
import { QuickSearchProductsResults } from '../types/QuickSearchProductsResults';
import { Suppliers, NewSupplier, AllSuppliersList } from '../types/Suppliers';
import { User, UserDetails, NewUser } from '../types/User';
import { NewCompany } from '../types/Company';
import { Locale } from '../ui-components/interfaces';

class ClientService {
  static async getProduct(id: string): Promise<Product> {
    const { data: response } = await LabelAPI.get(`/products/${id}`);
    return response;
  }
  static async getQuickSearchProductsResults(
    searchString: string,
  ): Promise<QuickSearchProductsResults[]> {
    const { data: response } = await LabelAPI.get(
      `products/search?search=${searchString}`,
    );
    return response;
  }
  static async getAllProducts(
    page: number,
    face?: string | null,
    glue?: string | null,
    back?: string | null,
    services?: string | null,
    certificates?: string | null,
    product_face?: string | null,
    product_glue?: string | null,
    product_back?: string | null,
    supplier?: string | null,
  ): Promise<AllProductsData> {
    const reqParams = [
      ['face', face],
      ['glue', glue],
      ['back', back],
      ['services', services],
      ['certificates', certificates],
      ['product_back', product_back],
      ['product_glue', product_glue],
      ['product_face', product_face],
      ['supplier', supplier],
    ];
    let reqUrl = `products?page=${page}&limit=10`;
    reqParams.forEach(param => {
      reqUrl = param[1] ? `${reqUrl}&${param[0]}=${param[1]}` : reqUrl;
    });

    const { data: response } = await LabelAPI.get(reqUrl);
    return response;
  }
  static async getAllFilters(): Promise<Filters> {
    const { data: response } = await LabelAPI.get('products/filters');
    return response;
  }
  static async setLocale(locale: Locale): Promise<UserDetails | undefined> {
    const { data: response } = await LabelAPI.put(
      `user/change-locale/${locale.locale}`,
      locale,
    );
    return response;
  }
  static async calculateCutting(data: any) {
    const { data: response } = await LabelAPI.post('/order/calculate', data);
    return response;
  }
  static async calculateAndSaveCutting(data: any) {
    const { data: response } = await LabelAPI.post(
      '/order/calculate/save',
      data,
    );
    return response;
  }
  static async calculateAndUpdateCutting(data: any) {
    const { data: response } = await LabelAPI.post(
      '/order/calculate/update',
      data,
    );
    return response;
  }
  static async deleteOrder(id: number) {
    const { data: response } = await LabelAPI.delete(`/order/${id}/delete`);
    return response;
  }
  static async getOrders(
    supplierId: number,
    companyId: number,
    status: string,
    page: number,
    limit: number,
  ): Promise<Orders> {
    const { data: response } = await LabelAPI.get(
      `/orders?supplier=${supplierId}&company=${companyId}&status=${status}&page=${page}&limit=${limit}`,
    );
    return response;
  }
  static async getSuppliers(): Promise<Suppliers> {
    const { data: response } = await LabelAPI.get('/suppliers');
    return response;
  }
  static async getAllSuppliers(): Promise<AllSuppliersList> {
    const { data: response } = await LabelAPI.get('/suppliers/all');
    return response;
  }
  static async sendOrder(supplierId: number, rowsForOrder: number[]) {
    const { data: response } = await LabelAPI.post('/orders/send', {
      supplier: supplierId,
      orders_id: rowsForOrder,
    });
    return response;
  }
  static async getAllUsers(page: number) {
    const { data: response } = await LabelAPI.get(`/user?page=${page}`);
    return response;
  }
  static async getOneUser(userId: number) {
    const { data: response } = await LabelAPI.get(`/user/${userId}`);
    return response;
  }
  static async getUserRoles() {
    const { data: response } = await LabelAPI.get('/roles-permissions');
    return response;
  }
  static async sendNewUser(data: NewUser) {
    const { data: response } = await LabelAPI.post('/user', data);
    return response;
  }
  static async updateUser(data: any, userId: number | null) {
    const { data: response } = await LabelAPI.put(`/user/${userId}`, data);
    return response;
  }
  static async updateUserOwnInfo(data: any) {
    const { data: response } = await LabelAPI.patch(
      '/user/update/own-info',
      data,
    );
    return response;
  }
  static async deleteUser(id: number) {
    const { data: response } = await LabelAPI.delete(`/user/${id}`);
    return response;
  }
  static async getAllCompanies(page: number) {
    const { data: response } = await LabelAPI.get(`/companies?page=${page}`);
    return response;
  }
  static async getAllCompaniesForSelect() {
    const { data: response } = await LabelAPI.get('/companies/all');
    return response;
  }
  static async getOneCompany(companyId: number) {
    const { data: response } = await LabelAPI.get(`/companies/${companyId}`);
    return response;
  }
  static async sendNewCompany(data: NewCompany) {
    const { data: response } = await LabelAPI.post('/companies', data);
    return response;
  }
  static async sendNewCompanyAlternativeAddress(data: any) {
    const { data: response } = await LabelAPI.post('/company-address', data);
    return response;
  }
  static async updateCompany(data: any, companyId: number | null) {
    const { data: response } = await LabelAPI.put(
      `/companies/${companyId}`,
      data,
    );
    return response;
  }
  static async createDeliveryAddress(data: any) {
    const { data: response } = await LabelAPI.post('/company-address', data);
    return response;
  }
  static async sendNewAddressId(orderId: number, addressId: number) {
    const { data: response } = await LabelAPI.patch(`/order/${orderId}/delivery-address`, {
      delivery_address_id: addressId,
    });
    return response;
  }
  static async updateDeliveryAddress(data: any, addressId: number) {
    const { data: response } = await LabelAPI.put(
      `/company-address/${addressId}`,
      data,
    );
    return response;
  }
  static async deleteAddress(addressId: number) {
    const { data: response } = await LabelAPI.delete(
      `/company-address/${addressId}`,
    );
    return response;
  }
  static async deleteCompany(companyId: number) {
    const { data: response } = await LabelAPI.delete(`/companies/${companyId}`);
    return response;
  }
  static async getOneSupplier(supplierId: number) {
    const { data: response } = await LabelAPI.get(`/suppliers/${supplierId}`);
    return response;
  }
  static async sendNewSupplier(data: NewSupplier) {
    const { data: response } = await LabelAPI.post('/suppliers', data);
    return response;
  }
  static async updateSupplier(data: any, supplierId: number | null) {
    const { data: response } = await LabelAPI.put(
      `/suppliers/${supplierId}`,
      data,
    );
    return response;
  }
  static async createSupplierCompany(data: any) {
    const { data: response } = await LabelAPI.post('/company-supplier', data);
    return response;
  }
  static async updateSupplierCompany(data: any, id: number) {
    const { data: response } = await LabelAPI.put(
      `/company-supplier/${id}`,
      data,
    );
    return response;
  }
  static async deleteSupplier(supplierId: number) {
    const { data: response } = await LabelAPI.delete(
      `/suppliers/${supplierId}`,
    );
    return response;
  }
  static async getProfileInfo(): Promise<User | undefined> {
    const { data: response } = await LabelAPI.get('/user/show-user-info');
    return response;
  }
  static async getOrdersTotalQm(supplierId: number, companyId: number, orderStatus: string) {
    const { data: response } = await LabelAPI.get(
      `/orders/totals?supplier=${supplierId}&company=${companyId}&status=${orderStatus}`,
    );
    return response;
  }
  static async cancelProduct(orderId: number) {
    const { data: response } = await LabelAPI.patch(`/order/${orderId}/cancel`);
    return response;
  }
}

export default ClientService;
