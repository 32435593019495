import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { Logo } from '../../ui-components/Logo';
import theme from '../../theme';
import { useAuth } from '../../contexts/AuthContext';
import { LanguageDropdown } from '../../ui-components/LanguageDropdown';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { MobileNavigation } from './MobileNavigation';
import { NavigationItem } from '../../ui-components/NavigationItem';

const pages = [
  {
    pageTitle: 'navigation.article',
    link: '/article',
    permission: 'search_product',
  },
  {
    pageTitle: 'navigation.orders',
    link: '/orders',
    permission: 'read_company_shopping_cart',
  },
  {
    pageTitle: 'navigation.report',
    link: '/report',
    permission: 'create_reports',
  },
  {
    pageTitle: 'navigation.profile',
    link: '/profile',
    permission: 'edit_user_data',
  },
  {
    pageTitle: 'navigation.contact',
    link: '/contact',
    permission: 'edit_user_data',
  },
  { pageTitle: 'navigation.faq', link: '/faq', permission: 'search_product' },
];

export const Navigation: React.FC = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor:
          location.pathname !== '/'
            ? theme.custom.bodyBackground
            : theme.custom.navigationBackground,
      }}
    >
      <Box
        display='flex'
        width='100%'
        maxWidth={theme.custom.maxContentWidth}
        height='70px'
        mx='auto'
        alignItems='center'
        justifyContent='space-between'
        borderBottom='1px solid'
        borderColor={theme.custom.borderGrey}
      >
        <Logo height={'42px'} width={'auto'} />
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
          }}
        >
          <LanguageDropdown
            sx={{
              '& .MuiOutlinedInput-input.MuiSelect-select': {
                padding: {
                  xs: '10px 28px 10px 10px',
                  md: '16.5px 32px 16.5px 14px',
                },
              },
              '& .MuiSvgIcon-root.MuiSelect-icon': {
                right: {
                  xs: '3px',
                  md: '7px',
                },
              },
            }}
          />
          <MobileNavigation />
        </Box>
        <Box
          component='nav'
          display='flex'
          gap='25px'
          sx={{
            display: { xs: 'none', md: 'flex' },
            a: {
              textDecoration: 'none',
            },
          }}
        >
          {pages.map((page, index) => (
            page.permission ? (
              <RestrictedComponent key={index} permission={page.permission}>
                <NavigationItem page={page} />
              </RestrictedComponent>
            ) : (
              <NavigationItem key={index} page={page} />
            )
          ))}
        </Box>
        <LanguageDropdown sx={{ display: { xs: 'none', md: 'flex' } }} />
        <Button
          variant='actionButton'
          onClick={() => {
            if (user) {
              logout();
            }
            navigate('login');
          }}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {user ? t('buttons.logout') : t('buttons.login')}
        </Button>
      </Box>
    </Box>
  );
};
