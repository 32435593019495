import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import CheckmarkLottie from '../../ui-components/CheckmarkLottie';
import { useNavigate } from 'react-router-dom';

export const SuccessfulOrder: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={theme.custom.maxPageContentWidth}
      mx="auto"
      justifyContent="center"
      alignItems="center"
      gap={theme.spacing(10)}
      p={theme.spacing(10, 0)}
    >
      <Typography variant="pageTitle" component='h1' maxWidth={512}>
        {t('main:successfulOrder.title')}
      </Typography>
      <CheckmarkLottie />
      <Button
        variant="actionButtonGreen"
        sx={{
          p: theme.spacing(1.5, 9),
        }}
        onClick={() => navigate('/orders')}
      >
        {t('main:successfulOrder.btnTitle1')}
      </Button>
    </Box>
  );
};

