import { useQuery } from '@tanstack/react-query';
import ClientService from '../services/ClientService';
import {
  QUERY_PRODUCT_INFO,
  USERS,
  COMPANIES,
  ALL_COMPANIES,
  SUPPLIERS,
  ALL_SUPPLIERS,
  USER,
  COMPANY,
  SUPPLIER,
  ROLES,
  TOTAL_QM,
  ORDERS,
} from '../utils/constants';

const useProduct = (id: string) =>
  useQuery([QUERY_PRODUCT_INFO, id], () => ClientService.getProduct(id), {
    staleTime: 10000,
  });
const useSuppliers = () =>
  useQuery([SUPPLIERS], () => ClientService.getSuppliers(), {
    staleTime: 10000,
  });

const useAllSuppliers = () =>
  useQuery([ALL_SUPPLIERS], async () => await ClientService.getAllSuppliers(), {
    staleTime: 10000,
  });

const useUsers = (page: number) =>
  useQuery([USERS, page], async () => await ClientService.getAllUsers(page), {
    staleTime: 10000,
  });

const useUser = (userId: number | null) =>
  useQuery([USER, userId], async () => userId && await ClientService.getOneUser(userId), {
    staleTime: 10000,
  });

const useUserRoles = () =>
  useQuery([ROLES], async () => await ClientService.getUserRoles(), {
    staleTime: 10000,
  });

const useCompanies = (page: number) =>
  useQuery([ALL_COMPANIES, page], async () => await ClientService.getAllCompanies(page), {
    staleTime: 10000,
  });

const useAllCompanies = () =>
  useQuery([COMPANIES], async () => await ClientService.getAllCompaniesForSelect(), {
    staleTime: 10000,
  });

const useCompany = (companyId: number) =>
  useQuery([COMPANY, companyId], async () => await ClientService.getOneCompany(companyId), {
    staleTime: 10000,
  });

const useSupplier = (supplierId: number | null) =>
  useQuery([SUPPLIER, supplierId], async () => supplierId && await ClientService.getOneSupplier(supplierId), {
    staleTime: 10000,
  });

const useOrdersTotalQm = (supplierId: number, companyId:number, orderStatus: string) =>
  useQuery([TOTAL_QM, supplierId, orderStatus], async () => await ClientService.getOrdersTotalQm(supplierId, companyId, orderStatus), {
    staleTime: 10000,
  });

const useOrders = (
  supplierId: number,
  companyId: number,
  status: string,
  page: number,
  limit: number) =>
  useQuery([ORDERS, supplierId, companyId, status, page, limit], async () => await ClientService.getOrders(supplierId, companyId, status, page, limit), {
    staleTime: 10000,
  });


export {
  useProduct,
  useUsers,
  useUser,
  useUserRoles,
  useCompanies,
  useAllCompanies,
  useCompany,
  useSuppliers,
  useAllSuppliers,
  useSupplier,
  useOrdersTotalQm,
  useOrders,
};
