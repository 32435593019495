import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../theme';
import { useAllCompanies, useUserRoles } from '../../../selectors/selectors';
import ClientService from '../../../services/ClientService';
import { NewUser } from '../../../types/User';
import {
  DropdownWrapper,
  DropdownOption,
} from '../../../ui-components/DropdownWrapper';
import { InputField } from '../Components/InputField';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';

interface HandleClose {
  handleClose: () => void;
  refetchTable: () => void;
}

export const NewUserForm: React.FC<HandleClose> = ({
  handleClose,
  refetchTable,
}): JSX.Element => {
  const { t } = useTranslation(['main']);
  const isDeviceMD = useMediaQuery(theme.breakpoints.down('md'));
  const { data: companies } = useAllCompanies();
  const { data: roles } = useUserRoles();

  const [companiesList, setCompaniesList] = useState<DropdownOption[] | []>([]);
  const [rolesList, setRolesList] = useState<DropdownOption[] | []>([]);
  const [roleId, setRoleId] = useState<string | number>('');
  const [companyId, setCompanyId] = useState<string | number>('');

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });

  useEffect(() => {
    if (companies && !companiesList.length) {
      setCompaniesList(
        companies.map((company: any) => ({
          name: company.name,
          value: company.id,
        })),
      );
    }
    if (roles?.length && !rolesList.length) {
      setRolesList(
        roles.map((role: any) => ({
          name: role.display_name,
          value: role.id,
        })),
      );
    }
  }, [companies, roles]);

  useEffect(() => {
    if (rolesList.length) {
      setRoleId(rolesList[0].value);
    }
    if (companiesList.length) {
      setCompanyId(companiesList[0].value);
    }
  }, [companiesList, rolesList]);

  const validationSchema = yup.object({
    userFirstName: yup.string().max(255).required(),
    userLastName: yup.string().max(255).required(),
    userEmail: yup.string().max(255).required(),
    userPhone: yup.string().max(255).required(),
    userOrderEmail: yup.string().max(255).required(),
    password: yup.string().max(255),
    passwordConfirmation: yup.string().max(255),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingBottom: '0',
        }}
      >
        {t('main:users.addNewUserTitle')}
        <IconButton aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          userFirstName: '',
          userLastName: '',
          userEmail: '',
          userPhone: '',
          userOrderEmail: '',
          roleId: roleId,
          companyId: companyId,
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async data => {
          try {
            const newUser: any = {
              first_name: data.userFirstName,
              last_name: data.userLastName,
              email: data.userEmail,
              phone: data.userPhone,
              order_email: data.userOrderEmail,
              password: data.password,
              password_confirmation: data.passwordConfirmation,
              company_id: companyId,
              role_id: roleId,
            };
            await ClientService.sendNewUser(newUser);
            refetchTable();
            setOpenSnackBar({
              openSnackBar: true,
              type: 'success',
              message: t('main:profile.successfulEdit') || '',
            });
            setTimeout(() => {
              handleClose();
            }, 1200);
          } catch (error) {
            console.log(error);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'error',
              message: t('main:profile.errorEdit') || '',
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              paddingX='2rem'
              gap={theme.spacing(2)}
            >
              {isDeviceMD && 
              <Box
                display='flex'
                gap={theme.spacing(2)}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '60%',
                      md: '50%',
                    },
                  }}
                >
                  {companies && (
                    <Field
                      as={DropdownWrapper}
                      onChange={(value: string) => setCompanyId(value)}
                      initialValue={companyId}
                      label={t('main:companies.name')}
                      name='companyId'
                      data={companiesList}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '40%',
                      md: '50%',
                    },
                  }}
                >
                  {roles && (
                    <Field
                      as={DropdownWrapper}
                      onChange={(value: string) => setRoleId(value)}
                      initialValue={roleId}
                      label={t('main:users.roleId')}
                      name='roleId'
                      data={rolesList}
                    />
                  )}
                </Box>
              </Box>}
              <Box
                display='flex'
                flexDirection='row'
                gap={theme.spacing(2)}
                width='100%'
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <InputField name='userFirstName' category='users' fullWidth />
                <InputField name='userLastName' category='users' fullWidth />
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                gap={theme.spacing(2)}
                width='100%'
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <InputField name='userEmail' category='users' fullWidth />
                <InputField name='userOrderEmail' category='users' fullWidth />
              </Box>
              <Box
                display='flex'
                gap={theme.spacing(2)}
                width='100%'
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(2)}
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '49%',
                    },
                  }}
                >
                  <InputField name='userPhone' category='users' fullWidth />
                  <Typography variant='body2' component='p' margin={'1px'}>
                    {t('main:users.password')}
                  </Typography>
                  <InputField 
                    name='password' 
                    category='users' 
                    type='password' 
                    fullWidth 
                  />
                  <InputField
                    name='passwordConfirmation'
                    category='users'
                    type='password'
                    fullWidth
                  />
                </Box>
                {!isDeviceMD && 
                  <Box
                    display='flex'
                    gap={theme.spacing(2)}
                    sx={{
                      flexDirection: {
                        md: 'column',
                      },
                      width: {
                        md: '50%',
                      },
                    }}
                  >
                    <Box>
                      {companies && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setCompanyId(value)}
                          initialValue={companyId}
                          label={t('main:companies.name')}
                          name='companyId'
                          data={companiesList}
                        />
                      )}
                    </Box>
                    <Box>
                      {roles && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setRoleId(value)}
                          initialValue={roleId}
                          label={t('main:users.roleId')}
                          name='roleId'
                          data={rolesList}
                        />
                      )}
                    </Box>
                  </Box>}
              </Box>
              <Box
                sx={{
                  padding: '2rem',
                }}
              >
                <Button
                  type='submit'
                  variant='actionButtonRed'
                  onClick={submitForm}
                  sx={{
                    p: theme.spacing(1.5, 9),
                  }}
                  disabled={isSubmitting}
                >
                  {t('main:users.createUser')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </Box>
  );
};
