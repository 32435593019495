import { useField, useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import theme from '../../../theme';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

const customStyles = {
  fontFamily: 'Poppins',
  fontSize: '12px',
};

const disabledStyles = {
  backgroundColor: 'transparent',
  border: '1px solid rgba(118, 118, 118, 0.5)',
};

type MyOption = {
  label: string;
  value: any;
};

type GroupedOption = {
  label: string;
  options: MyOption[];
};

type Props = {
  name: string;
  initialValue: string;
  isDisabled?: boolean;
  fullWidth?: boolean;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  'value' | 'onChange'
>;

export const FormikReactSelect = (props: Props) => {
  const { name, ...restProps } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const flattenedOptions = props.options?.flatMap(o => {
    const isNotGrouped = 'value' in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  const value = flattenedOptions?.filter(o => {
    const isArrayValue = Array.isArray(field.value);

    if (isArrayValue) {
      const values = field.value as Array<any>;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  return (
    <Select
      {...restProps}
      value={value}
      onChange={val => {
        const _val = val as MyOption[] | MyOption;
        const isArray = Array.isArray(_val);
        if (isArray) {
          const values = _val.map(o => o.value);
          setFieldValue(name, values);
        } else {
          setFieldValue(name, _val.value);
        }
      }}
      defaultValue={{ label: props.initialValue, value: props.initialValue }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          width: props.fullWidth ? '100%' : theme.spacing(20),
          height: '50.25px',
          ...(props.isDisabled ? disabledStyles : {}),
        }),
        placeholder: baseStyles => ({
          ...baseStyles,
          ...customStyles,
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          ...customStyles,
        }),
        loadingMessage: baseStyles => ({
          ...baseStyles,
          ...customStyles,
        }),
        noOptionsMessage: baseStyles => ({
          ...baseStyles,
          ...customStyles,
        }),
        input: baseStyles => ({
          ...baseStyles,
          ...customStyles,
        }),
        option: baseStyles => ({
          ...baseStyles,
          ...customStyles,
          cursor: 'pointer',
        }),
        container: baseStyles => ({
          ...baseStyles,
          zIndex: '9',
        }),
      }}
    />
  );
};
