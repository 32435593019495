import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel } from '@mui/material';
import { FormikInputField } from '../../../ui-components/formik/FormikInputField';
import theme from '../../../theme';

type InputFieldProps = {
  name: string;
  category?: string;
  type?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
  name,
  category = 'profile',
  type,
  fullWidth,
  disabled,
}) => {
  const { t } = useTranslation(['main']);
  return (
    <FormControl variant='outlined' fullWidth={fullWidth}>
      <InputLabel htmlFor={name}>
        {t<string>(`main:${category}.${name}`)}
      </InputLabel>
      <FormikInputField
        id={name}
        name={name}
        type={type || 'text'}
        label={t<string>(`main:${category}.${name}`)}
        fullWidth={fullWidth}
        disabled={disabled}
        sx={{ borderRadius: theme.spacing(0.5) }}
      />
    </FormControl>
  );
};
