import React, { useEffect, useState } from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { Lock, Person } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInputField } from '../ui-components/formik/FormikInputField';
import { Button } from '../ui-components/Button';
import Labelorder from '../assets/labelorder24_1.jpeg';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation(['main']);
  const { login, user } = useAuth();
  const navigate = useNavigate();
  const [responseStatus, setResponseStatus] = useState<number | null>();

  useEffect(() => {
    if(user){
      navigate('/article');
    }
  }, [user]);

  const validationSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
  });

  return(
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async data => {
        await login(data.email, data.password).then(response => setResponseStatus(response.status));
      }}
    >
      {({ submitForm, isSubmitting }) => (

        <Form>
          <Box
            display='flex'
            flexDirection='column'
            padding='10px'
            maxWidth={theme.custom.maxContentWidth}
            sx={{
              height: '100%',
              mx: 'auto',
              py: theme.spacing(6),
              gap: theme.spacing(3),
            }}
          >
            <img
              src={Labelorder}
              alt='Login Label'
              style={{
                maxHeight: '300px',
              }}
            />

            <Typography
              variant='h2'
              sx={{
                marginTop: {
                  xs: '20px',
                  sm: '0px',
                },
                marginBottom: {
                  xs: '60px',
                  sm: '0px',
                },
              }}
            >
              {t('main:login.title')}
            </Typography>

            <Snackbar
              open={responseStatus === 422}
              autoHideDuration={2000}
              onClose={() => setResponseStatus(null)}
              message={'Login Unsuccessful'}
            />

            <Box
              display='flex'
              flexDirection='column'
              gap={theme.spacing(2)}
            >
              <FormikInputField
                name='email'
                icon={<Person />}
                autoFocus={true}
                type='text'
                placeholder={t<string>('main:login.username')}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '40%',
                    lg: '30%',
                  },
                }}
              />
              <FormikInputField
                name='password'
                icon={<Lock />}
                type='password'
                placeholder={t<string>('main:login.password')}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '40%',
                    lg: '30%',
                  },
                }}
              />
            </Box>
            <Link to='/forgotten-password'>
              {t('main:login.forgottenPassword')}
            </Link>
            <Button
              variant='actionButton'
              isLoading={isSubmitting}
              onClick={submitForm}
              sx={{
                marginTop: {
                  xs: '20px',
                  md: '0px',
                },
                paddingX: {
                  xs: '50px',
                  md: '32px',
                },
              }}
            >
              {t('main:buttons.login')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
