/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { LandingPageBoxes } from './LandingPageBoxes';
import landingImage1 from '../../assets/icon-home-1@1x.svg';
import landingImage2 from '../../assets/icon-home-2@1x.svg';
import landingImage3 from '../../assets/icon-home-3.svg';

const landingPageData = [
  {
    src: landingImage1,
    alt: 'icon 1',
    titleEn: 'Powerful suppliers',
    subtitleEn:
      'Together with our partners UPM Raflatac, Avery Dennison, Fedrigoni and VPF, we can offer all materials.',
    titleDe: 'Leistungsstarke Lieferanten',
    subtitleDe:
      'Gemeinsam mit unser Partnern UPM Raflatac, Avery Dennison, Fedrigoni und VPF können wir sämtliche Materialien anbieten.',
  },
  {
    src: landingImage2,
    alt: 'icon 2',
    titleEn: 'Digital ordering process',
    subtitleEn:
      'Just choose surfaces, adhesives and bases online. Order. Done! Fast, easy and secure.',
    titleDe: 'Digitaler Bestellprozess',
    subtitleDe:
      'Oberflächen, Kleber und Träger online auswählen. Bestellen. Fertig! Schnell, einfach und sicher.',
  },
  {
    src: landingImage3,
    alt: 'icon 3',
    titleEn: 'Better together',
    subtitleEn:
      'Due to our high purchasing volume, we achieve best prices for our community, which would not be achievable as an individual company.',
    titleDe: 'Besser gemeinsam',
    subtitleDe:
      'Durch unser hohes Einkaufsvolumen erreichen wir unserer Gemeinschaft Bestpreise, die als Einzelunternehmen nicht erreichbar wären',
  },
];

export const LandingPage: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <Box
      sx={{
        backgroundColor: theme.custom.whiteBackground,
      }}
    >
      <Box
        width="100%"
        height="100%"
        sx={{
          backgroundColor: theme.custom.navigationBackground,
        }}
      >
        <Box
          maxWidth={theme.custom.maxContentWidth}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'center',
              md: 'start',
            },
            padding: '10px',
            mx: 'auto',
            py: theme.spacing(6),
            gap: theme.spacing(6),
          }}
        >
          <Typography variant="h2" maxWidth="500px">
            {t('main:landing.title')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'primary.grey',
            }}
            maxWidth="500px"
          >
            {t('main:landing.subtitle')}
          </Typography>
          <Button variant="actionButton">{t('main:buttons.startNow')}</Button>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        maxWidth={theme.custom.maxContentWidth}
        sx={{
          mx: 'auto',
          py: theme.spacing(6),
          gap: theme.spacing(6),
        }}
      >
        <Typography variant="h3" textAlign="center">
          {t('main:landing.titleBottom')}
        </Typography>

        <LandingPageBoxes data={landingPageData} />

        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            ':before': {
              content: 'open-quote',
            },
            ':after': {
              content: 'close-quote',
            },
            paddingX: '10px',
          }}
        >
          {t('main:landing.quote')}
        </Typography>

        <Box>
          <Typography textAlign="center" variant="body2" color="#4ad5f6">
            {t('main:landing.quoteAuthor')}
          </Typography>

          <Typography
            textAlign="center"
            variant="body2"
            sx={{
              color: 'primary.grey',
            }}
          >
            {t('main:landing.authorTitle')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
