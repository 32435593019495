import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import * as yup from 'yup';
import { Form, Formik, FormikValues } from 'formik';
import { SnackBar, SnackBarProps } from './SnackBar';
import { useCompany } from '../../../selectors/selectors';
import { Address } from '../../../types/Address';
import { Button } from '../../../ui-components/Button';
import { Company } from '../../../types/Company';
import { AddressFields } from './AddressFields';
import { InputField } from './InputField';
import ClientService from '../../../services/ClientService';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { countryOptions } from '../../../utils/constants';

type Props = {
  companyId: number;
  handleCloseUpdate?: () => void;
  refetchCompanies?: () => void;
};

export const CompanyAddresses: React.FC<Props> = ({
  companyId,
  handleCloseUpdate,
  refetchCompanies,
}) => {
  const { t } = useTranslation(['main']);
  const isDeviceSM = useMediaQuery(theme.breakpoints.down('sm'));
  const editSupplierInfoPermission = useUserPermissions('edit_supplier_info');
  const { data: initialCompanyData, refetch: refetchCompany } =
    useCompany(companyId);
  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });

  const [companyData, setCompanyData] = useState<Company>();
  const [billingAddress, setBillingAddress] = useState<Address>();
  const [deliveryAddress, setDeliveryAddress] = useState<Address>({
    company_name: '',
    street_name: '',
    street_number: '',
    city: '',
    post_code: '',
    country: '',
  });
  const [initialValues, setInitialValues] = useState<FormikValues>({
    companyName: '',
    billingAddressStreet: '',
    billingAddressStreetNumber: '',
    billingAddressCity: '',
    billingAddressPostCode: '',
    billingAddressCountry: countryOptions[0].value,
    companyTaxId: '',
    companyLabelOrderCode: '',
    companyEmail: '',
    deliveryAddressStreet: '',
    deliveryAddressStreetNumber: '',
    deliveryAddressCity: '',
    deliveryAddressPostCode: '',
    deliveryAddressCountry: countryOptions[0].value,
  });

  const validationSchema = yup.object({
    companyName: yup.string().max(255).required(),
    billingAddressStreet: yup.string().max(255).required(),
    billingAddressStreetNumber: yup.string().max(255).required(),
    billingAddressPostCode: yup.string().max(255).required(),
    billingAddressCity: yup.string().max(255).required(),
    billingAddressCountry: yup.string().max(255).required(),
    companyTaxId: yup.string().max(255).required(),
    companyLabelOrderCode: yup.string().max(255).required(),
    companyEmail: yup.string().email().required(),
    deliveryAddressStreet: yup.string().max(255).required(),
    deliveryAddressStreetNumber: yup.string().max(255).required(),
    deliveryAddressPostCode: yup.string().max(255).required(),
    deliveryAddressCity: yup.string().max(255).required(),
    deliveryAddressCountry: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setCompanyData(initialCompanyData);
  }, [initialCompanyData]);

  useEffect(() => {
    setInitialValues(prevValue => ({
      ...prevValue,
      companyName: companyData?.name || '',
      companyTaxId: companyData?.tax_id || '',
      companyLabelOrderCode: companyData?.label_order_code || '',
      companyEmail: companyData?.email || '',
    }));
    setBillingAddress({
      company_name: companyData?.name || '',
      street_name: companyData?.street_name || '',
      street_number: companyData?.street_number || '',
      city: companyData?.city || '',
      post_code: companyData?.post_code || '',
      country: companyData?.country || countryOptions[0].value,
    });
    if (companyData?.addresses?.length !== 0) {
      const deliveryAddress = companyData?.addresses?.find(
        (address: Address) => address?.type_id === 1,
      );
      if (deliveryAddress) {
        setDeliveryAddress(deliveryAddress);
      }
    }
  }, [companyData]);

  useEffect(() => {
    setInitialValues(prevValue => ({
      ...prevValue,
      billingAddressStreet: billingAddress?.street_name || '',
      billingAddressStreetNumber: billingAddress?.street_number || '',
      billingAddressCity: billingAddress?.city || '',
      billingAddressPostCode: billingAddress?.post_code || '',
      billingAddressCountry: billingAddress?.country || '',
      deliveryAddressStreet: deliveryAddress?.street_name || '',
      deliveryAddressStreetNumber: deliveryAddress?.street_number || '',
      deliveryAddressCity: deliveryAddress?.city || '',
      deliveryAddressPostCode: deliveryAddress?.post_code || '',
      deliveryAddressCountry: deliveryAddress?.country || countryOptions[0].value,
    }));
  }, [billingAddress, deliveryAddress]);

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  const createDeliveryAddress = async (deliveryAddressData: Address) => {
    try {
      return await ClientService.createDeliveryAddress(deliveryAddressData);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateDeliveryAddress = async (
    deliveryAddressData: Address,
    addressId: number,
  ) => {
    try {
      return await ClientService.updateDeliveryAddress(
        deliveryAddressData,
        addressId,
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateCompany = async (
    submitCompanyData: Company,
    addressId: number,
  ) => {
    try {
      return await ClientService.updateCompany(submitCompanyData, addressId);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async data => {
          try {
            let companyFlag = false;
            let deliveryFlag = false;

            for (const [key] of Object.entries(data)) {
              if (data[key] !== initialValues[key]) {
                if (
                  key.indexOf('billing') === 0 ||
                  key.indexOf('company') === 0
                ) {
                  companyFlag = true;
                }
                if (key.indexOf('delivery') === 0) {
                  deliveryFlag = true;
                }
              }
            }

            if (deliveryAddress?.id && deliveryFlag) {
              const submitDeliveryAddress: Address = {
                id: deliveryAddress.id,
                company_name: data.companyName,
                street_name: data.deliveryAddressStreet,
                street_number: data.deliveryAddressStreetNumber,
                city: data.deliveryAddressCity,
                post_code: data.deliveryAddressPostCode,
                country: data.deliveryAddressCountry,
                type_id: 1,
              };
              deliveryFlag = false;
              const deliveryAddressData = await updateDeliveryAddress(
                submitDeliveryAddress,
                deliveryAddress.id,
              );
              if (deliveryAddressData) {
                setDeliveryAddress(deliveryAddressData);
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'success',
                  message: t('main:profile.successfulEdit') || '',
                });
              } else {
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'error',
                  message: t('main:profile.errorEdit') || '',
                });
              }
            }

            if (!deliveryAddress?.id && deliveryFlag) {
              const submitDeliveryAddress: Address = {
                company_id: companyId,
                company_name: data.companyName,
                street_name: data.deliveryAddressStreet,
                street_number: data.deliveryAddressStreetNumber,
                city: data.deliveryAddressCity,
                post_code: data.deliveryAddressPostCode,
                country: data.deliveryAddressCountry,
                type_id: 1,
              };
              deliveryFlag = false;
              const deliveryAddressData = await createDeliveryAddress(
                submitDeliveryAddress,
              );
              if (deliveryAddressData) {
                setDeliveryAddress(deliveryAddressData);
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'success',
                  message: t('main:profile.successfulEdit') || '',
                });
              } else {
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'error',
                  message: t('main:profile.errorEdit') || '',
                });
              }
            }

            if (companyData?.id && companyFlag) {
              const submitCompanyData: Company = {
                id: companyData.id,
                name: data.companyName,
                street_name: data.billingAddressStreet,
                post_code: data.billingAddressPostCode,
                street_number: data.billingAddressStreetNumber,
                city: data.billingAddressCity,
                country: data.billingAddressCountry,
                email: data.companyEmail,
              };
              if (data.companyTaxId !== companyData?.tax_id) {
                submitCompanyData.tax_id = data.companyTaxId;
              }
              companyFlag = false;
              const companyAddressData = await updateCompany(
                submitCompanyData,
                companyData.id,
              );
              if (companyAddressData) {
                setCompanyData(companyAddressData);
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'success',
                  message: t('main:profile.successfulEdit') || '123',
                });
              } else {
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'error',
                  message: t('main:profile.errorEdit') || '',
                });
              }
            }
            if (handleCloseUpdate) {
              handleCloseUpdate();
              refetchCompany();
            }
            if (refetchCompanies) {
              refetchCompanies();
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              display='flex'
              flexDirection={isDeviceSM ? 'column' : 'row'}
              justifyContent='space-between'
              paddingX='2rem'
              paddingY='1rem'
              gap={theme.spacing(4)}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                width={isDeviceSM ? '100%' : '49%'}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(4)}
                >
                  <Typography variant='body2' component='p'>
                    {t<string>('main:profile.companyDetails')}
                  </Typography>
                  <InputField
                    name='companyName'
                    category='profile'
                    fullWidth
                    disabled={!editSupplierInfoPermission}
                  />
                  <InputField
                    name='companyEmail'
                    category='profile'
                    fullWidth
                    disabled={!editSupplierInfoPermission}
                  />
                  {isDeviceSM && (
                    <>
                      <InputField
                        name={'companyTaxId'}
                        category='profile'
                        fullWidth
                        disabled
                      />
                      <InputField
                        name={'companyLabelOrderCode'}
                        category='profile'
                        fullWidth
                        disabled
                      />
                    </>
                  )}
                  {billingAddress && !isDeviceSM && (
                    <AddressFields
                      addressType='billingAddress'
                      address={billingAddress}
                      disabled={!editSupplierInfoPermission}
                    />
                  )}
                  {editSupplierInfoPermission && (
                    <Button
                      variant='actionButtonGreen'
                      disabled={!editSupplierInfoPermission}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                      sx={{
                        p: theme.spacing(1.5, 9),
                      }}
                    >
                      {t('main:profile.submitButton')}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gap={theme.spacing(4)}
                width={isDeviceSM ? '100%' : '49%'}
              >
                {!isDeviceSM && (
                  <>
                    <Typography variant='body2' component='p'>
                      {t<string>('main:profile.otherDetails')}
                    </Typography>
                    <InputField
                      name={'companyTaxId'}
                      category='profile'
                      fullWidth
                      disabled
                    />
                    <InputField
                      name={'companyLabelOrderCode'}
                      category='profile'
                      fullWidth
                      disabled
                    />
                  </>
                )}
                {billingAddress && isDeviceSM && (
                  <AddressFields
                    addressType='billingAddress'
                    address={billingAddress}
                    disabled={!editSupplierInfoPermission}
                  />
                )}
                {deliveryAddress && (
                  <AddressFields
                    addressType={'deliveryAddress'}
                    address={deliveryAddress}
                    disabled={!editSupplierInfoPermission}
                  />
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </>
  );
};
