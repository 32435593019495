import React, { useEffect, useState } from 'react';
import { TableRow } from '@mui/material';
import { StyledInputWithoutLabel } from '../../ui-components/StyledInputWithoutLabel';
import { StyledInputMaterialNumber } from '../../ui-components/StyledInputMaterialNumber';
import { StyledTableCell } from '../../ui-components/StyledTableSell';
import { RowData } from './interfaces';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

type CalculatorTableRowProps = {
  id: number;
  rowNumber: number
  afterUpdate: (rowId: number, rowData: RowData) => void;
  rowRollHeight: number;
  rollHeight: number,
  palLength: number,
  setRollHeight: (value: number) => void;
  rollWidthOrder?: number;
  rollsCountOrder?: number;
  materialNumberOrder?: string;
  totalOrder?: number;
  disabledInputs?: boolean;
  isInOrders?:boolean;
}
export const CalculatorTableRow: React.FC<CalculatorTableRowProps> = ({
  id,
  rowNumber,
  afterUpdate,
  rowRollHeight,
  rollHeight,
  palLength,
  setRollHeight,
  rollWidthOrder,
  rollsCountOrder,
  materialNumberOrder,
  totalOrder,
  disabledInputs,
  isInOrders,
}) => {
  const { t } = useTranslation(['main']);
  const [materialNumber, setMaterialNumber] = useState<string>('');
  const [rollWidth, setRollWidth] = useState<number>(0);
  const [rollCount, setRollCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowRollHeightState, setRowRollHeightState] = useState<number>(0);

  useEffect(() => {
    setMaterialNumber(materialNumberOrder ?? '');
    setRollWidth(rollWidthOrder ?? 0);
    setRollCount(rollsCountOrder ?? 0);
    setTotal(totalOrder ?? 0);
    if(isInOrders) {
      setRowRollHeightState(rowRollHeight);
    }
  }, []);

  useEffect(() => {
    setTotal((rollWidth / 1000) * rollHeight * rollCount);
  }, [rollWidth, rollCount, rollHeight]);

  useEffect(() => {
    afterUpdate(id, {
      materialNumber,
      rollWidth,
      rollCount,
      rollHeight: isInOrders ? rowRollHeightState : rollHeight,
      total,
    });
  }, [total, materialNumber, rowRollHeightState, rollHeight]);

  const formatWidthInput = (value: string) => {
    if (!rollWidth || +value < 100) {
      setRollWidth(+value ? Math.max(+value, 100) : 0);
    } else {
      setRollWidth(+value >= 100 ? +value : 0);
    }
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <StyledTableCell
        component="th"
        scope="row"
        align='center'
        sx={{
          margin: '15px 15px 14px 14px',
          fontFamily: 'ArialRounded',
          fontSize: '16px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#454f5b',
          borderBlockEndColor: theme.custom.dividerColor,
        }}
      >
        {`${rowNumber}.`}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align='center' sx={{ borderBlockEndColor: theme.custom.dividerColor }}>
        <StyledInputWithoutLabel
          width='100px'
          type={'number'}
          value={`${rollWidth}`}
          onChange={setRollWidth}
          onBlur={formatWidthInput}
          disabled={disabledInputs}
        />
      </StyledTableCell>
      <StyledTableCell align='center' sx={{ borderBlockEndColor: theme.custom.dividerColor }} >
        <StyledInputWithoutLabel
          width='100px'
          type={'number'}
          value={`${rollCount}`}
          onChange={setRollCount}
          disabled={disabledInputs}
        />
      </StyledTableCell>
      <StyledTableCell
        align='center'
        sx={{
          borderBlockEndColor: theme.custom.dividerColor,
          fontSize: '12px',
          fontWeight: '800',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#454f5b',
          minWidth: '100px',
        }}
      >
        {palLength ?
          `${palLength.toLocaleString('de-DE')} m`
          : rowNumber <= 1 ?
            <StyledInputWithoutLabel
              width='100px'
              type={'number'}
              value={`${isInOrders ? rowRollHeightState : rollHeight}`}
              onChange={(val: number) => isInOrders ? setRowRollHeightState(val) : setRollHeight(val)}
              disabled={disabledInputs}
            />
            : `${isInOrders ? rowRollHeightState.toLocaleString('de-DE') : rollHeight.toLocaleString('de-DE')} m`}
      </StyledTableCell>
      <StyledTableCell align='center' sx={{ borderBlockEndColor: theme.custom.dividerColor }}>
        <StyledInputMaterialNumber value={materialNumber} onChange={setMaterialNumber} disabled={disabledInputs} />
      </StyledTableCell>
      <StyledTableCell
        align='center'
        width="100px"
        sx={{
          borderBlockEndColor: theme.custom.dividerColor,
          fontSize: '12px',
          fontWeight: '800',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#454f5b',
        }}
      >
        {`${total.toLocaleString('de-DE')} ${t('main:configurationPage.qm')}`}
      </StyledTableCell>
    </TableRow>
  );
};
