import React from 'react';
import { Box } from '@mui/system';
import { SnackbarContainer } from './SnackbarContainer';
import theme from '../../theme';

type Props = {
    children: React.ReactNode
}
export const PageBase: React.FC<Props> = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      backgroundColor: theme.custom.bodyBackground,
    }}
  >
    <SnackbarContainer />
    {children}
  </Box>
);

