import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { v4 } from 'uuid';
import theme from '../theme';


export type DropdownOption = {
    name: string
    value: string | number;
}

type Props = {
    label?: string | null
    placeholder?: string
    data: DropdownOption[]
    initialValue?: string
    onChange: (s: string) => void
    disabled?: boolean
    name?: string
}

export const DropdownWrapper: React.FC<Props> = props => {
  const [labelId, setLabelId] = useState('');

  useEffect(() => {
    setLabelId(v4());
  }, []);

  return (
    <FormControl
      fullWidth={true}
      sx={{
        backgroundColor: theme.custom.whiteBackground,
      }}
    >
      {props.label &&
      <InputLabel id={labelId}>
        {props.label}
      </InputLabel>}
      <Select
        labelId={labelId}
        id={`select-${labelId}`}
        value={props.initialValue}
        label={props.label}
        onChange={e => props.onChange(e.target.value)}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            sx: {
              maxHeight: 200,
            },
          },
        }}
        disabled={props.disabled}
        name={props.name}
      >
        {props.data.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{
              minHeight: 'auto',
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


