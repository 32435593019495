import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import filterTopLayerIcon from '../../assets/face.svg';
import filterMiddleLayerIcon from '../../assets/adhesive.svg';
import filterBottomLayerIcon from '../../assets/back.svg';
import theme from '../../theme';
import {
  BackFilter,
  BaseFilter,
  FaceFilter,
  GlueFilter,
  QuickFilterMenu,
  QuickFilterOption,
  QuickFiltersData,
  ServiceType,
} from '../../types/Filters';
import { FiltersList } from './FiltersList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReplayIcon from '@mui/icons-material/Replay';
import { useAuth } from '../../contexts/AuthContext';

const iconSrc = {
  back: filterBottomLayerIcon,
  face: filterTopLayerIcon,
  glue: filterMiddleLayerIcon,
};

type iconSrcType = keyof typeof iconSrc;

type Props = {
  backValue: string[];
  certificatesValue: string[];
  faceValue: string[];
  glueValue: string[];
  servicesValue: string[];
  quickFiltersData: QuickFiltersData;
  onChangeFaceValue: (value: string[]) => void;
  onChangeGlueValue: (value: string[]) => void;
  onChangeBackValue: (value: string[]) => void;
  onChangeServicesValue: (value: string[]) => void;
  onChangeCertificatesValue: (value: string[]) => void;
};

export const QuickFilters: React.FC<Props> = props => {
  const {
    backValue,
    certificatesValue,
    faceValue,
    glueValue,
    servicesValue,
    quickFiltersData,
    onChangeFaceValue,
    onChangeGlueValue,
    onChangeBackValue,
    onChangeServicesValue,
    onChangeCertificatesValue,
  } = props;
  const { t } = useTranslation(['main']);
  const [openFilters, setOpenFilters] = useState(false);
  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const [availableFilters, setAvailableFilters] = useState<QuickFilterMenu[]>(
    [],
  );
  const { lang } = useAuth();

  const transformAvailableFilters = () => {
    if (quickFiltersData) {
      const newAvailableFilters: QuickFilterMenu[] = [];
      Object.entries(quickFiltersData).forEach(([key, value]) => {
        const type = key as iconSrcType;
        let filterOptions: QuickFilterOption[] = [];

        const prepareOptions = (filter: FaceFilter | GlueFilter, depth = 0) => {
          const option: QuickFilterOption = {
            id: filter.id,
            name: filter[lang === 'de' ? 'name_de' : 'name_en'],
            checked: !!(localStorage.getItem(key)?.split(',').find(id => id === `${filter.id}`)) || false,
            options: [],
          };
          option.options = filter.children.map(child => {
            const childOption = prepareOptions(child, depth + 1);
            option.checked = option.checked || childOption.checked;
            return childOption;
          });
          if (!depth) {
            filterOptions.push(option);
          }
          return option;
        };

        const prepareServiceOptions = (filter: ServiceType) => {
          Object.entries(filter).forEach(([filterKey]) => {
            if (filterKey === 'netto') {
              const option: QuickFilterOption = {
                id: 1,
                name: filterKey,
                options: [],
                checked: !!(localStorage.getItem(key)) || false,
              };
              filterOptions.push(option);
            }
          });
        };

        switch (key) {
        case 'face':
          (value as FaceFilter[]).map(filter => {
            prepareOptions(filter);
          });
          break;
        case 'glue':
          (value as GlueFilter[]).map(filter => {
            prepareOptions(filter);
          });
          break;
        case 'service':
          prepareServiceOptions(value as ServiceType);
          break;
        default:
          filterOptions = (value as BackFilter[] | BaseFilter[]).map(({ id, name, type }) => ({
            id,
            name,
            type,
            checked: !!(localStorage.getItem(key)?.split(',').find(storeId => storeId === `${id}`)) || false,
          }));
          break;
        }

        newAvailableFilters.push({
          type: key,
          icon: iconSrc[type],
          options: filterOptions,
        });
      });

      return newAvailableFilters;
    }
  };

  const updateChecked = (
    options: QuickFilterOption[] | undefined,
    checkedIds: string[],
  ) => {
    options?.forEach((option: QuickFilterOption) => {
      if (checkedIds.includes(`${option.id}`)) {
        option.checked = true;
      } else {
        option.checked = false;
      }
      updateChecked(option.options, checkedIds);
    });
  };

  const uncheckOptions = (
    options: QuickFilterOption[] | undefined,
  ) => {
    options?.forEach((option: QuickFilterOption) => {
      if (option) {
        option.checked = false;
        if(option.options) {
          uncheckOptions(option.options);
        }
        return option;
      }
    });
  };

  const uncheckAll = () => {
    // change filters on screen
    const filters = [...availableFilters];
    filters.map(list => uncheckOptions(list.options));

    // clear localStorage
    localStorage.setItem('face', '');
    localStorage.setItem('glue', '');
    localStorage.setItem('back', '');
    localStorage.setItem('certificates', '');
    localStorage.setItem('service', '');

    // make new request
    onChangeFaceValue([]);
    onChangeGlueValue([]);
    onChangeBackValue([]);
    onChangeServicesValue([]);
    onChangeCertificatesValue([]);

    setAvailableFilters(filters);
  };

  const updateCheckboxState = (
    id: string,
    checked: boolean,
    state: string[],
    setState: (value: string[]) => void,
    filter: string,
  ) => {
    const updatedServicesList = [...state];

    if (checked) {
      if (!updatedServicesList.includes(id)) {
        updatedServicesList.push(id);
        localStorage.setItem(filter, updatedServicesList.join(','));
      }
    } else {
      if (updatedServicesList.includes(id)) {
        updatedServicesList.splice(state.indexOf(id), 1);
        const items = localStorage.getItem(filter)?.split(',');
        if (items) {
          items?.splice(state.indexOf(id), 1);
          localStorage.setItem(filter, items.join(','));
        }
      } else {
        localStorage.setItem(filter, '');
      }
    }
    setState(updatedServicesList);
  };

  const updateCheckboxStateService = (
    checked: boolean,
    state: string[],
    setState: (value: string[]) => void,
    filter: string,
  ) => {
    let updatedServicesList = [...state];

    if (checked) {
      if (!updatedServicesList.length) {
        updatedServicesList = quickFiltersData.service.netto.map(op => `${op.id}`);
        localStorage.setItem(filter, updatedServicesList.join(','));
      }
    } else {
      if (updatedServicesList.length) {
        updatedServicesList = [];
        localStorage.setItem(filter, '');
      } else {
        localStorage.setItem(filter, '');
      }
    }
    setState(updatedServicesList);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [type, id] = event.target.name.split('_');

    switch (type) {
    case 'face':
      updateCheckboxState(
        id,
        event.target.checked,
        faceValue,
        onChangeFaceValue,
        'face',
      );
      break;
    case 'glue':
      updateCheckboxState(
        id,
        event.target.checked,
        glueValue,
        onChangeGlueValue,
        'glue',
      );
      break;
    case 'back':
      updateCheckboxState(
        id,
        event.target.checked,
        backValue,
        onChangeBackValue,
        'back',
      );
      break;
    case 'certificates':
      updateCheckboxState(
        id,
        event.target.checked,
        certificatesValue,
        onChangeCertificatesValue,
        'certificates',
      );
      break;
    case 'service':
      updateCheckboxStateService(
        event.target.checked,
        servicesValue,
        onChangeServicesValue,
        'service',
      );
      break;
    }
  };

  useEffect(() => {
    const newAvailableFilters = transformAvailableFilters();

    if (newAvailableFilters) {
      setAvailableFilters(newAvailableFilters);
    }
  }, [quickFiltersData]);

  useEffect(() => {
    if (availableFilters.length > 0) {
      const updatedAvailableFilters = [...availableFilters];

      [
        ...updatedAvailableFilters[0].options,
        ...updatedAvailableFilters[1].options,
        ...updatedAvailableFilters[2].options,
        ...updatedAvailableFilters[3].options,
        ...updatedAvailableFilters[4].options,
      ].map(list => {
        uncheckOptions(list.options);
      });

      updateChecked(updatedAvailableFilters[0].options, faceValue);
      updateChecked(updatedAvailableFilters[1].options, glueValue);
      updateChecked(updatedAvailableFilters[2].options, backValue);
      updateChecked(updatedAvailableFilters[3].options, certificatesValue);
      updateChecked(updatedAvailableFilters[4].options, servicesValue);
      setAvailableFilters(updatedAvailableFilters);
    }
  }, [faceValue, glueValue, backValue, certificatesValue, servicesValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: theme.custom.whiteBackground,
        px: 1,
        pt: 1,
        pb: { sm: 1, md: 6 },
      }}
    >
      <Box display={'flex'} justifyContent={isDownMD ? 'space-between' : 'start'}>
        <Button
          variant="actionButtonNoBG"
          disableRipple={true}
          onClick={() => setOpenFilters(!openFilters)}
        >
          {t('main:labelDetails.quickFilters')}
          {isDownMD &&
            (openFilters ? (
              <KeyboardArrowUpIcon
                sx={{ color: theme.custom.footerColorSecondary }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ color: theme.custom.footerColorSecondary }}
              />
            ))}
        </Button>
        <IconButton onClick={uncheckAll}>
          <ReplayIcon />
        </IconButton >
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        component={isDownMD ? Collapse : Box}
        timeout="auto"
      >
        {availableFilters.length > 0 &&
          availableFilters.map((filterMenu: QuickFilterMenu, index: number) => (
            <Box key={index} display="flex" flexDirection="row">
              <Box position="relative" width="50px" height="50px">
                {filterMenu.icon && (
                  <img
                    src={filterMenu.icon}
                    alt={'icon'}
                    style={{
                      objectFit: 'contain',
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="h6">
                  {t(`main:filters.${filterMenu.type}`)}
                </Typography>
                <FiltersList
                  key={`${filterMenu.type}`}
                  type={filterMenu.type}
                  options={filterMenu.options}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
