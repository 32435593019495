import { AxiosError } from 'axios';

export type LabelApiErrorResponse = {
    errors: Record<string, string | string[]>
}

export const parseAxiosError = (error: AxiosError<LabelApiErrorResponse>) => {
  if(!error.response){
    return [error.message];
  }

  if(error.response.data.errors){
    const errors: (string | string[])[] = [];
    Object.keys(error.response.data.errors).map(key => {
      if(error.response) {
        errors.push(error.response.data.errors[key]);
      }
    });
    return errors.flat();
  }

  return ['Unknown Error'];
};
