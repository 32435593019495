import React from 'react';
import { Paper } from '@mui/material';
import { RestrictedComponent } from '../../../ui-components/RestrictedComponent';
import { CompanyAddresses } from '../Components/CompanyAddresses';
import { AlternativeAddresses } from '../Components/AlternativeAddresses';

type Props = {
  companyId: number;
  paperElevation: number;
};

export const ProfileForm: React.FC<Props> = ({ companyId, paperElevation }) => (
  <Paper elevation={paperElevation}>
    <CompanyAddresses companyId={companyId} />
    <RestrictedComponent permission="edit_alternative_data">
      <AlternativeAddresses companyId={companyId} />
    </RestrictedComponent>
  </Paper>
);
