import labelAPI from '../labelAPI';

export const forgottenPassword = async (email: string) => 
  await labelAPI.post('forgot-password', {
    email,
  });


type ResetPasswordType = {
    email: string, 
    token: string, 
    password: string, 
    password_confirmation: string
}

export const resetPassword = async (data: ResetPasswordType) => 
  await labelAPI.post('reset-password', data);
