import React from 'react';
import { Box, Typography } from '@mui/material';
import { InputField, InputProps } from '../InputField';
import { useField } from 'formik';

type Props = InputProps & {
    name: string
}

export const FormikInputField: React.FC<Props> = ({ icon, name, ...props }) => {
  const [field, meta] = useField({
    type: props.type,
    name: name,
  });

  return(
    <Box 
      display="flex"
      flexDirection="column"
    >
      <InputField
        {...props}
        icon={icon}
        {...field}
      />
      {meta.error && 
      <Typography variant="error">
        {meta.error}
      </Typography>}
    </Box>
  );
};

  
