import React from 'react';
import { Box } from '@mui/system';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';
import { PageBase } from './PageBase';

export const PageLayout: React.FC = () => (
  <PageBase>
    <Navigation />
    <Outlet />
    <Box marginTop="auto">
      <Footer />
    </Box>
  </PageBase>
);

