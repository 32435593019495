import React, { useState, useEffect } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  IconButton,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../theme';
import ClientService from '../../../services/ClientService';
import { NewSupplier } from '../../../types/Suppliers';
import { Address } from '../../../types/Address';
import { InputField } from '../Components/InputField';
import { AddressFields } from '../Components/AddressFields';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';
import { countryOptions } from '../../../utils/constants';

interface HandleClose {
  handleClose: () => void;
  refetchTableSuppliers: () => void;
}

export const NewSupplierForm: React.FC<HandleClose> = ({
  handleClose,
  refetchTableSuppliers,
}): JSX.Element => {
  const { t } = useTranslation(['main']);

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });
  const [billingAddress, setBillingAddress] = useState<Address>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    supplierName: '',
    supplierAddressStreet: '',
    supplierAddressStreetNumber: '',
    supplierAddressCountry: countryOptions[0].value,
    supplierAddressCity: '',
    supplierAddressPostCode: '',
    supplierEmail: '',
    supplierNumber: '',
    supplierPhone: '',
    supplierFax: '',
  });

  const validationSchema = yup.object({
    supplierName: yup.string().max(255).required(),
    supplierAddressStreet: yup.string().max(255).required(),
    supplierAddressStreetNumber: yup.string().max(255).required(),
    supplierAddressCountry: yup.string().max(255).required(),
    supplierAddressCity: yup.string().max(255).required(),
    supplierAddressPostCode: yup.string().max(255).required(),
    supplierEmail: yup.string().max(255).required(),
    supplierNumber: yup.string().max(255).required(),
    supplierPhone: yup.string().max(255).required(),
    supplierFax: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setBillingAddress({
      company_name: initialValues.supplierName || '',
      street_name: initialValues.supplierAddressStreet || '',
      street_number: initialValues.supplierAddressStreetNumber || '',
      post_code: initialValues.supplierAddressPostCode || '',
      city: initialValues.supplierAddressCity || '',
      country: initialValues.supplierAddressCountry || '',
    });
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingBottom: '0',
        }}
      >
        {t('main:suppliers.addNewSupplierTitle')}
        <IconButton aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async data => {
          try {
            const newSupplier: NewSupplier = {
              name: data.supplierName,
              street_name: data.supplierAddressStreet,
              street_number: data.supplierAddressStreetNumber,
              country: data.supplierAddressCountry,
              city: data.supplierAddressCity,
              post_code: data.supplierAddressPostCode,
              email: data.supplierEmail,
              supplier_number: data.supplierNumber,
              phone: data.supplierPhone,
              fax: data.supplierFax,
            };
            await ClientService.sendNewSupplier(newSupplier);
            refetchTableSuppliers();
            setOpenSnackBar({
              openSnackBar: true,
              type: 'success',
              message: t('main:profile.successfulEdit') || '',
            });
            setTimeout(() => {
              handleClose();
            }, 1200);
          } catch (error) {
            console.log(error);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'error',
              message: t('main:profile.errorEdit') || '',
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              paddingX='2rem'
              sx={{
                gap: {
                  xs: theme.spacing(2),
                },
              }}
            >
              <Box
                display='flex'
                gap={theme.spacing(2)}
                width='100%'
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <InputField
                  name='supplierName'
                  category='suppliers'
                  fullWidth
                />
                <InputField
                  name='supplierEmail'
                  category='suppliers'
                  fullWidth
                />
              </Box>
              <Box
                display='flex'
                gap={theme.spacing(2)}
                width='100%'
                sx={{
                  flexDirection: {
                    xs: 'column-reverse',
                    sm: 'row',
                  },
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(2)}
                  width='100%'
                >
                  <AddressFields
                    addressType='supplierAddress'
                    address={billingAddress}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(2)}
                  width='100%'
                >
                  <Typography variant='body2' component='p' margin={'1px'}>
                    {t('main:suppliers.otherDetails')}
                  </Typography>
                  <InputField
                    name='supplierNumber'
                    category='suppliers'
                    fullWidth
                  />
                  <InputField
                    name='supplierPhone'
                    category='suppliers'
                    fullWidth
                  />
                  <InputField
                    name='supplierFax'
                    category='suppliers'
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: '2rem',
              }}
            >
              <Button
                type='submit'
                variant='actionButtonRed'
                onClick={submitForm}
                sx={{
                  p: theme.spacing(1.5, 9),
                }}
                disabled={isSubmitting}
              >
                {t('main:suppliers.createSupplier')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </Box>
  );
};
