import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../types/Product';
import { ReactComponent as Checkmark } from '../../assets/checkmark.svg';
import { ReactComponent as Munus } from '../../assets/minus.svg';
import { ProductInfoBlock } from '../../ui-components/ProductInfoBlock';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { useAuth } from '../../contexts/AuthContext';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { DescriptionModal } from '../../ui-components/DescriptionModal';

type Props = {
    data: Product
}

export const FilteredResult: React.FC<Props> =({ data }) => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const { userLevel } = useAuth();
  const userIsLevel4 = userLevel === 'level_4';
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const mainSections = [
    {
      name: t('main:labelDetails.usableWidth'),
      value: data.services[0]?.usable_width,
    },
    {
      name: t('main:labelDetails.netWidth'),
      value: data.services[0]?.net_wid_service ? <Checkmark /> : <Munus />,
    },
    {
      name: t('main:labelDetails.minimumAmount'),
      value: data.services[0]?.min_order,
    },
    {
      name: t('main:labelDetails.inStock'),
      value: data.supplier.name === 'Fedrigoni' ? <Checkmark /> : data?.is_stock ? <Checkmark /> : <Munus />,
    },
    {
      name: t('main:labelDetails.deploymentTime'),
      value:  data.supplier.name === 'Fedrigoni' ? t('main:labelDetails.from72') : t('main:labelDetails.72h'),
    },
  ];


  const subSections = [
    {
      name: t('main:labelDetails.ean'),
      value:  data.article_number,
    },
    {
      name: '',
      value: '',
    },
    {
      name: t('main:labelDetails.productCode'),
      value: data.category,
    },
    {
      name: '',
      value:  '',
    },
    {
      name: '',
      value: '',
    },
  ];

  const handleDetailsClick = () => {
    navigate(`/article/${data.id}`, { state: data });
  };

  const internalID = () => {
    let value = '';
    const face = data?.face_group?.internal_id;
    const glue = data?.glue_group?.internal_id;
    const back = data?.back_group?.internal_id;

    if (face) {
      value = value.concat(face);
    } else {
      value = value.concat(' - ');
    }
    if (glue) {
      value = value.concat('/', glue);
    } else {
      value = value.concat('/ - ');
    }
    if (back) {
      value = value.concat('/', back);
    } else {
      value = value.concat('/ - /');
    }

    return value;
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
  };

  return(
    <Box
      boxShadow='2px 6px 16px 0 rgba(196, 205, 213, 0.5)'
      sx={{
        backgroundColor: theme.custom.whiteBackground,
        p: { xs: theme.spacing(2), md: theme.spacing(1, 3) },
      }}
    >
      <Box
        display='flex'
      >
        <Typography
          variant='h6'
        >
          {data.name.replaceAll('/', ' | ')}
        </Typography>
        {userIsLevel4 &&
          <Typography variant='h6' ml='30px' color={theme.palette.primary.red}>
            {internalID()}
          </Typography>}
      </Box>


      <Box
        display='flex'
        flexWrap='nowrap'
        width='100%'
        gap={theme.spacing(2)}
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      >
        <img
          src={data.image}
          alt={'Label Icon'}
          width='90px'
          height='90px'
        />
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          flexDirection='column'
          gap={theme.spacing(1)}
        >
          <Box
            display='flex'
            border='1px solid #c4cdd5'
            borderRadius='3px'
            p={theme.spacing(1)}
            m={0}
            sx={{
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              justifyContent: { xs: 'space-evenly', sm: 'space-between' },
            }}
          >
            {mainSections.map((section, index) => (
              <Box
                key={index}
                sx={{
                  p: 0,
                  m: { xs: '5px 10px', md: '0' },
                }}
              >
                <Typography
                  variant='body4Light'
                  textAlign='center'
                >
                  {section.name}
                </Typography>
                <Typography
                  variant='body3Dark'
                  textAlign='center'
                >
                  {section.value}
                </Typography>
              </Box>
            ))}

          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            m={0}
            sx={{
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              p: { xs: '0', md: theme.spacing(1) },
            }}
          >
            {subSections.map((section, index) => (
              <Box
                key={index}
                sx={{
                  p: 0,
                  m: { xs: '5px 10px', md: '0' },
                }}
              >
                <ProductInfoBlock label={section.name} value={section.value} />
              </Box>
            ))}
          </Box>
        </Box>

        <Grid display='flex' flexDirection='column' justifyContent='space-between'>
          <Grid display='flex' flexDirection='column' justifyContent='space-between' height={ '50%' }>
            <Grid item height={ '50%' }>
              <RestrictedComponent permission='read_product_information'>
                <Button
                  variant='actionButtonGreenBig'
                  onClick={handleDetailsClick}
                  sx={{
                    height: '50%',
                    paddingY: '9px',
                  }}
                >
                  {t('main:buttons.details')}
                </Button>
              </RestrictedComponent>
            </Grid>
            <Grid item>
              {data?.prices[0]?.price &&
                <Typography variant='body3Dark' sx={{ width: '200%' }}>
                  {`${parseFloat(data.prices[0]?.price).toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 3,
                  })} per ${data?.prices[0]?.quantity.toLocaleString('de-DE')} qm`}
                </Typography>}
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography
                variant='checkbox'
              >
                {data.services[0]?.product_service_group?.name}
              </Typography>
              <IconButton
                onClick={() => {
                  setOpenDescription(!openDescription);
                  setDescription(data.services[0]?.product_service_group?.description);
                }}
              >
                <InfoIcon />
              </IconButton>
            </Grid>
            {data.services.length > 1 &&
              <Grid item>
                <Typography
                  variant='checkbox'
                >
                  {`+${data.services.length}`}
                </Typography>
              </Grid>}
          </Grid>
        </Grid>
      </Box>
      <DescriptionModal
        openDescription={openDescription}
        title={data.services[0]?.product_service_group?.name}
        description={description}
        handleClose={handleCloseDescription}
      />
    </Box>
  );
};


