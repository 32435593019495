import React from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from '../assets/labelorder-logo.svg';

type Props = {
    width: number | string,
    height: number | string
}

export const Logo: React.FC<Props> = (props: Props) =>
  <Link to='/'>
    <img
      src={LogoSVG}
      alt="logo"
      height={props.height}
      width={props.width}
    />
  </Link>;
