import { Paper } from '@mui/material';
import React from 'react';
import { RowData } from './interfaces';
import { RowCut } from './Rows/RowCut';
import { RowOffcut } from './Rows/RowOffcut';
import { RowLastMega } from './Rows/RowLastMega';

type RollProps = {
  rows: RowData[];
  total: number,
  service: string | undefined,
}

export const LargeRoll: React.FC<RollProps> = ({
  rows,
  total,
  service,
}) =>
  (
    <Paper elevation={24} sx={{ display: 'flex', width: '376px', height: '109px', border: '1px solid rgb(184,184,184)', margin: '18px 0 28px 5px', boxShadow: '0 -15px 47px -7px rgba(33, 43, 54, 0.22)' }} >
      {rows.map((row, index) => (
        service === 'MEGA' ? (
          index !== rows.length - 1 ? (
            <RowCut key={row.key} row={row} total={total} />
          ) : ( row.materialNumber === 'offcut' ? (
            <RowLastMega key={row.key} row={row} total={total} />) : (<RowCut key={row.key} row={row} total={total} />))
        ) : (
          index !== rows.length - 1 ? (
            <RowCut key={row.key} row={row} total={total} />
          ) : ( row.materialNumber === 'offcut' ? (
            <RowOffcut key={row.key} row={row} total={total} />) : (<RowCut key={row.key} row={row} total={total} />
          )))
      ))}
    </Paper>
  );




