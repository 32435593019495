import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import { useAuth } from '../../../contexts/AuthContext';
import ClientService from '../../../services/ClientService';
import { InputField } from '../Components/InputField';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';
import { UpdateUserFormNewData } from '../../../types/User';

export const UserProfile: React.FC = () => {
  const { user, setUser } = useAuth();
  const userInfo = user && user.data.userInfo;

  const isDeviceMD = useMediaQuery(theme.breakpoints.down('md'));

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });

  const [initialValues, setInitialValues] = useState<FormikValues>({
    userFirstName: '' || userInfo?.first_name,
    userLastName: '' || userInfo?.last_name,
    userEmail: '' || userInfo?.email,
    userOrderEmail: '' || userInfo?.order_email,
    userPhone: '' || userInfo?.phone,
    oldPassword: '',
    password: '',
    passwordConfimation: '',
  });

  const validationSchema = yup.object({
    id: yup.number(),
    companyId: yup.number(),
    userFirstName: yup.string().max(255).required(),
    userLastName: yup.string().max(255).required(),
    userEmail: yup.string().max(255).required(),
    userOrderEmail: yup.string().max(255).required(),
    userPhone: yup.string().max(255).required(),
    oldPassword: yup.string().max(255),
    password: yup.string().max(255),
    passwordConfirmation: yup.string().max(255),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setInitialValues({
      userFirstName: userInfo?.first_name,
      userLastName: userInfo?.last_name,
      userEmail: userInfo?.email,
      userOrderEmail: userInfo?.order_email,
      userPhone: userInfo?.phone,
      locale: userInfo?.locale,
    });
  }, [userInfo]);
  
  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };


  return (
    userInfo && (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async data => {
            try {
              const newData: UpdateUserFormNewData = {
                company_id: data.companyId,
                id: data.id,
                first_name: data.userFirstName,
                last_name: data.userLastName,
                order_email: data.userOrderEmail,
                phone: data.userPhone,
                email: data.userEmail !== userInfo?.email ? data.userEmail : undefined,
              };
              if (data.oldPassword) {
                newData.old_password = data.oldPassword;
                newData.password = data.password;
                newData.password_confirmation = data.passwordConfirmation;
              }
              const response = await ClientService.updateUserOwnInfo(newData);
              if (user && response) {
                setUser({
                  ...user,
                  data: { ...user.data, userInfo: response },
                });
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'success',
                  message: t('main:profile.successfulEdit') || '',
                });
              } else {
                setOpenSnackBar({
                  openSnackBar: true,
                  type: 'error',
                  message: t('main:profile.errorEdit') || '',
                });
              }
            } catch (error) {
              console.log('Error', error);
              setOpenSnackBar({
                openSnackBar: true,
                type: 'error',
                message: t('main:profile.errorEdit') || '',
              });
            }
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Box
                display='flex'
                justifyContent='space-between'
                padding='2rem'
                gap={isDeviceMD ? theme.spacing(4) : theme.spacing(10)}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                  },
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  width={isDeviceMD ? '100%' : '49%'}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                  >
                    <Typography variant='body2' component='p' margin={'1px'}>
                      {t('main:users.title')}
                    </Typography>
                    <InputField name='userFirstName' category='users' fullWidth />
                    <InputField name='userLastName' category='users' fullWidth />
                    <InputField name='userEmail' category='users' fullWidth />
                    <InputField name='userOrderEmail' category='users' fullWidth />
                    <InputField name='userPhone' category='users' fullWidth />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(4)}
                  width={isDeviceMD ? '100%' : '49%'}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                  >
                    <Typography variant='body2' component='p' margin={'1px'}>
                      {t('main:users.changePassword')}
                    </Typography>
                    <InputField
                      name='oldPassword'
                      category='users'
                      fullWidth
                      type='password'
                    />
                    <InputField
                      name='password'
                      category='users'
                      fullWidth
                      type='password'
                    />
                    <InputField
                      name='passwordConfirmation'
                      category='users'
                      fullWidth
                      type='password'
                    />
                  </Box>
                </Box>
              </Box>
              <Button
                type='submit'
                variant='actionButtonRed'
                onClick={submitForm}
                sx={{
                  p: theme.spacing(1.5, 9),
                  margin: '0 2rem 2rem 2rem',
                }}
                disabled={isSubmitting}
              >
                {t('main:users.updateProfile')}
              </Button>
            </Form>
          )}
        </Formik>
        {openSnackBar.openSnackBar && (
          <SnackBar
            type={openSnackBar.type}
            openSnackBar={openSnackBar.openSnackBar}
            handleCloseSnackbar={handleCloseSnackbar}
            message={openSnackBar.message}
          />
        )}
      </>
    )
  );
};
