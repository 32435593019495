import React from 'react';
import styled from '@emotion/styled';
import { Tab } from '@mui/material';
import theme from '../../theme';


type StyledTabProps = {
  label: string;
  disableRipple: boolean;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab {...props} />
))(() => ({
  textTransform: 'none',
  fontWeight: 'normal',
  fontSize: '14px',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  marginRight: '3px',
  backgroundColor: theme.custom.bodyBackground,
  borderRadius: '3px',
  border: `1px solid ${theme.custom.borderGrey}`,
  color: theme.palette.primary.darkGrey,
  '&.Mui-selected': {
    backgroundColor: theme.custom.borderGrey,
    color: theme.palette.primary.darkGrey,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));
