import React, { useState } from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { Email } from '@mui/icons-material';
import { forgottenPassword } from '../utils/loginFunctions';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInputField } from '../ui-components/formik/FormikInputField';
import { Button } from '../ui-components/Button';

export const ForgottenPassword: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [responseStatus, setResponseStatus] = useState<number | null>();

  const validationSchema = yup.object({
    email: yup.string().email().required(),
  });

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async data => {
        await forgottenPassword(data.email).then(response => setResponseStatus(response.status));
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box
            flexDirection='column'
            maxWidth={theme.custom.maxContentWidth}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginY: {
                xs: '30px',
                md: '0px',
              },
              padding: '10px',
              mx: 'auto',
              py: theme.spacing(6),
              gap: theme.spacing(3),
            }}
          >
            <Snackbar
              open={!!responseStatus}
              autoHideDuration={2000}
              onClose={() => setResponseStatus(null)}
              message={responseStatus === 204 ? t('main:forgottenPassword.resetSuccessful') : t('main:forgottenPassword.resetPasswordError')}
            />

            <Typography
              variant='h2'
              sx={{
                marginY: {
                  xs: '30px',
                  md: '0px',
                },
              }}
            >
              {t('main:forgottenPassword.title')}
            </Typography>

            <FormikInputField
              name='email'
              icon={<Email />}
              autoFocus={true}
              fullWidth={true}
              sx={{
                width: {
                  xs: '100%',
                  sm: '300px',
                },
                marginY: {
                  xs: '30px',
                  md: '0px',
                },
              }}
              type='email'
              placeholder={t<string>('main:forgottenPassword.email')}
            />

            <Button
              variant='actionButton'
              isLoading={isSubmitting}
              onClick={submitForm}
              sx={{
                marginY: {
                  xs: '30px',
                  md: '0px',
                },
                paddingX: {
                  xs: '50px',
                  md: '32px',
                },
              }}
            >
              {t('main:buttons.send')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
