import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import ClientService from '../../services/ClientService';
import { SingleSupplier } from '../../types/Suppliers';
import {
  DropdownOption,
  DropdownWrapper,
} from '../../ui-components/DropdownWrapper';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { OrdersBlock } from './OrdersBlock';

type CompaniesListItem = {
  id: string,
  name: string
}

const fetchAllSuppliers = () => {
  try {
    return ClientService.getAllSuppliers();
  } catch (e){
    console.log('Error getting all suppliers list');
  }
};

const fetchAllCompanies = () => {
  try {
    return ClientService.getAllCompaniesForSelect();
  } catch (e){
    console.log('Error getting all companies list');
  }
};

type OrdersTabPanelProps = {
  ordersType: string;
};

export const OrdersTabPanel = (props: OrdersTabPanelProps): JSX.Element => {
  const { t } = useTranslation(['main']);
  const { ordersType } = props;
  const { user } = useAuth();
  const readComapnyShoppingCartPermission = useUserPermissions('read_company_shopping_cart');
  const readAllShoppingCartPermission = useUserPermissions('read_all_shopping_cart');

  const [companiesList, setCompaniesList] = useState<CompaniesListItem[]>([]);
  const [suppliers, setSuppliers] = useState<SingleSupplier[]>([]);

  const [currentCompanyId, setCurrentCompanyId] = useState(0);
  const [companiesOptions, setCompanyOptions] = useState<DropdownOption[]>([]);

  const handleSetCompanyId = (value: string) => {
    setCurrentCompanyId(parseInt(value));
  };

  useEffect(() => {
    (async () => {
      if (readComapnyShoppingCartPermission) {
        const suppliersData = await fetchAllSuppliers();
        if(suppliersData) {
          setSuppliers(suppliersData);
        }
      }
      if (readAllShoppingCartPermission) {
        const companiesData = await fetchAllCompanies();
        if(companiesData) {
          setCompaniesList(companiesData);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (user && user?.data.userInfo.company_id > 0) {
      setCurrentCompanyId(user?.data.userInfo.company_id);
    }
  }, [user]);

  useEffect(() => {
    if (companiesList && readAllShoppingCartPermission) {
      setCompanyOptions(companiesList.map(
        (company: CompaniesListItem) => ({
          name: company.name,
          value: company.id })));
    }
  }, [companiesList]);

  return (
    <Box>
      {currentCompanyId > 0 &&
        <RestrictedComponent permission='read_all_shopping_cart'>
          <Grid container={true} spacing={2} justifyContent='flex-start' mb={6}>
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={4}
            >
              {companiesList && companiesOptions.length > 0 && (
                <DropdownWrapper
                  data={companiesOptions}
                  label={t('main:orders.selectCompanyLabel')}
                  onChange={value => handleSetCompanyId(value)}
                  initialValue={`${currentCompanyId}`}
                  name='companiesOptions'
                />
              )}
            </Grid>
          </Grid>
        </RestrictedComponent>}
      {currentCompanyId > 0 && suppliers &&
        suppliers?.map((supplier: SingleSupplier) => (
          <OrdersBlock
            key={`supplier_key_${supplier.id}`}
            supplierId={supplier.id}
            supplierName={supplier.name}
            ordersType={ordersType}
            companyId={currentCompanyId}
          />
        ))}
    </Box>
  );
};
