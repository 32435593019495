import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { PageLayout } from './pages/PageLayout/PageLayout';
import { NotFoundPage } from './pages/NotFoundPage';
import { LoginPage } from './pages/LoginPage';
import { ForgottenPassword } from './pages/ForgottenPassword';
import { ProtectedPageLayout } from './pages/PageLayout/ProtectedPageLayout';
import { AuthProvider } from './contexts/AuthContext';
import { ResetPassword } from './pages/ResetPassword';
import { ApiProvider } from './contexts/ApiContext';
import { Report } from './pages/Report/Report';
import { Contact } from './pages/Contact/Contact';
import { Faq } from './pages/Faq/Faq';
import { SuccessfulOrder } from './pages/SuccessfulOrder/SuccessfulOrder';
import { SearchPageWrapper } from './pages/SearchPage/SearchPageWrapper';
import { ProductPageWrapper } from './pages/ProductConfigurationPage/ProductPageWrapper';
import { OrdersPageWrapper } from './pages/Orders/OrdersPageWrapper';
import { ProfilePageWrapper } from './pages/Profile/ProfilePageWrapper';

const App: React.FC = () => (
  <BrowserRouter>
    <AuthProvider>
      <ApiProvider>
        <Routes>

          <Route element={<PageLayout />}>
            <Route
              path="/"
              element={<LandingPage />}
            />
            <Route
              path="/login"
              element={<LoginPage />}
            />
            <Route
              path="/forgotten-password"
              element={<ForgottenPassword />}
            />
            <Route
              path="/reset-password"
              element={<ResetPassword />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
          </Route>

          <Route element={<ProtectedPageLayout />} >
            <Route
              path="/article"
              element={<SearchPageWrapper />}
            />
            <Route
              path="/article/:id"
              element={<ProductPageWrapper />}
            />
            <Route
              path="/orders"
              element={<OrdersPageWrapper />}
            />
            <Route
              path="/report"
              element={<Report />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
            <Route
              path="/profile"
              element={<ProfilePageWrapper />}
            />
            <Route
              path="/faq"
              element={<Faq />}
            />
            <Route
              path="/success"
              element={<SuccessfulOrder />}
            />
          </Route>
        </Routes>
      </ApiProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
