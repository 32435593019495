import React from 'react';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { CellData, EnhancedTableProps } from '../interfaces';
import theme from '../../../theme';

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { headCells, order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof CellData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.palette.primary.lightGray }}>
        {headCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            colSpan={index === 0 ? 2 : 1}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }}>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
