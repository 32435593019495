import React from 'react';
import { Alert, Snackbar } from '@mui/material';

export type SnackBarProps = {
  type?: 'success' | 'error';
  message?: string;
  openSnackBar?: boolean;
  handleCloseSnackbar?(): void;
};

const styles = {
  success: { width: '100%', backgroundColor: '#95cdb1' },
  error: { width: '100%', backgroundColor: '#e2000a' },
};

export const SnackBar: React.FC<SnackBarProps> = ({
  type,
  message,
  openSnackBar,
  handleCloseSnackbar,
}) => (
  <Snackbar
    open={openSnackBar}
    autoHideDuration={3000}
    onClose={handleCloseSnackbar}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <Alert
      onClose={handleCloseSnackbar}
      variant="filled"
      severity={type}
      sx={type && styles[type]}
    >
      {message}
    </Alert>
  </Snackbar>
);
