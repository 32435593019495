import { STORAGE_TOKEN } from '../utils/constants';

class Client {
  // Single source of truth is LocalStorage!
  // The idea is that the user can clear his storage/cookie and there would be a bug!
  static getToken (): string | undefined {
    let token;
    // main source
    const storedToken = localStorage.getItem(STORAGE_TOKEN);
    if (storedToken) {
      token = storedToken;
    }
    return token;
  }
}

export default Client;
