import React, { useState, useEffect } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useSupplier } from '../../../selectors/selectors';
import {
  Button,
  Typography,
  Box,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClientService from '../../../services/ClientService';
import { NewSupplier } from '../../../types/Suppliers';
import { Address } from '../../../types/Address';
import { InputField } from '../Components/InputField';
import { AddressFields } from '../Components/AddressFields';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';

interface HandleClose {
  handleCloseUpdate: () => void;
  supplierId: number | null;
  refetchSuppliers: () => void;
}

export const UpdateSupplierForm: React.FC<HandleClose> = ({
  handleCloseUpdate,
  supplierId,
  refetchSuppliers,
}): JSX.Element => {
  const { t } = useTranslation(['main']);
  const { data: supplier, refetch: refetchFormData } = useSupplier(supplierId);
  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });
  const [billingAddress, setBillingAddress] = useState<Address>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    supplierName: '',
    supplierAddressStreet: '',
    supplierAddressStreetNumber: '',
    supplierAddressCountry: '',
    supplierAddressCity: '',
    supplierAddressPostCode: '',
    supplierEmail: '',
    supplierNumber: '',
    supplierPhone: '',
    supplierFax: '',
  });

  const validationSchema = yup.object({
    supplierName: yup.string().max(255).required(),
    supplierAddressStreet: yup.string().max(255).required(),
    supplierAddressStreetNumber: yup.string().max(255).required(),
    supplierAddressCountry: yup.string().max(255).required(),
    supplierAddressCity: yup.string().max(255).required(),
    supplierAddressPostCode: yup.string().max(255).required(),
    supplierEmail: yup.string().max(255).required(),
    supplierNumber: yup.string().max(255).required(),
    supplierPhone: yup.string().max(255).required(),
    supplierFax: yup.string().max(255).required(),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setBillingAddress({
      company_name: supplier?.name || '',
      street_name: supplier?.street_name || '',
      street_number: supplier?.street_number || '',
      post_code: supplier?.post_code || '',
      city: supplier?.city || '',
      country: supplier?.country || '',
    });
    setInitialValues({
      supplierName: supplier?.name || '',
      supplierAddressStreet: supplier?.street_name || '',
      supplierAddressStreetNumber: supplier?.street_number || '',
      supplierAddressCountry: supplier?.country || '',
      supplierAddressCity: supplier?.city || '',
      supplierAddressPostCode: supplier?.post_code || '',
      supplierEmail: supplier?.email || '',
      supplierNumber: supplier?.supplier_number || '',
      supplierPhone: supplier?.phone || '',
      supplierFax: supplier?.fax || '',
    });
  }, [supplier]);

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      const newSupplier: NewSupplier = {
        name: data.supplierName,
        supplier_number: data.supplierNumber,
        street_name: data.supplierAddressStreet,
        street_number: data.supplierAddressStreetNumber,
        post_code: data.supplierAddressPostCode,
        city: data.supplierAddressCity,
        country: data.supplierAddressCountry,
        email: data.supplierEmail,
        phone: data.supplierPhone,
        fax: data.supplierFax,
      };
      await ClientService.updateSupplier(newSupplier, supplierId);
      refetchSuppliers();
      refetchFormData();
      setOpenSnackBar({
        openSnackBar: true,
        type: 'success',
        message: t('main:profile.successfulEdit') || '',
      });
      setTimeout(() => {
        handleCloseUpdate();
      }, 1200);
    } catch (error) {
      console.log(error);
      setOpenSnackBar({
        openSnackBar: true,
        type: 'error',
        message: t('main:profile.errorEdit') || '',
      });
    }
  };

  return (
    supplier && (
      <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '2rem',
            paddingBottom: '0',
          }}
        >
          {t('main:suppliers.updateSupplierTitle')}
          <IconButton aria-label='close' onClick={handleCloseUpdate}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async data => {
            await handleSubmit(data);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                paddingX='2rem'
                sx={{
                  gap: {
                    xs: theme.spacing(2),
                  },
                }}
              >
                <Box
                  display='flex'
                  gap={theme.spacing(2)}
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}
                >
                  <InputField
                    name='supplierName'
                    category='suppliers'
                    fullWidth
                  />
                  <InputField
                    name='supplierEmail'
                    category='suppliers'
                    fullWidth
                  />
                </Box>
                <Box
                  display='flex'
                  gap={theme.spacing(2)}
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column-reverse',
                      sm: 'row',
                    },
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                    width='100%'
                  >
                    <AddressFields
                      addressType='supplierAddress'
                      address={billingAddress}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                    width='100%'
                  >
                    <Typography variant='body2' component='p' margin={'1px'}>
                      {t('main:suppliers.otherDetails')}
                    </Typography>
                    <InputField
                      name='supplierNumber'
                      category='suppliers'
                      fullWidth
                    />
                    <InputField
                      name='supplierPhone'
                      category='suppliers'
                      fullWidth
                    />
                    <InputField
                      name='supplierFax'
                      category='suppliers'
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '2rem',
                }}
              >
                <Button
                  type='submit'
                  variant='actionButtonRed'
                  onClick={submitForm}
                  sx={{
                    p: theme.spacing(1.5, 9),
                  }}
                  disabled={isSubmitting}
                >
                  {t('main:suppliers.updateSupplier')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {openSnackBar.openSnackBar && (
          <SnackBar
            type={openSnackBar.type}
            openSnackBar={openSnackBar.openSnackBar}
            handleCloseSnackbar={handleCloseSnackbar}
            message={openSnackBar.message}
          />
        )}
      </Box>
    )
  );
};
