import { ListItemButton, ListItemText, Collapse } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { QuickFilterOption } from '../../types/Filters';
import { FiltersList } from './FiltersList';
import { CheckboxWithLabel } from '../../ui-components/CheckboxWithLabel';

type Props = {
  depth: number;
  option: QuickFilterOption;
  type: string;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ListWithButton: React.FC<Props> = ({
  depth,
  option,
  type,
  handleCheckboxChange,
}: Props) => {
  const [open, setOpen] = useState<boolean>(option?.checked || false);

  return option.options?.length ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        disableGutters
        disableRipple
        sx={{
          ml: depth,
          py: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ListItemText
          primary={option.name}
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'ArialRounded',
              fontSize: '14px',
              textDecoration: 'none',
              color: '#6d7277',
            },
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon
            sx={{ color: theme.palette.primary.lightGrey }}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{ color: theme.palette.primary.lightGrey }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <FiltersList
          options={option.options}
          depth={depth + 1}
          type={type}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Collapse>
    </>
  ) : (
    <ListItemButton
      key={`${option.id}-${option.name}`}
      disableGutters
      disableRipple
      sx={{
        ml: depth,
        py: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <CheckboxWithLabel
        name={`${type}_${option.id}`}
        label={option.name}
        checked={option.checked || false}
        description={option.description}
        onChange={handleCheckboxChange}
      />
    </ListItemButton>
  );
};
