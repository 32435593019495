import { Box } from '@mui/system';
import React from 'react';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      aria-labelledby={`orders-tab-${index}`}
      hidden={value !== index}
      id={`orders-tabpanel-${index}`}
      role="tabpanel"
      style={{ width: '100%' }}
      {...other}
    >
      {value === index &&
      <Box>
        {children}
      </Box>}
    </Box>
  );
};
