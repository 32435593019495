import React, { useEffect, useRef } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { DEFAULT_SNACKBAR_DURATION, SnackbarMessage as SnackbarMessageProps } from '../contexts/ApiContext';

type Props = {
    onClose: (id: string) => void
} & SnackbarMessageProps;

export const SnackbarMessage: React.FC<Props> = ({ onClose, ...props }: Props) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      onClose(props.id);
    }, props.duration ?? DEFAULT_SNACKBAR_DURATION);

    return () => clearTimeout(timerRef.current);
  }, []);

  return(
    <Box
      sx={{
        pointerEvents: 'auto',
      }}
    >
      <Alert
        severity={props.type}
        variant="filled"
        sx={{ maxWidth: '70%', width: 'fit-content', height: 'auto', mx: 'auto' }}
        onClose={() => {
          clearTimeout(timerRef.current);
          onClose(props.id);
        }}
      >
        {props.messages.map((message, index) => (
          <Typography key={index}>
            {message}
          </Typography>
        ))}
      </Alert>
    </Box>
  );
};

