import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Stack, Typography } from '@mui/material';
import { useExternalProductLink } from '../../hooks/useExternalProductLink';
import PdfIcon from '../../assets/pdf-icon.svg';

type ProductDataPropsType = {
  articleNumber: string;
  supplierName: string;
};

export const ExternalProductLink: React.FC<ProductDataPropsType> = ({
  articleNumber,
  supplierName,
}) => {
  const { t } = useTranslation(['main']);
  const externalProductLink = useExternalProductLink(articleNumber, supplierName);

  return (
    <>
      {externalProductLink && (
        <Grid container justifyContent='space-between' alignItems='center'>
          <Link
            href={externalProductLink}
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Stack direction='row' spacing={2}>
              <img src={PdfIcon} alt='icon' />
              <Typography variant='h5' component='h5'>
                {t('main:configurationPage.sheetDownload')}
              </Typography>
            </Stack>
          </Link>
        </Grid>
      )}
    </>
  );
};
