import React from 'react';
import { FormControl, OutlinedInput } from '@mui/material';
import theme from '../theme';


// eslint-disable-next-line @typescript-eslint/ban-types
type StyledInputProps = {
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  value: string;
  type?: string;
  width?: string;
  disabled?: boolean;
}
export const StyledInputMaterialNumber: React.FC<StyledInputProps> = ({ onChange, onBlur, value, type, width, disabled }) => (
  <FormControl fullWidth>
    <OutlinedInput
      size="small"
      value={value}
      type={type}
      disabled={disabled}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur ? e => onBlur(e.target.value): e => onChange(e.target.value)}
      id="outlined-adornment-amount"
      inputProps={{ min: 0 }}
      sx={{ background: theme.custom.tableInputBackground, width: width ? width : 'unset' }}
    />
  </FormControl>
);

StyledInputMaterialNumber.defaultProps = {
  type: 'text',
  width: undefined,
  onBlur: undefined,
};
