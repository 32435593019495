import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { MainFilterOption } from './MainFilters';

type FilterSelectType = {
  name: string;
  value: MainFilterOption | null;
  placeholder: string;
  options: MainFilterOption[];
  onChangeFaceValue?: (value: MainFilterOption | null) => void;
  onChangeGlueValue?: (value: MainFilterOption | null) => void;
  onChangeBackValue?: (value: MainFilterOption | null) => void;
  onChangeSupplierValue?: (value: MainFilterOption | null) => void;
};

export const FilterSelect: React.FC<FilterSelectType> = (
  props: FilterSelectType,
) => {
  const {
    name,
    value,
    placeholder,
    options,
    onChangeFaceValue,
    onChangeGlueValue,
    onChangeBackValue,
    onChangeSupplierValue,
  } = props;
  const filterStyles = {
    fontFamily: 'Poppins',
    fontSize: '12px',
  };

  const handleSelectChange = (
    newValue: SingleValue<MainFilterOption>,
    actionMeta: ActionMeta<MainFilterOption>,
  ) => {
    if (actionMeta.name === 'face' && onChangeFaceValue) {
      onChangeFaceValue(newValue);
    }

    if (actionMeta.name === 'glue' && onChangeGlueValue) {
      onChangeGlueValue(newValue);
    }

    if (actionMeta.name === 'back' && onChangeBackValue) {
      onChangeBackValue(newValue);
    }

    if (actionMeta.name === 'supplier' && onChangeSupplierValue) {
      onChangeSupplierValue(newValue);
    }
  };

  return (
    <Select
      name={name}
      options={options}
      value={value}
      isSearchable
      isClearable
      onChange={handleSelectChange}
      placeholder={placeholder}
      styles={{
        placeholder: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
        }),
        loadingMessage: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
        }),
        noOptionsMessage: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
        }),
        input: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
        }),
        option: baseStyles => ({
          ...baseStyles,
          ...filterStyles,
          cursor: 'pointer',
        }),
        container: baseStyles => ({
          ...baseStyles,
          zIndex: '9',
        }),
      }}
    />
  );
};
