import { List } from '@mui/material';
import React from 'react';
import { QuickFilterOption } from '../../types/Filters';
import { ListWithButton } from './ListWithButton';

type Props = {
  options: QuickFilterOption[];
  type: string;
  depth?: number;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FiltersList: React.FC<Props> = ({
  options,
  depth = 1,
  type,
  handleCheckboxChange,
}) => (
  <List
    disablePadding
    sx={{ width: '100%', maxWidth: { xs: '100%', sm: '260px' } }}
  >
    {options.map(
      (option, index) => (
        <ListWithButton
          key={`${type}-${option.name}-${index}`}
          depth={depth}
          option={option}
          type={type}
          handleCheckboxChange={handleCheckboxChange}
        />
      ),
    )}
  </List>
);
