import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  Box,
  DialogTitle,
  IconButton,
  useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  useUser,
  useAllCompanies,
  useUserRoles,
} from '../../../selectors/selectors';
import ClientService from '../../../services/ClientService';
import {
  DropdownWrapper,
  DropdownOption,
} from '../../../ui-components/DropdownWrapper';
import { InputField } from '../Components/InputField';
import { SnackBar, SnackBarProps } from '../Components/SnackBar';

interface HandleCloseUpdate {
  handleCloseUpdate: () => void;
  userId: number | null;
  refetchTable: () => void;
}

export const UpdateUserForm: React.FC<HandleCloseUpdate> = ({
  handleCloseUpdate,
  userId,
  refetchTable,
}) => {
  const { t } = useTranslation(['main']);

  const isDeviceMD = useMediaQuery(theme.breakpoints.down('md'));

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });

  const { data: companies } = useAllCompanies();
  const { data: roles } = useUserRoles();
  const { data: user, refetch: refetchForm } = useUser(userId);

  const [companiesList, setCompaniesList] = useState<DropdownOption[] | []>([]);
  const [rolesList, setRolesList] = useState<DropdownOption[] | []>([]);
  const [roleId, setRoleId] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');

  const [initialValues, setInitialValues] = useState<FormikValues>({
    id: user?.id,
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhone: '',
    userOrderEmail: '',
    password: '',
    passwordConfomation: '',
    companyId: '',
    roleId: roleId,
  });

  useEffect(() => {
    setInitialValues({
      userFirstName: user?.first_name || '',
      userLastName: user?.last_name || '',
      userEmail: user?.email || '',
      userOrderEmail: user?.order_email || '',
      userPhone: user?.phone || '',
    });
  }, [user]);

  useEffect(() => {
    if (companies && !companiesList.length) {
      setCompaniesList(
        companies.map((company: any) => ({
          name: company.name,
          value: company.id,
        })),
      );
    }
    if (roles?.length && !rolesList.length) {
      setRolesList(
        roles.map((role: any) => ({
          name: role.display_name,
          value: role.id,
        })),
      );
    }

    if (user?.company_id) {
      setCompanyId(user.company_id);
    }
    if (user?.roles[0]?.id) {
      setRoleId(user.roles[0].id);
    }
  }, [companies, roles, user]);

  const validationSchema = yup.object({
    userFirstName: yup.string().max(255).required(),
    userLastName: yup.string().max(255).required(),
    userEmail: yup.string().max(255).required(),
    userPhone: yup.string().max(255).required(),
    userOrderEmail: yup.string().max(255).required(),
    password: yup.string().max(255),
    passwordConfirmation: yup.string().max(255),
  });

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  const handleSubmit = async (data: any) => {
    try {
      const newData: any = {
        company_id: +companyId,
        role_id: +roleId,
        first_name: data.userFirstName,
        last_name: data.userLastName,
        order_email: data.userOrderEmail,
        phone: data.userPhone,
      };
      if (data.userEmail !== user.email) {
        newData.email = data.userEmail;
      }
      if (data.password) {
        newData.password = data.password;
        newData.password_confirmation = data.passwordConfirmation;
      }
      await ClientService.updateUser(newData, userId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    user && (
      <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '2rem',
            paddingBottom: '0',
          }}
        >
          {t('main:users.updateUserTitle')}
          <IconButton aria-label='close' onClick={handleCloseUpdate}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async data => {
            try {
              await handleSubmit(data);
              refetchTable();
              refetchForm();
              setOpenSnackBar({
                openSnackBar: true,
                type: 'success',
                message: t('main:profile.successfulEdit') || '',
              });
              setTimeout(() => {
                handleCloseUpdate();
              }, 1200);
            } catch (error) {
              setOpenSnackBar({
                openSnackBar: true,
                type: 'error',
                message: t('main:profile.errorEdit') || '',
              });
            }
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Box
                display='flex'
                justifyContent='space-between'
                paddingX='2rem'
                gap={theme.spacing(2)}
                sx={{
                  flexDirection: {
                    xs: 'column',
                  },
                }}
              >
                {isDeviceMD && 
                  <Box
                    display='flex'
                    gap={theme.spacing(2)}
                    sx={{
                      flexDirection: {
                        xs: 'column',
                        sm: 'row',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '60%',
                          md: '50%',
                        },
                      }}
                    >
                      {companies && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setCompanyId(value)}
                          initialValue={companyId}
                          label={t('main:companies.name')}
                          name='companyId'
                          data={companiesList}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '40%',
                          md: '50%',
                        },
                      }}
                    >
                      {roles && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setRoleId(value)}
                          initialValue={roleId}
                          label={t('main:users.roleId')}
                          name='roleId'
                          data={rolesList}
                        />
                      )}
                    </Box>
                  </Box>}
                <Box
                  display='flex'
                  flexDirection='row'
                  gap={theme.spacing(2)}
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}
                >
                  <InputField name='userFirstName' category='users' fullWidth />
                  <InputField name='userLastName' category='users' fullWidth />
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  gap={theme.spacing(2)}
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}
                >
                  <InputField name='userEmail' category='users' fullWidth />
                  <InputField
                    name='userOrderEmail'
                    category='users'
                    fullWidth
                  />
                </Box>

                <Box
                  display='flex'
                  gap={theme.spacing(2)}
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column-reverse',
                      sm: 'row',
                    },
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(2)}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '49%',
                      },
                    }}
                  >
                    <Typography variant='body2' component='p' margin={'1px'}>
                      {t('main:users.password')}
                    </Typography>
                    <Box
                      display='flex'
                      flexDirection='column' 
                      gap={theme.spacing(2)}
                    >
                      <InputField
                        name='password'
                        category='users'
                        type='password'
                        fullWidth
                      />
                      <InputField
                        name='passwordConfirmation'
                        category='users'
                        type='password'
                        fullWidth
                      />
                    </Box>
                  </Box>
                  {!isDeviceMD && 
                  <Box
                    display='flex'
                    gap={theme.spacing(2)}
                    sx={{
                      flexDirection: {
                        md: 'column',
                      },
                      width: {
                        md: '50%',
                      },
                    }}
                  >
                    <Box>
                      {companies && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setCompanyId(value)}
                          initialValue={companyId}
                          label={t('main:companies.name')}
                          name='companyId'
                          data={companiesList}
                        />
                      )}
                    </Box>
                    <Box>
                      {roles && (
                        <Field
                          as={DropdownWrapper}
                          onChange={(value: string) => setRoleId(value)}
                          initialValue={roleId}
                          label={t('main:users.roleId')}
                          name='roleId'
                          data={rolesList}
                        />
                      )}
                    </Box>
                  </Box>}
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '2rem',
                }}
              >
                <Button
                  type='submit'
                  variant='actionButtonRed'
                  onClick={submitForm}
                  sx={{
                    p: theme.spacing(1.5, 9),
                  }}
                  disabled={isSubmitting}
                >
                  {t('main:users.updateUser')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {openSnackBar.openSnackBar && (
          <SnackBar
            type={openSnackBar.type}
            openSnackBar={openSnackBar.openSnackBar}
            handleCloseSnackbar={handleCloseSnackbar}
            message={openSnackBar.message}
          />
        )}
      </Box>
    )
  );
};
