/* eslint-disable indent */
import { useAuth } from '../contexts/AuthContext';

export const useExternalProductLink = (productEAN: string | undefined, supplierName: string | undefined) => {
  const { lang } = useAuth();

  switch (supplierName) {
    case 'Fedrigoni': return productEAN ? `https://myselfadhesives.fedrigoni.com/${lang}_${lang}/${productEAN}` : null;
      break;
    case 'UPM Raflatac Oy': return productEAN ? `https://tools.upmraflatac.com/PRT/s/ProductSelector?language=${lang}#searchText=${productEAN}&countryCode=D&continent=EMEA&palNumber=20797&prodType=R` : null;
  }
};
