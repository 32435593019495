import React from 'react';
import { Paper, Box } from '@mui/material';
import theme from '../../../theme';
import { SupplierForm } from '../Components/SupplierForm';

type Props = {
  companyId: number;
  paperElevation: number;
};

export const SupplierInfo: React.FC<Props> = ({
  companyId,
  paperElevation,
}) => (
  <Paper elevation={paperElevation}>
    <Box
      display='flex'
      flexDirection='column'
      padding='2rem'
      gap={theme.spacing(4)}
    >
      <SupplierForm companyId={companyId} />
    </Box>
  </Paper>
);
