import React from 'react';
import { Box, Typography } from '@mui/material';
import { TabPanel } from './TabPanel';
import theme from '../../theme';
import { StyledTab } from './StyledTab';
import { StyledTabs } from './StyledTabs';
import { useTranslation } from 'react-i18next';
import { OrdersTabPanel } from './OrdersTabPanel';

const tabs = [
  'main:orders.newOrdersTabTitle',
  'main:orders.awaitingOrdersTabTitle',
  'main:orders.deliveredOrdersTabTitle',
  'main:orders.canceledOrdersTabTitle',
  'main:orders.rejectedOrdersTabTitle',
  'main:orders.changedOrdersTabTitle',
];
const panels = ['new', 'awaiting', 'delivered', 'canceled', 'rejected', 'changed'];

export const Orders: React.FC = () => {
  const { t } = useTranslation(['main']);

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={theme.custom.maxPageContentWidth}
        mx="auto"
        justifyContent="center"
        gap={theme.spacing(5)}
        p={theme.spacing(5, 0)}
        sx={{
          paddingX: {
            xs: '10px',
          },
        }}
      >
        <Typography variant="pageTitle" component='h1'>
          {t('main:orders.title')}
        </Typography>
        <StyledTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="order tabs"
          allowScrollButtonsMobile
        >
          {tabs.map( (tabTitle, index) => (
            <StyledTab key={`tabs_${index}`} disableRipple={false} label={t(`${tabTitle}`)} {...a11yProps(index)} />
          ))}
        </StyledTabs>
        {panels.map( (panelType, index) => (
          <TabPanel key={`panels_${index}`} index={index} value={value}>
            <OrdersTabPanel ordersType={panelType} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};


