import { AxiosError } from 'axios';
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import LabelAPI from '../labelAPI';
import { getCookie, setCookie } from '../utils/genericFunctions';
import {
  LabelApiErrorResponse,
  parseAxiosError,
} from '../utils/labelAPIFunctions';

export const DEFAULT_SNACKBAR_DURATION = 5000;
export const DEFAULT_LOCALE = 'en'; // possible values are en and de

export type SnackbarMessage = {
  id: string;
  messages: string[];
  type: 'error' | 'success' | 'info';
  duration?: number;
};

type ApiContext = {
  snackbarMessages: SnackbarMessage[];
  setSnackbarMessages: React.Dispatch<React.SetStateAction<SnackbarMessage[]>>;
  createMessage: (
    messages: string[],
    type: SnackbarMessage['type'],
    duration?: number
  ) => void;
};

export const ApiContext = createContext<ApiContext>({} as ApiContext);

type Props = {
  children: React.ReactNode;
};

/**
 *
 * This Context is strictly for anything to do with the api communication
 * - axios interceptors logic
 * - cookies
 * - handling responses
 *
 */

export const ApiProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const [snackbarMessages, setSnackbarMessages] = useState<SnackbarMessage[]>(
    [],
  );

  const createMessage = useCallback(
    (
      messages: string[],
      type: SnackbarMessage['type'],
      duration = DEFAULT_SNACKBAR_DURATION,
    ) => {
      const msg: SnackbarMessage = {
        id: v4(),
        messages,
        type,
        duration,
      };
      setSnackbarMessages(m => [...m, msg]);
    },
    [setSnackbarMessages],
  );

  // set default locale cookie on load of the app
  useEffect(() => {
    const locale = getCookie('locale');
    if (!locale) {
      setCookie('locale', DEFAULT_LOCALE, 3600 * 24); // set a locale cookie for a day
    } else {
      i18n.changeLanguage(locale);
    }
  }, []);

  useEffect(() => {
    const interceptor = LabelAPI.interceptors.response.use(
      response => {
        const { config } = response;

        // need to stringify the response.data
        let snackMessages: string[] | null = null;
        let type: SnackbarMessage['type'] = 'success';
        let duration = config.messageDuration ?? DEFAULT_SNACKBAR_DURATION;

        if (
          config.messageDisplayedOn &&
          config.messageDisplayedOn.includes(response.status)
        ) {
          snackMessages = [JSON.stringify(response.data)];
        }

        if (config.messageOverride) {
          const override = config.messageOverride.find(
            msg => msg.code === response.status,
          );
          if (override) {
            snackMessages = [override.message];
            type = override.type ?? 'success';
            duration = override.duration ?? DEFAULT_SNACKBAR_DURATION;
          }
        }

        snackMessages && createMessage(snackMessages, type, duration);
        return response;
      },

      async (error: AxiosError<LabelApiErrorResponse>) => {
        const { config } = error;

        let snackMessages: string[] | null = null;
        let type: SnackbarMessage['type'] = 'error';
        let duration =
          (config ? config.messageDuration : undefined) ??
          DEFAULT_SNACKBAR_DURATION;

        if (
          config &&
          error.response &&
          config.messageDisplayedOn &&
          config.messageDisplayedOn.includes(error.response.status)
        ) {
          snackMessages = parseAxiosError(error);
        }

        if (config && config.messageOverride) {
          const override = config.messageOverride.find(
            msg => msg.code === error.status,
          );
          if (override) {
            snackMessages = [override.message];
            type = override.type ?? 'error';
            duration = override.duration ?? DEFAULT_SNACKBAR_DURATION;
          }
        }

        snackMessages && createMessage(snackMessages, type, duration);
        return Promise.reject(error);
      },
    );

    return () => {
      LabelAPI.interceptors.response.eject(interceptor);
    };
  }, [setSnackbarMessages]);

  return (
    <ApiContext.Provider
      value={{
        snackbarMessages,
        setSnackbarMessages,
        createMessage,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
