import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from '@mui/material';
import { StyledInputWithoutLabel } from '../../ui-components/StyledInputWithoutLabel';
import { StyledTableCell } from '../../ui-components/StyledTableSell';

type OffcutProps = {
  offcut: number;
  rollHeight: number;
  totalSQM: number;
  productPrice: number;
  numberOfRolls: number;
}


export const OffcutArticle: React.FC<OffcutProps> = ({
  offcut,
  rollHeight,
  totalSQM,
  productPrice,
  numberOfRolls,
}) => {

  const { t } = useTranslation(['main']);
  const total = (totalSQM * numberOfRolls);

  return (
    <TableRow>
      <StyledTableCell component="th" scope="row" align='center' sx={{ paddingLeft: '4rem', paddingRight: '0px' }} >
        <StyledInputWithoutLabel
          width='100px'
          type={'number'}
          value={`${offcut}`}
          onChange={() => 'change'}
          disabled={true}
        />
      </StyledTableCell>
      <StyledTableCell component="th" align='center'sx={{ paddingLeft: '0px', paddingRight: '0px' }} >
        <StyledInputWithoutLabel
          width='100px'
          type={'number'}
          value={offcut && `${numberOfRolls}`}
          onChange={() => 'change'}
          disabled={true}
        />
      </StyledTableCell>
      <StyledTableCell
        align='center'
        width='100px'
        sx={{
          padding: '8px',
          fontFamily: 'Heebo',
          fontSize: '12px',
          fontWeight: '800',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          color: '#454f5b',
        }}
      >
        {`${rollHeight.toLocaleString('de-DE')} ${t('main:configurationPage.m')}`}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        width="100px"
        sx={{
          padding: '8px',
          fontFamily: 'ArialRounded',
          fontSize: '14px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '2.57',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#212b36',
        }}
      >
        {`${ offcut && total ? ((total - (total - offcut)) * productPrice).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : 0}`}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        width="100px"
        sx={{
          padding: '8px',
          fontFamily: 'Heebo',
          fontSize: '12px',
          fontWeight: '800',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#454f5b',
        }}
      >
        {`${(total - (total - offcut)).toLocaleString('de-DE')} ${t('main:configurationPage.qm')}`}
      </StyledTableCell>
    </TableRow>
  );
};
