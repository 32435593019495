import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Typography, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { DescriptionModal } from './DescriptionModal';
import { useAuth } from '../contexts/AuthContext';

type Props = {
  name: string;
  label: string;
  description?: string;
  checked: boolean;
  onChange: (b: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxWithLabel: React.FC<Props> = props => {
  const { lang } = useAuth();
  const [openDescription, setOpenDescription] = useState<boolean>(false);

  const handleClickInfo = () => {
    setOpenDescription(true);
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={props.name}
            onChange={e => props.onChange(e)}
            checked={props.checked}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 18 },
              pt: 0,
              pb: 0,
            }}
          />
        }
        label={
          <Grid container>
            <Typography
              variant='checkbox'
              textTransform={lang === 'en' ? 'capitalize' : 'unset'}
            >
              {props.label}
            </Typography>
            {props.description && props.name && (
              <IconButton onClick={handleClickInfo}>
                <InfoIcon />
              </IconButton>
            )}
          </Grid>
        }
      />
      <DescriptionModal
        openDescription={openDescription}
        title={props.label}
        description={props.description}
        handleClose={handleCloseDescription}
      />
    </>
  );
};
