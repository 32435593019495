import { Box, Collapse, Dialog, DialogActions, DialogTitle, Grid, IconButton, TableCell, TableRow, TextField, Typography, Tooltip, Checkbox } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '../../ui-components/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../../theme';
import { ProductCalculator } from '../ProductConfigurationPage/ProductCalculator';
import { ProductService } from '../../types/Product';
import { useTranslation } from 'react-i18next';
import { ProductInfoBlock } from '../../ui-components/ProductInfoBlock';
import ProductTableRow from '../ProductConfigurationPage/ProductTableRow';
import { SaveData, Totals } from '../../pages/ProductConfigurationPage/interfaces';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from 'dayjs';
import ClientService from '../../services/ClientService';
import { addOffcut, multiplyRows } from '../../utils/setsFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getCookie } from '../../utils/genericFunctions';
import { useCompany } from '../../selectors/selectors';
import { User } from '../../types/User';
import { Address } from '../../types/Address';
import { SingleOrder } from '../../types/Orders';
import { FormikValues } from 'formik';
import { countryOptions } from '../../utils/constants';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { EditDeliveryAddressForm } from './components/EditDeliveryAddressForm';

type OrdersRowProps = {
  row: SingleOrder,
  hideAddRowButton: boolean,
  hideSaveButton: boolean,
  hideCancelButton: boolean,
  disabledInputs: boolean,
  onCancelArticle: (value: number) => void;
  refetchOrdersData: () => void;
  rowsWithCheck: number[];
  setRowsWithCheck: Dispatch<SetStateAction<number[]>>;
  user: User;
  ordersType: string;
};

const minDate = dayjs().add(3, 'd');

const OrdersRow = ({ row, hideAddRowButton, hideSaveButton, hideCancelButton, disabledInputs, onCancelArticle, refetchOrdersData, rowsWithCheck, setRowsWithCheck, user, ordersType }: OrdersRowProps) => {
  const { t } = useTranslation(['main']);
  const { data: companyData, refetch: refetchCompany } = useCompany(user.data.userInfo.company_id);
  const locale = getCookie('locale') === 'en' ? 'en-gb': 'de';

  const [open, setOpen] = useState(false);
  const [totals, setTotals] = useState<Totals>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [priceChanged, setPriceChanged] = useState<boolean>(false);
  const [palService, setPalService] = useState<ProductService>();
  const [productTableRows, setProductTableRows] = useState<ProductTableRow[]>([]);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [offcut, setOffcut] = useState<number>();
  const [errorMessagesRows, setErrorMessagesRows] = useState<string[][] | string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openEditAddressDialog, setOpenEditAddressDialog] = useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<Dayjs>(dayjs(row.delivery_date));
  const [deliveryAddresses, setDeliveryAddresses] = useState<Address[]>();
  const [deliveryAddress, setDeliveryAddress] = useState<Address>();
  const [initialValues, setInitialValues] = useState<FormikValues>({
    id: '',
    deliveryAddressStreet: '',
    deliveryAddressStreetNumber: '',
    deliveryAddressCity: '',
    deliveryAddressPostCode: '',
    deliveryAddressCountry: countryOptions[0].value,
  });

  const red = {
    color: theme.palette.primary.red,
  };

  const strikeOut = {
    textDecoration: 'line-through',
  };

  useEffect(() => {
    if(companyData?.addresses.length > 0) {
      setDeliveryAddresses(companyData?.addresses);
    }
  }, [companyData]);

  useEffect(() => {
    if(row.delivery){
      setDeliveryAddress(row.delivery);
    } else if (deliveryAddresses && deliveryAddresses.length !== 0) {
      setDeliveryAddress(deliveryAddresses?.find((address: Address) => address.type_id === 1));
    }
  }, [deliveryAddresses, row]);

  useEffect(() => {
    if(deliveryAddress) {
      if(deliveryAddress.id) {
        setInitialValues({
          id: deliveryAddress.id,
          deliveryAddressStreet: deliveryAddress.street_name,
          deliveryAddressStreetNumber: deliveryAddress.street_number,
          deliveryAddressCity: deliveryAddress.city,
          deliveryAddressPostCode: deliveryAddress.post_code,
          deliveryAddressCountry: deliveryAddress.country,
          type_id: 2,
        });
      } else {
        setInitialValues({
          deliveryAddressStreet: deliveryAddress.street_name,
          deliveryAddressStreetNumber: deliveryAddress.street_number,
          deliveryAddressCity: deliveryAddress.city,
          deliveryAddressPostCode: deliveryAddress.post_code,
          deliveryAddressCountry: deliveryAddress.country,
          type_id: 2,
        });
      }
    }
  }, [deliveryAddress]);

  useEffect(() => {
    if(row.product) {
      const currentPalService = row.product.services.find( (service: ProductService) => service.id === row.service_id);
      setPalService(currentPalService);
    }

    if (!productTableRows.length) {
      setProductTableRows(row.items.map(
        (item: any) => new ProductTableRow(item.id, item.material_number, item.width, item.rolls, item.meters, item.total),
      ));
    }
    if(row?.order_update_product?.is_valid === 0 || row?.order_update_product?.price_changed === 1) {
      if(!rowsWithCheck.includes(row.id)) {
        setRowsWithCheck(prevState => [...prevState, row.id]);
      }
    }
    if(row?.order_update_product?.is_valid === 0) {
      setIsValid(false);
    }
    if(row?.order_update_product?.price_changed === 1) {
      setPriceChanged(true);
    }
    setDeliveryDate(dayjs(row.delivery_date));
    setOrderId(row.id);
  }, [row]);

  const updateRowInCalculator = (rows: ProductTableRow[]) => {

    const newRows = multiplyRows(rows);

    let usableWidth = 0;
    if (row.product) {
      usableWidth = row.product.services[0].usable_width;
    }

    const rollsWithOffcut = addOffcut(newRows, usableWidth);

    const offcut = rollsWithOffcut
      .map(roll => roll.rows
        .filter(row => row.materialNumber === 'offcut')
        .map(row => row.rollWidth))
      .flat()
      .reduce((acc, cur) => acc + cur, 0);

    setOffcut(offcut);
    handleCalculate(rows, offcut);
  };

  const handleCalculate = async ( rows: ProductTableRow[], offcut: number ) => {

    const cutting: any[] = [];
    rows.map(row => {
      if (row?.rollCount && row?.rollWidth && row?.rollHeight) {
        cutting.push({
          width: row?.rollWidth,
          rolls: row?.rollCount,
          meters: row?.rollHeight,
        });
      }
    });

    if(cutting.length > 0) {
      const data: SaveData = palService?.product_service_group.name !== 'MEGA' ? ({
        product_id: row?.product?.id,
        service_id: palService?.id,
        supplier_id: row?.product?.supplier?.id,
        material_number: '',
        delivery_date: dayjs(row.delivery_date)?.format('YYYY-MM-DD'),
        sets: [
          {
            id: 1,
            offcut: offcut,
            cutting: cutting,
          },
        ],
      }) : ({
        product_id: row?.product?.id,
        service_id: palService?.id,
        supplier_id: row?.product?.supplier?.id,
        material_number: '',
        delivery_date: dayjs(row.delivery_date)?.format('YYYY-MM-DD'),
        sets: [
          {
            id: 1,
            cutting: cutting,
          },
        ],
      });

      try {
        const response = await ClientService.calculateCutting(data);
        setTotals(response.data[0].totals);
        setErrorMessagesRows('');
      } catch (error: any){
        console.log('error', error?.response?.data?.errors?.sets[0]?.cutting?.rows);
        setErrorMessagesRows(error?.response?.data?.errors?.sets[0]?.cutting?.rows);
      }
    }
  };

  const deleteOrder = async () => {
    if(orderId) {
      handleCloseDelete();
      await ClientService.deleteOrder(orderId);
      refetchOrdersData();
    }
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseEditAddress = () => {
    setOpenEditAddressDialog(false);
    if(row.delivery) {
      setDeliveryAddress(row.delivery);
    }
  };

  const handleCheck = () => {
    if(rowsWithCheck.includes(row.id)) {
      setRowsWithCheck(rowsWithCheck.filter((id: number) => id !== row.id));
    } else {
      setRowsWithCheck([...rowsWithCheck, row.id]);
    }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left" >
          <Checkbox checked={rowsWithCheck.includes(row.id)} onChange={handleCheck} disabled={!isValid || priceChanged} />
        </TableCell>
        <TableCell component="th" scope="row" width='30%' >
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {row.product_name}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {dayjs(row.updated_at).format('DD.MM.YYYY')}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {`${row.total_sqm_all.toLocaleString('de-DE')} ${t('main:configurationPage.qm')}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {`${row.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 4 })}/${t('main:configurationPage.qm')}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {row.order_number}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }} style={isValid ? priceChanged ? { ...red } : {} : { ...strikeOut, ...red }} >
            {row.user}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }} >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.order_product && <ProductInfoBlock label={t('main:labelDetails.ean')} value={row.order_product.article_number} />}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }} >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.material_number && <ProductInfoBlock label={t('main:labelDetails.orderNumber')} value={row.material_number} />}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {deliveryDate &&
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DatePicker
                  label={t('main:configurationPage.deliveryDate')}
                  value={deliveryDate}
                  onChange={newValue => {
                    setDeliveryDate(newValue as Dayjs);
                  }}
                  disabled={!isValid}
                  disablePast
                  minDate={minDate}
                  renderInput={params => <TextField {...params} sx={{ marginTop: '10px', width: '10rem', '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main } }} />}
                />
              </LocalizationProvider>}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.order_product && <ProductInfoBlock label={t('main:labelDetails.productCode')} value={row.order_product.category} />}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.order_product && <ProductInfoBlock label={t('main:orders.labelStock')} value={row.order_product.face} />}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.order_product &&<ProductInfoBlock label={t('main:orders.labelAdhesive')} value={row.order_product.glue} />}
          </Collapse>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.order_product &&<ProductInfoBlock label={t('main:orders.labelBack')} value={row.order_product.back} />}
          </Collapse>
        </TableCell>
        <TableCell sx={{ borderBottom: 'unset', py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RestrictedComponent permission='create_orders'>
              <Tooltip title={t('main:orders.deleteOrder')} placement='top'>
                <IconButton onClick={() => setOpenDeleteDialog(true)} sx={{ color: theme.palette.primary.red }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </RestrictedComponent>
          </Collapse>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RestrictedComponent permission='create_orders'>
              <Tooltip title={t('main:orders.editAddress')} placement='top'>
                <IconButton onClick={() => setOpenEditAddressDialog(true)} sx={{ color: theme.palette.primary.main }} disabled={!isValid}>
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
            </RestrictedComponent>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow style={{ position: 'relative' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Typography
              sx={{
                zIndex: 1,
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'rotate(-25deg) translate(-50%, -50%)',
                color: '#FFbaba',
                textAlign: 'center',
                fontSize: '36px',
              }}
            >
              {priceChanged ? t('main:orders.priceChanged') : isValid ? '' : t('main:orders.orderInvalid')}
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {errorMessagesRows && typeof errorMessagesRows !== 'string' &&
                <Grid item display='flex' flexDirection='column' alignItems='flex-end' justifyContent='flex-end'>
                  {errorMessagesRows.map((messages: string[], index: number) =>
                    <Typography key={index} variant='body3Dark' sx={{ color: theme.palette.primary.red }}>
                      {`Row №${index + 1} - invalid data`}
                    </Typography>)}
                </Grid>}
              <ProductCalculator
                row={row}
                product={row.product}
                palService={palService}
                deliveryDate={dayjs(deliveryDate)}
                productTableRowsOrder={productTableRows}
                buttonText={t('main:configurationPage.saveBtnText')}
                hideSaveButton={hideSaveButton || !isValid || priceChanged}
                hideCancelButton={hideCancelButton}
                hideAddRowButton={hideAddRowButton}
                disabledInputs={disabledInputs || !isValid}
                orderId={orderId}
                isInOrders={true}
                onCancelArticle={onCancelArticle}
                updateRowInOrder={updateRowInCalculator}
                totalsFromOrdersRow={totals}
                offcutForUpdate={offcut}
                refetchOrdersData={refetchOrdersData}
                ordersType={ordersType}
                priceChanged={priceChanged}
                setPriceChanged={setPriceChanged}
                setRowsWithCheck={setRowsWithCheck}
                rowsWithCheck={rowsWithCheck}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={openEditAddressDialog} onClose={handleCloseEditAddress}>
        {initialValues && (
          <EditDeliveryAddressForm
            addressType={'deliveryAddress'}
            address={deliveryAddress}
            setDeliveryAddress={setDeliveryAddress}
            disabled={false}
            handleClose={handleCloseEditAddress}
            initialValues={initialValues}
            companyData={companyData}
            refetchCompany={refetchCompany}
            refetchOrdersData={refetchOrdersData}
            orderId={row.id}
            deliveryAddresses={deliveryAddresses}
          />
        )}
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleCloseDelete}>
        <DialogTitle id='alert-dialog-title'>
          {t('main:orders.deleteAlert')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} sx={{ color: 'black' }}>
            No
          </Button>
          <Button
            onClick={deleteOrder}
            autoFocus
            sx={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrdersRow;
