import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogActions,
  TablePagination,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UpdateIcon from '@mui/icons-material/Update';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import BlockSharpIcon from '@mui/icons-material/BlockSharp';
import theme from '../../../theme';
import { StyledCell } from '../StyledCell';
import { TableHeadRow } from '../TableHeadRow';
import { NewUserForm } from './NewUserForm';
import { UpdateUserForm } from './UpdateUserForm';
import { UserDetails } from '../../../types/User';
import ClientService from '../../../services/ClientService';
import { useUsers } from '../../../selectors/selectors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.lightGray,
    color: theme.palette.primary.darkGrey,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.custom.whiteBackground,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const UsersTable: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [page, setPage] = useState(1);
  const { data: users, refetch: refetchTable } = useUsers(page);

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const heads = [
    t('main:users.id'),
    t('main:users.userEmail'),
    t('main:users.userFirstName'),
    t('main:users.userLastName'),
    t('main:users.userPhone'),
    t('main:users.actions'),
  ];

  const handleClickOpenNewUser = () => {
    setOpenNew(true);
  };

  const handleCloseNewUser = () => {
    setOpenNew(false);
  };

  const handleClickOpenUpdate = (id: number) => {
    setOpenUpdate(true);
    setCurrentUserId(id);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setCurrentUserId(null);
  };

  const handleOpenDelete = (id: number) => {
    setOpenDelete(true);
    setCurrentUserId(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (id: number | null) => {
    if (id) {
      await ClientService.deleteUser(id);
    }
    refetchTable();
    setOpenDelete(false);
  };

  const handleOpenBlock = (id: number) => {
    setOpenBlock(true);
    setCurrentUserId(id);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
    setCurrentUserId(null);
  };

  const handleBlock = (id: number | null) => {
    console.log(id);
    setOpenBlock(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '2rem',
        }}
      >
        <Button variant='actionButtonRed' onClick={handleClickOpenNewUser}>
          {t('main:users.newUser')}
        </Button>
      </Box>
      <Paper elevation={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <TableHeadRow heads={heads} />
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.data.map((user: UserDetails) => (
                  <StyledTableRow key={user.email}>
                    <StyledCell text={user.id} />
                    <StyledCell text={user.email} />
                    <StyledCell text={user.first_name} />
                    <StyledCell text={user.last_name} />
                    <StyledCell text={user.phone} />
                    <StyledTableCell align='center'>
                      <Box>
                        <UpdateIcon
                          onClick={() => handleClickOpenUpdate(user.id)}
                        />
                        <ClearSharpIcon
                          sx={{ color: 'red' }}
                          onClick={() => handleOpenDelete(user.id)}
                        />
                        <BlockSharpIcon
                          onClick={() => handleOpenBlock(user.id)}
                        />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {users && (
          <TablePagination
            component='div'
            rowsPerPageOptions={[]}
            count={users.total}
            rowsPerPage={15}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        )}
      </Paper>
      <Dialog
        open={openNew}
        onClose={handleCloseNewUser}
        maxWidth='md'
        fullScreen={fullScreen}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        <NewUserForm
          handleClose={handleCloseNewUser}
          refetchTable={refetchTable}
        />
      </Dialog>
      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        maxWidth='md'
        fullScreen={fullScreen}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        <UpdateUserForm
          handleCloseUpdate={handleCloseUpdate}
          userId={currentUserId}
          refetchTable={refetchTable}
        />
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle id='alert-dialog-title'>
          {t('main:users.confirmDelete')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} sx={{ color: 'black' }}>
            No
          </Button>
          <Button
            onClick={() => handleDelete(currentUserId)}
            autoFocus
            sx={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openBlock} onClose={handleCloseBlock}>
        <DialogTitle id='alert-dialog-title'>
          {t('main:users.confirmBlock')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseBlock} sx={{ color: 'black' }}>
            No
          </Button>
          <Button
            onClick={() => handleBlock(currentUserId)}
            autoFocus
            sx={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
