import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import labelImage from '../../assets/etiketten.svg';
import { Product } from '../../types/Product';
import { ReactComponent as Checkmark } from '../../assets/checkmark.svg';
import { ReactComponent as Munus } from '../../assets/minus.svg';
import { useAuth } from '../../contexts/AuthContext';
import { ProductData } from './ProductData';
import { ExternalProductLink } from './ExternalProductLink';


type ProductInfoProps = {
  product: Product | undefined
}
export const ProductInfo: React.FC<ProductInfoProps> = ({ product }) => {
  const { t } = useTranslation(['main']);
  const { userLevel } = useAuth();
  const userIsLevel4 = userLevel === 'level_4';
  const internalID = () => {
    let value = '';
    const face = product?.face_group?.internal_id;
    const glue = product?.glue_group?.internal_id;
    const back = product?.back_group?.internal_id;

    if (face) {
      value = value.concat(face);
    } else {
      value = value.concat(' - ');
    }
    if (glue) {
      value = value.concat('/', glue);
    } else {
      value = value.concat('/ - ');
    }
    if (back) {
      value = value.concat('/', back);
    } else {
      value = value.concat('/ - /');
    }

    return value;
  };

  return (

    <Grid container alignItems="top">
      <Grid item width="180px" sm={3}>
        <img
          src={labelImage}
          alt={'Label Icon'}
          width="180px"
          height="180px"
        />
      </Grid>
      <Grid container item sm direction="column">
        <Typography component="h5" variant="h5" mt='15px'>
          {product?.name.replaceAll('/', ' | ')}
        </Typography>
        <Grid container>
          <Stack pt="20px">
            <Typography variant='body3Light'>
              {t('main:labelDetails.ean')}
            </Typography>
            <Typography variant='body3Dark'>
              {product?.article_number}
            </Typography>
          </Stack>
          {userIsLevel4 &&
            <Typography variant='body3Dark' ml={'50px'} pt="20px" display={'flex'} alignItems={'center'} justifyContent={'center'}>
              {internalID()}
            </Typography>}
        </Grid>

        <Grid container item pt="20px" direction="row" gap={3}>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.usableWidth')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              {`${product?.services[0]?.usable_width.toLocaleString('de-DE')} mm`}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.minOrderLength')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              1.000 m
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.netWidth')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              {product?.services[0]?.net_wid_service ? <Checkmark /> : <Munus />}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.minimumAmount')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              {`${product?.services[0]?.min_order.toLocaleString('de-DE')} qm`}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.inStock')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              {product?.is_stock ? <Checkmark /> : <Munus />}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='body4Light'>
              {t('main:labelDetails.deploymentTime')}
            </Typography>
            <Typography variant='body4Dark' textAlign='center'>
              72h
            </Typography>
          </Stack>
          <Grid
            item
            xs={10}
            sx={{
              marginTop: {
                xs: '10px',
              },
            }}
          >
            {product && <ExternalProductLink articleNumber={product?.article_number} supplierName={product?.supplier?.name} />}
            {product && <ProductData face={product.face} glue={product.glue} back={product.back} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


