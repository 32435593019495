export const QUERY_PRODUCT_INFO = 'product_info';
export const STORAGE_TOKEN = 'authToken';
export const ORDERS = 'orders';
export const USERS = 'users';
export const USER = 'user';
export const ROLES = 'roles';
export const COMPANIES = 'companies';
export const ALL_COMPANIES = 'all_companies';
export const COMPANY = 'company';
export const SUPPLIERS = 'suppliers';
export const ALL_SUPPLIERS = 'all_suppliers';
export const SUPPLIER = 'supplier';
export const TOTAL_QM = 'total_qm';
export const ORDERS_TYPE_NEW = 'new';
export const ORDERS_TYPE_AWAITING = 'awaiting';
export const ORDERS_TYPE_DELIVERED = 'delivered';
export const ORDERS_TYPE_CANCELED = 'canceled';
export const countryOptions = [
  { label: 'Germany', value: 'Germany' },
  { label: 'Austria', value: 'Austria' },
];
