import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, InputLabel, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import { InputField } from '../../Profile/Components/InputField';
import { Address } from '../../../types/Address';
import { FormikReactSelect } from '../../Profile/Components/FormikReactSelect';
import { countryOptions } from '../../../utils/constants';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../../ui-components/Button';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { Company } from '../../../types/Company';
import ClientService from '../../../services/ClientService';
import AddIcon from '@mui/icons-material/Add';

type AddressFieldsProps = {
  addressType: string;
  address?: Address;
  setDeliveryAddress?: Dispatch<SetStateAction<Address | undefined>>;
  disabled?: boolean;
  handleClose: () => void;
  initialValues: FormikValues;
  companyData: Company;
  refetchCompany: () => void;
  refetchOrdersData: () => void;
  orderId: number;
  deliveryAddresses?: Address[]
};

export const EditDeliveryAddressForm: React.FC<AddressFieldsProps> = ({
  addressType,
  address,
  setDeliveryAddress,
  disabled,
  handleClose,
  initialValues,
  companyData,
  refetchCompany,
  refetchOrdersData,
  orderId,
  deliveryAddresses,
}) => {
  const { t } = useTranslation(['main']);
  const isDeviceMD = useMediaQuery(theme.breakpoints.down('md'));
  const [showSelect, setShowSelect] = useState<boolean>(true);
  const validationSchema = yup.object({
    deliveryAddressStreet: yup.string().max(255).required('This field is required'),
    deliveryAddressStreetNumber: yup.string().max(255).required('This field is required'),
    deliveryAddressPostCode: yup.string().max(255).required('This field is required'),
    deliveryAddressCity: yup.string().max(255).required('This field is required'),
    deliveryAddressCountry: yup.string().max(255).required('This field is required'),
  });

  const changeOption = (index: number) => {
    let address;
    if(deliveryAddresses) {
      address = deliveryAddresses[index];
    }
    if(address && setDeliveryAddress) {
      setDeliveryAddress(address);
    }
  };

  const addNewAddress = () => {
    setShowSelect(false);
    if(setDeliveryAddress) {
      setDeliveryAddress({
        street_name: '',
        street_number: '',
        city: '',
        post_code: '',
        country: countryOptions[0].value,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async data => {
        try {
          if(data.id) {
            const res = await ClientService.sendNewAddressId(orderId, data.id);
            if(res) {
              refetchOrdersData();
              refetchCompany();
              handleClose();
            }
          } else {
            const submitDeliveryAddress = {
              company_id: companyData.id,
              company_name: companyData.name,
              street_name: data.deliveryAddressStreet,
              street_number: data.deliveryAddressStreetNumber,
              city: data.deliveryAddressCity,
              post_code: data.deliveryAddressPostCode,
              country: data.deliveryAddressCountry,
              type_id: 2,
            };
            const response = await ClientService.createDeliveryAddress(submitDeliveryAddress);
            if(response) {
              const res = await ClientService.sendNewAddressId(orderId, response.id);
              if(res) {
                refetchOrdersData();
                refetchCompany();
                handleClose();
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            paddingX='2rem'
            paddingY='1rem'
            gap={theme.spacing(4)}
          >
            <Box display='flex' flexDirection='column' gap={theme.spacing(3)}>
              <Box>
                <Typography variant='body2' component='p'>
                  {t<string>(`main:profile.${addressType}Title`)}
                </Typography>
                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {showSelect &&
                <Box>
                  <InputLabel id="test-select-label">
                    {t('orders.selectLabel')}
                  </InputLabel>
                  <Select
                    onChange={e => changeOption(e.target.value as number)}
                    sx={{ width: '100%' }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    name={'seelctAddress'}
                  >
                    {deliveryAddresses && deliveryAddresses.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={index}
                        sx={{
                          minHeight: 'auto',
                        }}
                      >
                        {`${option.street_name} №${option.street_number}, ${option.city} ${option.post_code}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>}
              <Box
                display='flex'
                justifyContent='space-between'
                gap={theme.spacing(2)}
                sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
              >
                <InputField
                  name={`${addressType}Street`}
                  fullWidth
                  disabled={disabled}
                />
                <InputField
                  name={`${addressType}StreetNumber`}
                  disabled={disabled}
                  fullWidth={isDeviceMD}
                />
              </Box>
              <Box display='flex' justifyContent='space-between' gap={theme.spacing(2)} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                <Box>
                  {address && (
                    <FormikReactSelect
                      name={`${addressType}Country`}
                      options={countryOptions}
                      initialValue={address.country}
                      isDisabled={disabled}
                      fullWidth={isDeviceMD}
                    />
                  )}
                </Box>
                <Box display='flex' gap={theme.spacing(2)} width={'100%'} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                  <InputField
                    name={`${addressType}City`}
                    disabled={disabled}
                    fullWidth
                  />
                  <InputField
                    name={`${addressType}PostCode`}
                    disabled={disabled}
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
            {showSelect &&
              <Box>
                <Button
                  variant='actionButtonNoBackground'
                  onClick={addNewAddress}
                  sx={{
                    p: theme.spacing(1.5, 1),
                  }}
                >
                  {<AddIcon />}
                  {t('main:profile.addDeliveryAlternativeAddress')}
                </Button>
              </Box>}
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='actionButtonGreen'
                disabled={false}
                onClick={submitForm}
                isLoading={isSubmitting}
                sx={{
                  p: theme.spacing(1.5, 9),
                }}
              >
                {t('main:profile.submitButton')}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
