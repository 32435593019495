import { Box, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlternativeAddresses } from '../Components/AlternativeAddresses';
import { CompanyAddresses } from '../Components/CompanyAddresses';
import CloseIcon from '@mui/icons-material/Close';

interface HandleClose {
  companyId: number;
  handleCloseUpdate: () => void;
  refetchCompanies: () => void;
}

export const UpdateCompanyForm: React.FC<HandleClose> = ({
  companyId,
  handleCloseUpdate,
  refetchCompanies,
}): JSX.Element => {

  const { t } = useTranslation(['main']);

  return(
    <Box>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingBottom: '0',
        }}
      >
        {t('main:companies.updateCompanyTitle')}
        <IconButton
          aria-label='close'
          onClick={handleCloseUpdate}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <CompanyAddresses
        companyId={companyId}
        handleCloseUpdate={handleCloseUpdate}
        refetchCompanies={refetchCompanies}
      />
      <AlternativeAddresses companyId={companyId} />
    </Box>
  );
};
