import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Grid, Snackbar } from '@mui/material';
import theme from '../../theme';
import { ProductInfo } from './Productinfo';
import { useNavigate, useParams } from 'react-router-dom';
import { useProduct } from '../../selectors/selectors';
import { ProductFilters } from './ProductFilters';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { ProductService } from '../../types/Product';
import { ProductData } from './ProductData';
import { ExternalProductLink } from './ExternalProductLink';
import { Set } from './Set';
import NewSet from './NewSet';
import { RowData, SetInfo, SetTotals, SetErrorMessage } from './interfaces';
import ClientService from '../../services/ClientService';

type SaveData = {
  product_id: number | undefined;
  service_id: number | undefined;
  supplier_id: number | undefined;
  order_id?: number | undefined;
  material_number: string | undefined;
  delivery_date: string | undefined;
  sets: any[] | undefined;
};

const sets = [
  new NewSet(1),
];

export const ProductConfigurationPage: React.FC = () => {
  const { t } = useTranslation(['main']);
  const { id } = useParams();
  const { data: product } = useProduct(id as string);
  const navigate = useNavigate();
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(null);
  const [palServiceId, setPalServiceId] = useState<number | undefined>();
  const [materialNumber, setMaterialNumber] = useState<string>('');
  const [palService, setPalService] = useState<ProductService | undefined>(product?.services[0]);
  const [allSets, setAllSets] = useState(sets);
  const [setIdCount, setSetIdCount] = useState(1);
  const [setsInfo, setSetsInfo] = useState<SetInfo[]>([]);
  const [setsTotals, setSetsTotals] = useState<any[]>([]);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [itemPrice, setItemPrice] = useState<number>(0);
  const [errorMessagesRows, setErrorMessagesRows] = useState<SetErrorMessage[] | string>();
  const [errorMessagesTotals, setErrorMessagesTotals] = useState<string>();
  const isButtonDisabled = useMemo(() => (!deliveryDate || !palService || !!errorMessagesRows || !!errorMessagesTotals), [deliveryDate, palService, errorMessagesRows, errorMessagesTotals]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>(`${t('main:configurationPage.successfulAddOrder')}`);

  const addNewSet = () => {
    setAllSets([
      ...allSets,
      new NewSet(setIdCount + 1),
    ]);
    setSetIdCount(setIdCount + 1);
  };

  const updateSet = async (setId: string | number, rowsFromSet: RowData[], offcuts: number[]) => {

    const newSetsInfo = [
      ...setsInfo,
    ];

    const index = newSetsInfo.findIndex(({ id }) => id === setId);

    newSetsInfo[index === -1 ? newSetsInfo.length : index] = {
      offcuts: offcuts,
      id: setId,
      cutting: rowsFromSet,
    };

    setSetsInfo(newSetsInfo);
  };

  const addToCard = async () => {

    const sets = palService?.product_service_group.name !== 'MEGA' ? (setsInfo
      .map(({ id, offcuts, cutting }) => ({
        id,
        offcut: offcuts.reduce((acc, cur) => acc + cur, 0),
        cutting: cutting.filter(({ rollCount, rollWidth, rollHeight, materialNumber }) => (rollCount && rollWidth && rollHeight) || (rollCount && rollWidth && rollHeight && materialNumber))
          .map(({ rollCount: rolls, rollWidth: width, rollHeight: meters, materialNumber: material_number }) => ({ width, rolls, meters, material_number })),
      }))
      .filter(set => set.cutting.length)) : (setsInfo
      .map(({ id, cutting }) => ({
        id,
        cutting: cutting.filter(({ rollCount, rollWidth, rollHeight, materialNumber }: RowData) => (rollCount && rollWidth && rollHeight) || (rollCount && rollWidth && rollHeight && materialNumber))
          .map(({ rollCount: rolls, rollWidth: width, rollHeight: meters, materialNumber: material_number }) => ({ width, rolls, meters, material_number })),
      }))
      .filter(set => set.cutting.length));

    const data: SaveData = {
      product_id: product?.id,
      service_id: palService?.id,
      supplier_id: product?.supplier?.id,
      material_number: materialNumber,
      delivery_date: deliveryDate?.format('YYYY-MM-DD'),
      sets: sets,
    };

    const response = await ClientService.calculateAndSaveCutting(data);
    setSnackBarMessage(`${t('main:configurationPage.successfulAddOrder')}`);
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
    navigate('/orders');
  };

  useEffect(() => {
    const service = product?.services?.find(service => service.id === palServiceId);
    setPalService(service);
  }, [palServiceId]);

  useEffect(() => {
    (async () => {
      const data = palService?.product_service_group.name !== 'MEGA' ? (setsInfo
        .map(({ id, offcuts, cutting }) => ({
          id,
          offcut: offcuts.reduce((acc, cur) => acc + cur, 0),
          cutting: cutting.filter(({ rollCount, rollWidth, rollHeight }) => rollCount && rollWidth && rollHeight)
            .map(({ rollCount: rolls, rollWidth: width, rollHeight: meters }) => ({ width, rolls, meters })),
        }))
        .filter(set => set.cutting.length)) : (setsInfo
        .map(({ id, cutting }) => ({
          id,
          cutting: cutting.filter(({ rollCount, rollWidth, rollHeight }) => rollCount && rollWidth && rollHeight)
            .map(({ rollCount: rolls, rollWidth: width, rollHeight: meters }) => ({ width, rolls, meters })),
        }))
        .filter(set => set.cutting.length));

      const calculateData = {
        product_id: product?.id,
        service_id: palService?.id,
        supplier_id: product?.supplier?.id,
        sets: data,
      };

      try {
        if(data.length){
          const response = await ClientService.calculateCutting(calculateData);
          setSetsTotals(response.data);
          setErrorMessagesRows('');
          setErrorMessagesTotals('');
        } else {
          setSetsTotals([]);
          setErrorMessagesRows('');
          setErrorMessagesTotals('');
        }
      } catch (error: any) {
        if(error?.response?.data?.errors?.sets[0]?.cutting?.rows){
          setErrorMessagesRows(error?.response?.data?.errors?.sets);
        } else {
          setErrorMessagesTotals(error?.response?.data?.errors?.sets[0]?.cutting?.total[0]);
        }
      }
    })();
  }, [setsInfo]);

  useEffect(() => {
    if(setsTotals.length){
      const totalPrice = setsTotals.reduce((acc, total) => acc + total.totals.total_price, 0);
      const price = setsTotals.map(total => total.totals.price);
      setTotalValue(totalPrice);
      setItemPrice(price[0]);
    }
  }, [setsTotals]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box
      px='70px'
      sx={{
        background: theme.custom.whiteBackground,
        paddingX: {
          xs: '10px',
        },
        width: {
          xs: '94%',
          md: '85%',
        },
      }}
      mx='auto'
      maxWidth='lg'
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        gap={theme.spacing(4)}
        p={theme.spacing(5, 0)}
      >
        <ProductInfo product={product} />
        <ProductFilters
          product={product}
          deliveryDate={deliveryDate}
          setDeliveryDate={setDeliveryDate}
          palServiceId={palServiceId}
          setPalServiceId={setPalServiceId}
          setMaterialNumber={setMaterialNumber}
        />
        {allSets.map((set: NewSet, index) => (
          <Set
            key={set.id}
            setId={set.id}
            count={index + 1}
            updateSet={updateSet}
            palService={palService}
            deliveryDate={deliveryDate}
            errorMessagesRows={errorMessagesRows}
            errorMessagesTotals={errorMessagesTotals}
            total={setsTotals.length ? setsTotals.find((total: SetTotals) => total.id === set.id) : undefined}
          />
        ))}
        <Grid
          container
          sx={{
            display:'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            sx={{
              display:'flex',
              justifyContent:'flex-end',
              marginRight: '3rem',
              fontFamily: 'ArialRounded',
              fontSize: '20px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.8',
              letterSpacing: 'normal',
              color: '#212b36',
              marginBottom: '1rem',
            }}
          >
            {`${totalValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`}
          </Grid>
          <Grid
            container
            sx={{
              display:'flex',
              flexDirection: 'row',
              justifyContent:'space-between',
            }}
          >
            <Button
              variant="actionButtonNewSet"
              onClick={addNewSet}
            >
              {t('main:configurationPage.newSet')}
            </Button>
            <Grid>
              {`${itemPrice}€/qm`}
              <Button
                variant="actionButtonGreen"
                sx={{ marginRight: '3rem', marginLeft: '1rem' }}
                onClick={addToCard}
                disabled={isButtonDisabled}
              >
                {t('main:configurationPage.addToCard')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {openSnackBar &&
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="success" sx={{ width: '100%', backgroundColor: '#95cdb1' }}>
            {snackBarMessage}
          </Alert>
        </Snackbar>}
    </Box>
  );
};


