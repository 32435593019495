import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import { Orders } from '../../types/Orders';
import { useTranslation } from 'react-i18next';
import { HeadCell } from './interfaces';
import { DataTable } from './DataTable';
import { useOrders, useOrdersTotalQm } from '../../selectors/selectors';

const returnColumnNames = (t: TFunction<'main'[]>): GridColDef[] => [
  {
    field: 'article',
    headerName: `${t('main:orders.columnProduct')}`,
    flex: 2,
    renderCell: renderCellTypography,
  },
  {
    field: 'orderedOn',
    headerName: `${t('main:orders.columnOrderedOn')}`,
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: `${t('main:orders.columnQuantity')}`,
    flex: 1,
  },
  {
    field: 'price',
    headerName: `${t('main:orders.columnPrice')}`,
    flex: 1,
  },
  {
    field: 'orderNumber',
    headerName: `${t('main:orders.columnOrderedNumber')}`,
    flex: 1,
  },
  {
    field: 'orderedFrom',
    headerName: `${t('main:orders.columnOrderedFrom')}`,
    flex: 1,
    renderCell: renderCellIcon,
  },
];

const renderCellTypography = (props: GridRenderCellParams<string>) => {
  const { value } = props;

  return (
    <Typography sx={{ fontSize: '16px' }}>
      {value}
    </Typography>);
};

const renderCellIcon = (props: GridRenderCellParams<string>) => {
  const { value } = props;

  return (
    <>
      <Typography>
        {value}
      </Typography>
      <IconButton>
        <KeyboardArrowDownIcon />
      </IconButton>
    </>
  );
};

const returnEmptyOrder = (supplierName: string): Orders => ({
  data: {
    [supplierName]: [
      {
        delivery_date: '',
        from_user_id: 0,
        id: 1,
        items: [],
        material_number: '',
        meters_total: 0,
        offcut: 0,
        order_number: '',
        price: 0,
        product_id: 0,
        product_name: '',
        rolls_total: 0,
        service_id: 0,
        supplier: '',
        supplier_id: 0,
        total_price: 0,
        total_sqm: 0,
        total_sqm_all: 0,
        updated_at: '',
        user: '',
        width_total: 0,
      },
    ],
  },
});

type OrdersBlockProps = {
  supplierId: number;
  supplierName: string;
  ordersType: string;
  companyId: number;
};

export const OrdersBlock = ({
  supplierId,
  supplierName,
  ordersType,
  companyId,
}: OrdersBlockProps) => {
  const { t } = useTranslation(['main']);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersData, setOrdersData] = useState<Orders>();
  const [totalQm, setTotalQm] = useState(0);
  const [cancelArticle, setCancelArticle] = useState(0);
  const {
    data: allOrdersData,
    refetch: refetchOrdersData,
  } = useOrders(supplierId, companyId, ordersType, page, perPage);
  const { data: totalQmData, refetch: refetchTotalQmData } = useOrdersTotalQm(
    supplierId,
    companyId,
    ordersType,
  );

  const headCells: HeadCell[] = [
    {
      align: 'center',
      id: 'product_name',
      numeric: false,
      label: `${t('main:orders.columnProduct')}`,
    },
    {
      align: 'center',
      id: 'updated_at',
      numeric: false,
      label: `${t('main:orders.columnOrderedOn')}`,
    },
    {
      align: 'center',
      id: 'total_sqm_all',
      numeric: true,
      label: `${t('main:orders.columnQuantity')}`,
    },
    {
      align: 'center',
      id: 'price',
      numeric: true,
      label: `${t('main:orders.columnPrice')}`,
    },
    {
      align: 'center',
      id: 'order_number',
      numeric: false,
      label: `${t('main:orders.columnOrderedNumber')}`,
    },
    {
      align: 'center',
      id: 'user',
      numeric: false,
      label: `${t('main:orders.columnOrderedFrom')}`,
    },
  ];

  useEffect(() => {
    if (allOrdersData) {
      setOrdersData(allOrdersData);
      setOrdersCount(allOrdersData?.meta?.total ?? 0);
    } else {
      setOrdersData(returnEmptyOrder(supplierName));
      setOrdersCount(0);
    }
  }, [allOrdersData]);

  useEffect(() => {
    refetchTotalQmData();
  }, [companyId]);

  useEffect(() => {
    refetchOrdersData();
  }, [supplierId, companyId, page, perPage]);

  useEffect(() => {
    if (cancelArticle > 0) {
      refetchOrdersData();
      refetchTotalQmData();
    }
  }, [cancelArticle]);

  useEffect(() => {
    if (totalQmData && totalQmData.data.total_sqm > 0) {
      setTotalQm(totalQmData.data.total_sqm);
    } else {
      setTotalQm(0);
    }
  }, [totalQmData]);

  return (
    <Box>
      {ordersData &&
        Object.entries(ordersData.data).map((data, index) => (
          <Box key={`ordersBySupplier_${index}`}>
            <Typography
              sx={{
                mt: '20px',
                wordWrap: 'break-word',
              }}
              variant='bigTitle'
              component='h2'
            >
              {supplierName}
            </Typography>
            <DataTable
              key={`${data[0].split(' ').join('')}_${index}`}
              rows={data[1]}
              columns={returnColumnNames(t)}
              headCells={headCells}
              pageSize={perPage}
              setPageSize={setPerPage}
              ordersTotal={ordersCount}
              rowsPerPageOptions={[5, 10]}
              disableSelectionOnClick={true}
              qm={totalQm}
              page={page}
              onPageChange={(value: number) => setPage(value)}
              supplierid={supplierId}
              ordersType={ordersType}
              onCancelArticle={setCancelArticle}
              refetchOrdersData={refetchOrdersData}
            />
          </Box>
        ))}
    </Box>
  );
};
