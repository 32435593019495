import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { HeadCell } from '../interfaces';
import theme from '../../../theme';

type TableHeadType = {
  headCells: HeadCell[]
}

export const TableHeadNoOrders = (props: TableHeadType) => {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.palette.primary.lightGray }}>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
          >
            <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
