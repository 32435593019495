import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ProductCalculator } from '../../pages/ProductConfigurationPage/ProductCalculator';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import { useProduct } from '../../selectors/selectors';
import { useParams } from 'react-router-dom';
import { ProductService } from '../../types/Product';
import { Dayjs } from 'dayjs';
import ProductTableRow from '../../pages/ProductConfigurationPage/ProductTableRow';
import {
  RowData,
  RollData,
  SetTotals,
  SetErrorMessage,
} from '../../pages/ProductConfigurationPage/interfaces';
import roll from '../../assets/rolle.png';
import { LargeRoll } from './LargeRoll';
import { multiplyRows, addOffcut } from '../../utils/setsFunctions';

const createProductTableRows = () => [
  new ProductTableRow(1, '', 0, 0, 0, 0),
  new ProductTableRow(2, '', 0, 0, 0, 0),
  new ProductTableRow(3, '', 0, 0, 0, 0),
  new ProductTableRow(4, '', 0, 0, 0, 0),
  new ProductTableRow(5, '', 0, 0, 0, 0),
];

type SetProps = {
  count: number;
  deliveryDate: Dayjs | null;
  palService: ProductService | undefined;
  setId: string | number;
  total: SetTotals;
  errorMessagesRows?: SetErrorMessage[] | string;
  errorMessagesTotals?: string;
  updateSet: (
    setId: string | number,
    rowsFromSet: RowData[],
    offcuts: number[]
  ) => void;
};

export const Set: React.FC<SetProps> = ({
  count,
  deliveryDate,
  palService,
  setId,
  total,
  errorMessagesRows,
  errorMessagesTotals,
  updateSet,
}) => {
  const { t } = useTranslation(['main']);
  const { id } = useParams();
  const { data: product } = useProduct(id as string);
  const productTableRows = useMemo(createProductTableRows, []);
  const [allRolls, setAllRolls] = useState<RollData[]>([]);

  const updateRowInCalculator = (rows: ProductTableRow[]) => {
    const newRows = multiplyRows(rows);

    let usableWidth = 0;
    if (product) {
      usableWidth = product.services[0].usable_width;
    }

    const rollsWithOffcut = addOffcut(newRows, usableWidth);

    const offcuts = rollsWithOffcut
      .map((roll) =>
        roll.rows
          .filter((row) => row.materialNumber === 'offcut')
          .map((row) => row.rollWidth)
      )
      .flat();

    updateSet(setId, rows, offcuts);
    setAllRolls(rollsWithOffcut);
  };

  const linearMM = allRolls[0]?.rollHeight;
  const thicknessInMM = (product && product?.thickness / 1000) || 150 / 1000;
  const coreRadiusInMM = 38;
  const rollDiameter =
    linearMM &&
    2 *
      Math.sqrt(
        coreRadiusInMM * coreRadiusInMM +
          (linearMM * 1000 * thicknessInMM * 0.95) / 3.1415926
      );

  return (
    <>
      <Grid
        display="inline-flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ marginTop: '2rem' }}
      >
        <Typography variant="h5" component="h5">
          {`${t('main:configurationPage.title')} ${count}`}
        </Typography>
        {errorMessagesRows && typeof errorMessagesRows !== 'string' && (
          <Grid
            item
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            {errorMessagesRows.map(({ cutting, id }) =>
              cutting.rows.map(({ rownumber, messages }, index: number) => (
                <Typography
                  key={index}
                  variant="body3Dark"
                  fontSize="1.5rem"
                  sx={{ color: theme.palette.primary.red }}
                >
                  {`Set №${id + 1}, Row №${rownumber + 1} - ${messages[0][0]}`}
                </Typography>
              ))
            )}
          </Grid>
        )}
        {errorMessagesTotals && (
          <Grid
            item
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            {
              <Typography
                variant="body3Dark"
                sx={{ color: theme.palette.primary.red }}
              >
                {`${errorMessagesTotals}`}
              </Typography>
            }
          </Grid>
        )}
      </Grid>
      <Divider color={theme.custom.dividerColor} />
      <Grid
        container
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
        gap="2%"
      >
        <Grid
          item
          xs={12}
          lg={7}
          sx={{
            marginTop: {
              xs: '20px',
              md: '0',
            },
          }}
        >
          <ProductCalculator
            key={setId}
            setId={setId}
            product={product}
            palService={palService}
            deliveryDate={deliveryDate}
            productTableRowsOrder={productTableRows}
            buttonText={t('main:configurationPage.addToCard')}
            hideSaveButton={true}
            hideCancelButton={true}
            hideAddRowButton={false}
            updateRowInSet={updateRowInCalculator}
            isInOrders={false}
            numberOfRolls={allRolls.length === 0 ? 1 : allRolls.length}
            totalsForSet={total}
          />
        </Grid>
        <Divider
          color={theme.custom.dividerColor}
          orientation="vertical"
          flexItem
        />
        <Grid item xs={12} lg={4}>
          <Typography
            sx={{
              margin: '2px 239px 23px 21px',
              fontFamily: 'Heebo',
              fontSize: '18px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              textAlign: 'center',
              color: '#454f5b',
            }}
          >
            {t('main:configurationPage.priceTier')}
          </Typography>
          <Divider
            color={theme.custom.dividerColor}
            sx={{
              marginTop: {
                xs: '0',
                lg: '24px',
              },
              width: '100%',
            }}
          />
          <Grid container>
            <Grid
              container
              my={1}
              spacing={2}
              marginTop="none"
              sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}
            >
              {product?.prices.map((price) => (
                <Grid
                  container
                  key={price.id}
                  direction="column"
                  flexBasis="20%"
                >
                  <Grid item xs={2} my={1}>
                    <Typography sx={{ fontSize: 10 }}>
                      {`${price?.quantity?.toLocaleString('de-DE')} qm`}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} my={1}>
                    <Typography variant="body3Dark" sx={{ fontSize: 14 }}>
                      {parseFloat(price?.price).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 4,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid sx={{ display: 'inline-flex' }}>
              <Avatar
                alt="Roll"
                src={roll}
                sx={{ width: '200px', height: '200px', marginLeft: '-2rem' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderTop: 'solid 1px #979797',
                  borderBottom: 'solid 1px #979797',
                  marginTop: '25px',
                  height: '128px',
                  width: '175px',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body3Dark">
                  {t('main:configurationPage.rollDiameter')}
                </Typography>
                {rollDiameter && (
                  <Typography variant="body3Dark" sx={{ marginTop: '5px' }}>
                    {`${rollDiameter.toFixed(2).replace('.', ',')} mm`}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              display="inline-flex"
              alignItems="center"
              my={1}
              marginTop="1rem"
            >
              <Typography variant="body3Dark">
                {t('main:labelDetails.usableWidth')}
              </Typography>
              <Typography
                variant="body3Dark"
                textAlign="center"
                marginLeft="2rem"
              >
                {`${product?.services[0]?.usable_width?.toLocaleString(
                  'de-DE'
                )} mm`}
              </Typography>
            </Grid>
            {!errorMessagesRows &&
              product &&
              allRolls.map(
                (roll: RollData) =>
                  palService?.product_service_group.name !== 'MEGA' &&
                  roll.rollWidth > 0 && (
                    <LargeRoll
                      key={roll.key}
                      rows={roll.rows}
                      total={product?.services[0]?.usable_width}
                      service={palService?.product_service_group.name}
                    />
                  )
              )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
