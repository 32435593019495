import { ArrowForward, ArrowDownward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import theme from '../../theme';
import { useAuth } from '../../contexts/AuthContext';

type LandingPageBoxType = {
  src: string;
  alt: string;
  titleEn: string;
  subtitleEn: string;
  titleDe: string;
  subtitleDe: string;
};

type Props = {
  data: LandingPageBoxType[];
};

export const LandingPageBoxes: React.FC<Props> = ({ data }: Props) => {
  const { lang } = useAuth();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        justifyContent: 'center',
        alignItems: 'center',
      }}
      gap={theme.spacing(3)}
    >
      {data.map((box, index) => (
        <Box
          key={index}
          position="relative"
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              p: theme.spacing(5, 5),
              boxShadow: '0 8px 20px 0 rgba(224, 224, 224, 0.5)',
              width: {
                xs: '75%',
                md: '80%',
                lg: '300px',
                xl: '400px',
              },
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={box.src}
              alt={box.alt}
              width="120px"
              height="120px"
              style={{
                marginBottom: theme.spacing(5),
              }}
            />
            <Typography variant="h6" textTransform="uppercase">
              {lang === 'en' ? box.titleEn : box.titleDe}
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                color: 'primary.grey',
                marginTop: '2rem',
              }}
            >
              {lang === 'en' ? box.subtitleEn : box.subtitleDe}
            </Typography>
          </Box>
          {data.length - 1 > index && (
            <Box
              sx={{
                display: 'flex',
                zIndex: 2,
                position: 'absolute',
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                border: '1px solid',
                justifyContent: 'center',
                alignItems: 'center',
                top: {
                  xs: '100%',
                  md: '50%',
                },
                right: {
                  xs: '50%',
                  md: '0px',
                },
                transform: {
                  xs: 'translate(45%, -50%)',
                  sm: 'translate(50%, -50%)',
                  md: 'translate(70%, -50%)',
                },
                background: '#fff',
                color: theme.custom.borderGrey,
                borderColor: theme.custom.borderGrey,
              }}
            >
              {matches ? <ArrowDownward /> : <ArrowForward />}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
