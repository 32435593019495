import React from 'react';
import { Box, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import theme from '../../../theme';
import { SupplierForm } from '../Components/SupplierForm';

interface HandleClose {
  companyId: number;
  handleClose?: (() => void) | undefined;
}

export const EditSupplierForm: React.FC<HandleClose> = ({
  companyId,
  handleClose,
}): JSX.Element => {
  const { t } = useTranslation(['main']);

  return (
    <Box
      display='flex'
      flexDirection='column'
      padding='2rem'
      gap={theme.spacing(4)}
    >
      <DialogTitle
        sx={{
          padding: '0px',
        }}
      >
        {t('main:profile.supplierTitle')}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <SupplierForm companyId={companyId} handleClose={handleClose} />
    </Box>
  );
};
