import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { Grid, Typography } from '@mui/material';

type ProductDataType = {
  overline: string;
  title: string;
}

type ProductDataPropsType = {
  face: string;
  glue: string;
  back: string;
}

export const ProductData: React.FC<ProductDataPropsType> = props => {
  const { face, glue, back } = props;
  const { lang } = useAuth();
  const { t } = useTranslation(['main']);
  const [productData, setProductData] = useState<ProductDataType[]>([]);

  useEffect(() => {
    setProductData([
      {
        overline: t('main:configurationPage.productDataLabelMaterial'),
        title: face,
      },
      {
        overline: t('main:configurationPage.productDataAdhesive'),
        title: glue,
      },
      {
        overline: t('main:configurationPage.productDataUnderlayMaterial'),
        title: back,
      },
    ]);
  }, [lang]);


  return (
    <>
      {productData &&
        <Grid
          container
          direction='row'
          justifyContent={'space-evenly'}
          sx={{
            marginTop: {
              xs: '3px',
            },
          }}
          spacing={2}
        >
          {productData.map((data, index) => (
            <Grid
              item
              xs={4}
              key={index}
              sx={{
                flexDirection: {
                  xs: 'row',
                  md: 'column',
                },
              }}
            >
              <Typography
                variant='body4Light'
                component='p'
              >
                {data.overline}
              </Typography>
              <Typography
                sx={{
                  overflowWrap: 'normal',
                }}
              >
                {data.title}
              </Typography>
            </Grid>
          ))}
        </Grid>}
    </>
  );
};
