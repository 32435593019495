import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../theme';
import { useUserPermissions } from '../hooks/useUserPermissions';

type Props = {
  permission: string;
  message?: string | null;
  children: React.ReactNode;
};

export const RestrictedComponent: React.FC<Props> = ({ permission, message, children }) => {
  const isPermitted = useUserPermissions(permission);

  if(isPermitted) {
    return (
      <>
        { children }
      </>
    );
  }

  if(message) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={theme.custom.maxContentPageWidth}
        mx="auto"
        justifyContent="center"
        gap={theme.spacing(5)}
        p={theme.spacing(5, 0)}
      >
        <Typography
          variant="h5"
          textAlign="center"
        >
          { message }
        </Typography>
      </Box>
    );
  }
  return null;
};
