import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { t } from 'i18next';
import { TableHeadNoOrders } from './tableComponents/TableHeadNoOrders';
import { HeadCell } from './interfaces';
import theme from '../../theme';

type TableHeadType = {
  headCells: HeadCell[]
}

export const NoOrdersTable = (props: TableHeadType) => {
  const { headCells } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label='table'>
        <TableHeadNoOrders headCells={headCells} />
        <TableBody>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope='row' colSpan={6} sx={{ textAlign: 'center', fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.darkGrey }}>
              {t('main:orders.noOrders')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
