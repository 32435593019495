import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Page = {
  link: string;
  pageTitle: string;
}

type NavigationItemProps = {
  page: Page;
}

export const NavigationItem = ({ page }: NavigationItemProps) => {
  const { t } = useTranslation(['main']);

  return (
    <Link to={page.link}>
      <Typography variant='navigationLink'>
        {t(`${page.pageTitle}`)}
      </Typography>
    </Link>
  );
};
