import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table, TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid/Grid';
import OrdersRow from './OrdersRow';
import { CellData, HeadCell, Order } from './interfaces';
import EnhancedTableHead from './tableComponents/EnhancedTableHead';
import { getComparator, stableSort } from './tableComponents/tableUtils';
import ClientService from '../../services/ClientService';
import { useNavigate } from 'react-router';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { ORDERS_TYPE_AWAITING, ORDERS_TYPE_NEW } from '../../utils/constants';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { SingleOrder } from '../../types/Orders';
import { NoOrdersTable } from './NoOrdersTable';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { StyledInput } from '../../ui-components/StyledInput';


type TableProps = {
  rows: SingleOrder[];
  columns: GridColDef[];
  headCells: HeadCell[];
  pageSize: number;
  setPageSize: (value: number) => void;
  ordersTotal: number;
  rowsPerPageOptions: number[];
  disableSelectionOnClick: boolean;
  qm: number;
  onPageChange: (value: number) => void;
  page: number;
  supplierid: number;
  ordersType: string;
  onCancelArticle: (value: number) => void;
  refetchOrdersData: () => void;
};

export const DataTable = (props: TableProps) => {
  const { rows, headCells, qm, onPageChange, page, pageSize, setPageSize, ordersTotal, supplierid, ordersType, onCancelArticle, refetchOrdersData } = props;
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof CellData>('order_number');
  const [rowsWithCheck, setRowsWithCheck] = useState<number[]>([]);
  const [materialNumber, setMaterialNumber] = useState<string>('');
  const { user } = useAuth();

  const editShoppingCartPermission = useUserPermissions('edit_shopping_cart');
  const readAllShoppingCart = useUserPermissions('read_all_shopping_cart');

  const disabledInputs = (ordersType === ORDERS_TYPE_NEW && editShoppingCartPermission && !readAllShoppingCart) ? false : true;
  const hideSaveButton = (ordersType === ORDERS_TYPE_NEW && editShoppingCartPermission && !readAllShoppingCart) ? false : true;
  const hideAddRowButton = (ordersType === ORDERS_TYPE_NEW && !readAllShoppingCart) ? false : true;
  const hideCancelButton = ((ordersType === ORDERS_TYPE_NEW || ordersType === ORDERS_TYPE_AWAITING) && !readAllShoppingCart) ? false : true;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CellData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    onPageChange(1);
  };

  const handleSendOrder = async () => {
    try {
      await ClientService.sendOrder(supplierid, rows.filter((row: SingleOrder) => !rowsWithCheck.includes(row.id))
        .map((rowsToSent: SingleOrder) => rowsToSent.id));
      refetchOrdersData();
      navigate('/success');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      mb={10}
      sx={{
        marginTop: {
          xs: '15px',
          sm: '0px',
        },
      }}
    >
      {ordersType === ORDERS_TYPE_NEW &&
        <Grid container justifyContent='flex-end' mb='2px'>
          <Grid item py='12px'>
            <Typography variant='yellowBackground'>
              {`${t('main:orders.totalSQM')} ${qm.toLocaleString('de-DE')} ${t('main:orders.qm')}`}
            </Typography>
          </Grid>
        </Grid>}
      {ordersTotal > 0 &&
        <Box
          sx={{
            width: '100%',
            boxShadow: '0 6px 8px 0 rgba(164, 164, 164, 0.21)',
            backgroundColor: theme.custom.whiteBackground,
          }}
        >
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <EnhancedTableHead headCells={headCells} order={order} onRequestSort={handleRequestSort} orderBy={orderBy} />
              {qm !== 0 && qm && rows && user &&
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .map((row, index) => (
                      <OrdersRow
                        key={`${row.order_number}_${index}`}
                        row={row}
                        hideSaveButton={hideSaveButton}
                        hideAddRowButton={hideAddRowButton}
                        disabledInputs={disabledInputs}
                        hideCancelButton={hideCancelButton}
                        onCancelArticle={onCancelArticle}
                        refetchOrdersData={refetchOrdersData}
                        rowsWithCheck={rowsWithCheck}
                        setRowsWithCheck={setRowsWithCheck}
                        user={user}
                        ordersType={ordersType}
                      />
                    ))}
                </TableBody>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={ordersTotal}
            rowsPerPage={pageSize}
            page={page-1}
            labelRowsPerPage={t('main:orders.rowsPerPage')}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <RestrictedComponent permission='submit_order_cart'>
            {!readAllShoppingCart && ordersType === ORDERS_TYPE_NEW &&
              <Grid container justifyContent='space-evenly' alignItems='center' py={5}>
                <Grid
                  item
                  sx={{
                    marginX: '1rem',
                    marginY: {
                      xs: '1rem',
                      sm: '0',
                    },
                  }}
                >
                  <Button
                    variant="actionButtonRed"
                    sx={{
                      width: '12rem',
                    }}
                    component={Link}
                    to="mailto:info@etikettenorder24.com"
                    disabled={true}
                  >
                    {t('main:orders.btnTitle1')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <StyledInput label={`${t('main:configurationPage.orderNumber1')}`} onChange={setMaterialNumber} />
                </Grid>
                <Grid
                  item
                  sx={{
                    marginX: '1rem',
                    marginY: {
                      xs: '1rem',
                      sm: '0',
                    },
                  }}
                >
                  <Button
                    variant="actionButtonGreen"
                    onClick={handleSendOrder}
                  >
                    {t('main:orders.btnTitle2')}
                  </Button>
                </Grid>
              </Grid>}
          </RestrictedComponent>
        </Box>}
      {!ordersTotal && <NoOrdersTable headCells={headCells} /> }
    </Box>
  );
};
