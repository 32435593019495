import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import { InputField } from './InputField';
import { Address } from '../../../types/Address';
import { FormikReactSelect } from './FormikReactSelect';
import { countryOptions } from '../../../utils/constants';

type AddressFieldsProps = {
  addressType: string;
  address?: Address;
  disabled?: boolean;
};

export const AddressFields: React.FC<AddressFieldsProps> = ({
  addressType,
  address,
  disabled,
}) => {
  const { t } = useTranslation(['main']);
  const isDeviceMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display='flex' flexDirection='column' gap={theme.spacing(3)}>
      <Typography variant='body2' component='p'>
        {t<string>(`main:profile.${addressType}Title`)}
      </Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        gap={theme.spacing(2)}
        sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
      >
        <InputField
          name={`${addressType}Street`}
          fullWidth
          disabled={disabled}
        />
        <InputField
          name={`${addressType}StreetNumber`}
          disabled={disabled}
          fullWidth={isDeviceMD}
        />
      </Box>
      <Box display='flex' justifyContent='space-between' gap={theme.spacing(2)} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Box>
          {address && (
            <FormikReactSelect
              name={`${addressType}Country`}
              options={countryOptions}
              initialValue={address.country}
              isDisabled={disabled}
              fullWidth={isDeviceMD}
            />
          )}
        </Box>
        <Box display='flex' gap={theme.spacing(2)} width={'100%'} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <InputField
            name={`${addressType}City`}
            disabled={disabled}
            fullWidth
          />
          <InputField
            name={`${addressType}PostCode`}
            disabled={disabled}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
