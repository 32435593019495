import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../../theme';
import ClientService from '../../../services/ClientService';
import { SnackBar, SnackBarProps } from './SnackBar';
import { Button } from '../../../ui-components/Button';
import { AlternativeAddressFields } from './AlternativeAddressFields';
import { AddAlternativeAddressModal } from './AddAlternativeAddressModal';
import { useCompany } from '../../../selectors/selectors';
import { Address } from '../../../types/Address';

type Props = {
  companyId: number;
};

export const AlternativeAddresses: React.FC<Props> = ({ companyId }) => {
  const { t } = useTranslation(['main']);
  const { data: companyData, refetch: reCompanyData } = useCompany(companyId);
  const [openModal, setOpenModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });
  const [alternativeDeliveryAddresses, setAlternativeDeliveryAddresses] =
    useState<Address[]>([]);

  useEffect(() => {
    setAlternativeDeliveryAddresses(
      companyData?.addresses?.filter(
        (address: Address) => address.type_id === 2,
      ),
    );
  }, [companyData]);

  useEffect(() => {
    reCompanyData();
  }, [alternativeDeliveryAddresses]);

  const handleDeleteAddress = async (addressId: number | undefined) => {
    if (addressId) {
      try {
        await ClientService.deleteAddress(addressId);
        reCompanyData();
        setOpenSnackBar({
          openSnackBar: true,
          type: 'success',
          message: t('main:profile.successfulEdit') || '',
        });
      } catch (e) {
        setOpenSnackBar({
          openSnackBar: true,
          type: 'error',
          message: t('main:profile.errorEdit') || '',
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <Box display='flex' flexDirection='column' padding={theme.spacing(1, 4)}>
      <Typography variant='body2' component='p' marginBottom={theme.spacing(-2)}>
        {t('main:profile.deliveryAlternativeAddressTitle')}
      </Typography>
      {alternativeDeliveryAddresses?.map(addressData => (
        <AlternativeAddressFields
          key={addressData.id}
          address={addressData}
          handleDelete={() => handleDeleteAddress(addressData.id)}
        />
      ))}
      <Button
        variant='actionButtonNoBackground'
        onClick={() => setOpenModal(true)}
        sx={{
          p: theme.spacing(1.5, 1),
        }}
      >
        {<AddIcon />}
        {t('main:profile.addDeliveryAlternativeAddress')}
      </Button>
      <AddAlternativeAddressModal
        companyId={companyData?.id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        alternativeDeliveryAddresses={alternativeDeliveryAddresses}
        setAlternativeDeliveryAddresses={setAlternativeDeliveryAddresses}
      />
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </Box>
  );
};
