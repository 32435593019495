import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import theme from '../../theme';
import { useAuth } from '../../contexts/AuthContext';
import { StyledTab } from './StyledTab';
import { StyledTabs } from './StyledTabs';
import { TabPanel } from './TabPanel';
import { ProfileForm } from './Profile/ProfileForm';
import { UsersTable } from './Users/UsersTable';
import { SuppliersTable } from './Suppliers/SuppliersTable';
import { CompaniesTable } from './Companies/CompaniesTable';
import { RightsTable } from './Rights/RightsTable';
import { UserProfileTab } from './UserProfile/UserProfileTab';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { SupplierInfo } from './SupplierInfo/SupplierInfo';
import { useUserLevel } from '../../hooks/useUserLevel';

type ProfilePermissions = {
  editUserData: boolean,
  editSupplierInfo: boolean,
  userManagement: boolean,
  editAlternativeData: boolean,
}

type TabsPanels = {
  component: JSX.Element,
} | undefined;

const returnAllTabsTitle = (companyId: number, profilePermissions: ProfilePermissions, userLevels: Record<string, boolean>): (string | undefined)[] => {
  let tabs:(string | undefined)[] = [
    ...(profilePermissions.editUserData ? ['main:profile.userTabTitle'] : [undefined]),
    ...(userLevels.isUserLevel3 ? ['main:profile.suppliersInfoTabTitle'] : [undefined]),
    ...(companyId && (userLevels.isUserLevel2 || userLevels.isUserLevel3) ? ['main:profile.companyTabTitle'] : [undefined]),
    ...(profilePermissions.editSupplierInfo ? ['main:profile.suppliersTabTitle'] : [undefined]),
    ...(profilePermissions.userManagement ? ['main:profile.usersTabTitle'] : [undefined]),
    ...(profilePermissions.editSupplierInfo ? ['main:profile.companiesTabTitle'] : [undefined]),
    ...(profilePermissions.editUserData ? ['main:profile.rightsTabTitle'] : [undefined]),
  ];
  tabs = tabs.filter( v => v !== undefined);

  return tabs;
};

const returnAllTabPanels = (companyId: number, profilePermissions: ProfilePermissions, userLevels: Record<string, boolean>): TabsPanels[] => {
  let panels:TabsPanels[] = [
    ...(profilePermissions.editUserData ? [{
      component: <UserProfileTab />,
    }] : [undefined]),
    ...(userLevels.isUserLevel3 ? [{
      component: <SupplierInfo companyId={companyId} paperElevation={2} />,
    }] : [undefined]),
    ...(companyId && (userLevels.isUserLevel2 || userLevels.isUserLevel3) ? [{
      component: <ProfileForm companyId={companyId} paperElevation={2} />,
    }] : [undefined]),
    ...(profilePermissions.editSupplierInfo ? [{
      component: <SuppliersTable />,
    }] : [undefined]),
    ...(profilePermissions.userManagement ? [{
      component: <UsersTable />,
    }] : [undefined]),
    ...(profilePermissions.editSupplierInfo ? [{
      component: <CompaniesTable />,
    }] : [undefined]),
    ...(profilePermissions.editUserData ? [{
      component: <RightsTable />,
    }] : [undefined]),
  ];
  panels = panels.filter( v => v !== undefined);

  return panels;
};

export const Profile: React.FC = () => {
  const { t } = useTranslation(['main']);
  const { user } = useAuth();
  const profilePermissions:ProfilePermissions = {
    editUserData: useUserPermissions('edit_user_data'),
    editSupplierInfo: useUserPermissions('edit_supplier_info'),
    userManagement: useUserPermissions('user_management'),
    editAlternativeData: useUserPermissions('edit_alternative_data'),
  };
  const showUserLevels = {
    isUserLevel2: useUserLevel('level_2'),
    isUserLevel3: useUserLevel('level_3'),
  };

  const [tabs, setTabs] = useState<(string | undefined)[]>([]);
  const [tabPanels, setTabPanels] = useState<TabsPanels[]>([]);
  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const companyId = user?.data.userInfo.company_id;
    if(companyId && companyId > 0){
      setTabPanels(returnAllTabPanels(companyId, profilePermissions, showUserLevels));
      setTabs(returnAllTabsTitle(companyId, profilePermissions, showUserLevels));
    }
  }, [user]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxWidth={theme.custom.maxPageContentWidth}
      width='100%'
      mx='auto'
      justifyContent='center'
      gap={theme.spacing(5)}
      p={theme.spacing(5, 0)}
    >
      <Typography variant='pageTitle' component='h1'>
        {t('main:profile.title')}
      </Typography>
      <StyledTabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label='profile tabs'
      >
        {tabs.length && tabs.map( (tabTitle, index) => (
          tabTitle &&
            <StyledTab
              key={`profileTabs_${index}`}
              disableRipple={false}
              label={t(`${tabTitle}`)}
              {...a11yProps(index)}
            />
        ))}
      </StyledTabs>
      {tabPanels.length && tabPanels.map( (panelContent, index) => (
        panelContent &&
          <TabPanel key={`profilePanels_${index}`} index={index} value={value}>
            {panelContent.component}
          </TabPanel>
      ))}
    </Box>
  );
};
