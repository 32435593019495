import React from 'react';
import { Box } from '@mui/system';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { PageBase } from './PageBase';
import { CircularProgress } from '@mui/material';

export const ProtectedPageLayout: React.FC = () => {
  const { user, isAuthLoading, isAuthFinished } = useAuth();

  if(isAuthLoading){
    return(
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user && isAuthFinished) {
    return (
      <Navigate
        to="/login"
        state={{ from: window.location.href }}
      />
    );
  }

  return(
    <PageBase>
      <Navigation />
      <Outlet />
      <Box marginTop="auto">
        <Footer />
      </Box>
    </PageBase>
  );
};

