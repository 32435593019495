import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClientService from '../../services/ClientService';

const getSearchResults = (searchString: string) => {
  try {
    return ClientService.getQuickSearchProductsResults(searchString);
  } catch (e){
    console.log('error');
  }
};

type OptionType = {
  value: string;
  label: string;
};

const loadOptions = async (searchString: string) => {
  const filteredOptions: OptionType[] = [];

  if(searchString) {
    const response = await getSearchResults(searchString);

    if (response) {
      response.forEach(option => {
        filteredOptions.push({
          value: option.id,
          label: option.name,
        });
      });
    }
  }

  return {
    options: filteredOptions,
  };
};

export const QuickSearch: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const [option, onChange] = useState<OptionType | null>(null);

  const wrappedLoadOptions = useCallback<typeof loadOptions>((...args) => loadOptions(...args), []);

  const asyncPaginateStyles = {
    fontFamily: 'Poppins',
    fontSize: '12px',
  };

  useEffect(() => {
    if(option){
      navigate(`/article/${option?.value}`, { state: option });
    }
  }, [option]);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '300px',
        },
      }}
    >
      <AsyncPaginate
        styles={{
          placeholder: baseStyles => ({
            ...baseStyles,
            ...asyncPaginateStyles,
          }),
          loadingMessage: baseStyles => ({
            ...baseStyles,
            ...asyncPaginateStyles,
          }),
          noOptionsMessage: baseStyles => ({
            ...baseStyles,
            ...asyncPaginateStyles,
          }),
          input: baseStyles => ({
            ...baseStyles,
            ...asyncPaginateStyles,
          }),
          option: baseStyles => ({
            ...baseStyles,
            ...asyncPaginateStyles,
            cursor: 'pointer',
          }),
          container: baseStyles => ({
            ...baseStyles,
            zIndex: '10',
          }),
        }}
        placeholder={t('main:quickSearch.selectPlaceholder')}
        loadingMessage={() => t('main:quickSearch.loading')}
        noOptionsMessage={({ inputValue }) => !inputValue ? t('main:quickSearch.typeToSearch') : t('main:quickSearch.noProductsFound')}
        isClearable
        debounceTimeout={300}
        value={option}
        loadOptions={wrappedLoadOptions}
        onChange={onChange}
      />
    </Box>
  );
};
