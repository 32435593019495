import { RowData } from './interfaces';

export default class ProductTableRow implements RowData {
  private static itemId = 0;

  private _itemId: number;
  get itemId () {
    return this._itemId;
  }
  id: number;
  materialNumber: string;
  rollWidth: number;
  rollCount: number;
  rollHeight: number;
  total: number;

  constructor (id:number, materialNumber: string, rollWidth: number, rollCount: number, rollHeight: number, total: number) {
    this._itemId = ++ProductTableRow.itemId;
    this.id = id;
    this.materialNumber = materialNumber;
    this.rollWidth = rollWidth;
    this.rollCount = rollCount;
    this.rollHeight = rollHeight;
    this.total = total;
  }
}
