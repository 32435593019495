import React from 'react';
import { Box, OutlinedInput, OutlinedInputProps } from '@mui/material';
import theme from '../theme';

export type InputProps = {
    icon?: React.ReactElement
} & OutlinedInputProps;

export const InputField: React.FC<InputProps> = ({ icon, ...props }) => (
  <Box 
    display="flex"
    alignItems="center"
    position="relative"
    height="50px"
  >
    {!!icon && 
    <Box
      sx={{
        backgroundColor: 'primary.dark',
        color: '#fff',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
      }}
    >
      {icon}
    </Box>}
    <OutlinedInput  
      {...props} 
      sx={{
        borderStartStartRadius: 0,
        borderBottomLeftRadius: 0,
        ...props.sx,
      }}
    />
  </Box>
);

  
