import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Button,
  Box,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogActions,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import UpdateIcon from '@mui/icons-material/Update';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import BlockSharpIcon from '@mui/icons-material/BlockSharp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import theme from '../../../theme';
import ClientService from '../../../services/ClientService';
import { useCompanies } from '../../../selectors/selectors';
import { NewCompaniesForm } from './NewCompaniesForm';
import { EditSupplierForm } from './EditSupplierForm';
import { UpdateCompanyForm } from './UpdateCompanyForm';
import { StyledCell } from '../StyledCell';
import { TableHeadRow } from '../TableHeadRow';
import { Company } from '../../../types/Company';
import { COMPANY } from '../../../utils/constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.lightGray,
    color: theme.palette.primary.darkGrey,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.custom.whiteBackground,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const CompaniesTable: React.FC = () => {
  const { t } = useTranslation(['main']);
  const [page, setPage] = useState(1);
  const { data: companies, refetch: refetchTableCompanies } =
    useCompanies(page);

  const [openNew, setOpenNew] = useState(false);
  const [editSupplier, setOpenEditSupplier] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState<number | null>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const heads = [
    t('main:companies.id'),
    t('main:companies.name'),
    t('main:companies.companyEmail'),
    t('main:companies.address'),
    t('main:companies.companyTaxId'),
    t('main:companies.labelOrderCode'),
    t('main:companies.actions'),
  ];

  const handleClickOpenNewCompany = () => {
    setOpenNew(true);
  };

  const handleCloseNewCompany = () => {
    setOpenNew(false);
  };

  const handleClickOpenEditSupplier = (id: number) => {
    setOpenEditSupplier(true);
    setCurrentCompanyId(id);
  };

  const handleClickCloseEditSupplier = () => {
    setOpenEditSupplier(false);
    setCurrentCompanyId(null);
  };

  const handleClickOpenUpdate = (id: number) => {
    setOpenUpdate(true);
    setCurrentCompanyId(id);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setCurrentCompanyId(null);
    refetchTableCompanies();
  };

  const handleOpenDelete = (id: number) => {
    setOpenDelete(true);
    setCurrentCompanyId(id);
  };

  const handleOpenBlock = (id: number) => {
    setOpenBlock(true);
    setCurrentCompanyId(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (id: number | null) => {
    if (id) {
      await ClientService.deleteCompany(id);
    }
    refetchTableCompanies();
    setOpenDelete(false);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
    setCurrentCompanyId(null);
  };

  const handleBlock = (id: number | null) => {
    console.log(id);
    setOpenBlock(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '2rem',
        }}
      >
        <Button variant='actionButtonRed' onClick={handleClickOpenNewCompany}>
          {t('main:companies.newCompany')}
        </Button>
      </Box>
      <Paper elevation={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <TableHeadRow heads={heads} />
              </TableRow>
            </TableHead>
            <TableBody>
              {companies &&
                companies.data.map((company: Company) => (
                  <StyledTableRow key={`${COMPANY}-${company.id}`}>
                    <StyledCell text={company.id} />
                    <StyledCell text={company.name} />
                    <StyledCell text={company.email} />
                    <StyledCell
                      text={`${company.street_name} ${company.street_number}; ${company.city}; ${company.country}`}
                    />
                    <StyledCell text={company.tax_id} />
                    <StyledCell text={company.label_order_code} />
                    <StyledTableCell align='center'>
                      <Box>
                        <LocalShippingIcon
                          onClick={() => handleClickOpenEditSupplier(company.id)}
                        />
                        <UpdateIcon
                          onClick={() => handleClickOpenUpdate(company.id)}
                        />
                        <ClearSharpIcon
                          sx={{ color: 'red' }}
                          onClick={() => handleOpenDelete(company.id)}
                        />
                        <BlockSharpIcon
                          onClick={() => handleOpenBlock(company.id)}
                        />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {companies && (
          <TablePagination
            component='div'
            rowsPerPageOptions={[]}
            count={companies.meta.total}
            rowsPerPage={15}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        )}
      </Paper>
      <Dialog
        open={openNew}
        onClose={handleCloseNewCompany}
        maxWidth='md'
        fullScreen={fullScreen}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        <NewCompaniesForm
          handleClose={handleCloseNewCompany}
          refetchTableCompanies={refetchTableCompanies}
        />
      </Dialog>
      <Dialog
        open={editSupplier}
        onClose={handleClickCloseEditSupplier}
        maxWidth='md'
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        {currentCompanyId && (
          <EditSupplierForm
            companyId={currentCompanyId}
            handleClose={handleClickCloseEditSupplier}
          />
        )}
      </Dialog>
      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        {currentCompanyId && (
          <Box>
            <UpdateCompanyForm
              handleCloseUpdate={handleCloseUpdate}
              companyId={currentCompanyId}
              refetchCompanies={refetchTableCompanies}
            />
          </Box>
        )}
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle id='alert-dialog-title'>
          {t('main:companies.deleteAlert')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} sx={{ color: 'black' }}>
            No
          </Button>
          <Button
            onClick={() => handleDelete(currentCompanyId)}
            autoFocus
            sx={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openBlock} onClose={handleCloseBlock}>
        <DialogTitle id='alert-dialog-title'>
          {t('main:companies.blockAlert')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseBlock} sx={{ color: 'black' }}>
            No
          </Button>
          <Button
            onClick={() => handleBlock(currentCompanyId)}
            autoFocus
            sx={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
