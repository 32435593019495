export const getCookie = (name: string) => {
//   const escape = (s: string) => s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
//   const match = document.cookie.match(RegExp(`(?:^|;\\s*)${ escape(name) }=([^;]*)`));
  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${ name }=([^;]*)`));
  return match ? match[1] : null;
};

export const setCookie = (name: string, value: string, seconds = 3600) => {
  let expires = '';
  if (seconds) {
    const date = new Date();
    date.setTime(date.getTime() + (seconds));
    expires = `; max-age=${ date.toUTCString()}`;
  }
  document.cookie = `${name }=${ value || '' }${expires }; path=/`;
};

export const getUniqueValue = (data: any[]) => Array.from(data.reduce((res, item) => res.add(item) && res, new Set()));

// export const getItemsByValue = (data: any[], prop: string, value: any) => data?.filter(item => {
//   console.log('item', item);
//   return item[prop]?.id === value;
// });

export const getTotal = (width: number, height: number, remainingWidth: number ) => {
  let total: number;
  if (remainingWidth >= 0) {
    total = ((width * height) / 1000);
  } else {
    const count = Math.ceil(Math.abs(remainingWidth / width));
    total = ((width * (count + 1) * height) / 1000);
  }
  return total;
};
