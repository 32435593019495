import React, { useContext } from 'react';
import { Box } from '@mui/system';
import { ApiContext } from '../../contexts/ApiContext';
import { SnackbarMessage } from '../../ui-components/SnackbarMessage';
import theme from '../../theme';

export const SnackbarContainer: React.FC = () => {
  const { snackbarMessages, setSnackbarMessages } = useContext(ApiContext);

  return(
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'hidden',
        zIndex: 5, // maybe handle z indexes globally
        pointerEvents: 'none',
        flexDirection: 'column',
        gap: theme.spacing(2),
        pt: theme.spacing(6),
      }}
    >
      {snackbarMessages.map(m => (
        <SnackbarMessage
          key={m.id}
          onClose={id => {
            setSnackbarMessages(msgs => msgs.filter(msg => msg.id !== id));
          }} 
          type={m.type}
          messages={m.messages}
          duration={m.duration}
          id={m.id}
        />
      ))}
    </Box>
  );
};

