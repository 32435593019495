import { Box } from '@mui/material';
import React from 'react';
import { RowData } from '../interfaces';

type RowProps = {
  row: RowData,
  total: number,
}

export const RowLastMega: React.FC<RowProps> = ({
  row,
  total,
}) => (
  <Box
    key={row.key}
    component='span'
    sx={{
      border: 'solid 1px #979797',
      backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.33) 65%, #f2ebfa)',
      backgroundColor: '#979797',
      width: `calc(${row.rollWidth} / ${total} * 100%)`,
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  />
);
