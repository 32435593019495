import React from 'react';
import { useTranslation } from 'react-i18next';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { Orders } from './Orders';

export const OrdersPageWrapper = () => {
  const { t } = useTranslation(['main']);
  return (
    <RestrictedComponent permission='read_company_shopping_cart' message={t('main:errors.notPermitted')}>
      <Orders />
    </RestrictedComponent>
  );
};

