import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Dialog, DialogTitle, DialogActions, useMediaQuery, TablePagination, Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import UpdateIcon from '@mui/icons-material/Update';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import BlockSharpIcon from '@mui/icons-material/BlockSharp';
import theme from '../../../theme';
import { useSuppliers } from '../../../selectors/selectors';
import ClientService from '../../../services/ClientService';
import { StyledCell } from '../StyledCell';
import { TableHeadRow } from '../TableHeadRow';
import { NewSupplierForm } from './NewSupplierForm';
import { UpdateSupplierForm } from './UpdateSupplierForm';
import { Supplier } from '../../../types/Suppliers';
import { SUPPLIER } from '../../../utils/constants';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.lightGray,
    color: theme.palette.primary.darkGrey,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.custom.whiteBackground,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const SuppliersTable: React.FC = () => {

  const { t } = useTranslation(['main']);
  const [page, setPage] = useState(1);
  const { data: suppliers, refetch: refetchTableSuppliers } = useSuppliers();

  const [openNewSupplier, setOpenNewSupplier] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [currentSupplierId, setCurrentSupplierId] = useState<number | null>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const heads =[
    t('main:suppliers.id'),
    t('main:suppliers.supplierName'),
    t('main:suppliers.supplierEmail'),
    t('main:suppliers.address'),
    t('main:suppliers.supplierNumber'),
    t('main:suppliers.actions'),
  ];


  const handleClickOpenNewSupplier = () => {
    setOpenNewSupplier(true);
  };

  const handleCloseNewSupplier = () => {
    setOpenNewSupplier(false);
  };


  const handleClickOpenUpdate = (id: number) => {
    setOpenUpdate(true);
    setCurrentSupplierId(id);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setCurrentSupplierId(null);
  };

  const handleOpenDelete = (id: number) => {
    setOpenDelete(true);
    setCurrentSupplierId(id);
  };

  const handleOpenBlock = (id: number) => {
    setOpenBlock(true);
    setCurrentSupplierId(id);
  };

  const handleDelete = async (id: number | null) => {
    if(id) {
      await ClientService.deleteSupplier(id);
    }
    refetchTableSuppliers();
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
    setCurrentSupplierId(null);
  };

  const handleBlock = (id: number | null) => {
    console.log(id);
    setOpenBlock(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };


  return (
    <>
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '2rem',
        }}
      >
        <Button
          variant='actionButtonRed'
          onClick={handleClickOpenNewSupplier}
        >
          {t('main:suppliers.newSupplier')}
        </Button>
      </Box>
      <Paper elevation={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <TableHeadRow heads={heads} />
              </TableRow>
            </TableHead>
            <TableBody>
              { suppliers && suppliers.data.map((supplier: Supplier) => (
                <StyledTableRow key={`${SUPPLIER}-${supplier.id}`}>
                  <StyledCell text={supplier.id} />
                  <StyledCell text={supplier.name} />
                  <StyledCell text={supplier.email} />
                  <StyledCell 
                    text={`${supplier.street_name}: ${supplier.street_number};
                      ${supplier.city}; ${supplier.country}`} 
                  />
                  <StyledCell text={supplier.supplier_number} />
                  <StyledTableCell align='center'>
                    <Box>
                      <UpdateIcon onClick={() => handleClickOpenUpdate(supplier.id)} />
                      <ClearSharpIcon sx={{ color: 'red' }} onClick={() => handleOpenDelete(supplier.id)} />
                      <BlockSharpIcon onClick={() => handleOpenBlock(supplier.id)} />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {suppliers &&
          <TablePagination
            component='div'
            rowsPerPageOptions={[]}
            count={suppliers.total}
            rowsPerPage={15}
            page={page-1}
            onPageChange={handleChangePage}
          />}
      </Paper>
      <Dialog
        open={openNewSupplier}
        onClose={handleCloseNewSupplier}
        maxWidth='md'
        fullScreen={fullScreen}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        <NewSupplierForm handleClose={handleCloseNewSupplier} refetchTableSuppliers={refetchTableSuppliers} />
      </Dialog>
      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        maxWidth='md'
        fullScreen={fullScreen}
        PaperProps={fullScreen ? {
          style: {
            minHeight: '100%',
            minWidth: '100%',
          },
        } : { 
          style: {
            minHeight: '70%',
            maxHeight: '90%',
            minWidth: '70%',
          },
        }}
      >
        <UpdateSupplierForm handleCloseUpdate={handleCloseUpdate} supplierId={currentSupplierId} refetchSuppliers={refetchTableSuppliers} />
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
      >
        <DialogTitle id='alert-dialog-title'>
          {t('main:suppliers.confirmDelete')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} sx={{ color: 'black' }}>
            No
          </Button>
          <Button onClick={() => handleDelete(currentSupplierId)} autoFocus sx={{ color: 'red' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBlock}
        onClose={handleCloseBlock}
      >
        <DialogTitle id='alert-dialog-title'>
          {t('main:suppliers.confirmBlock')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseBlock} sx={{ color: 'black' }}>
            No
          </Button>
          <Button onClick={() => handleBlock(currentSupplierId)} autoFocus sx={{ color: 'red' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
