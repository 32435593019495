import React from 'react';
import { ButtonProps, Button as ButtonMui, CircularProgress } from '@mui/material';

type Props = {
    isLoading?: boolean
} & ButtonProps;

export const Button: React.FC<Props> = ({ isLoading, ...props }) => (
  <ButtonMui 
    {...props}
  >
    {isLoading ?
      <CircularProgress size="20px" />   
      :
      props.children}
  </ButtonMui>
);

  
