import axios from 'axios';
import Client from './contexts/Client';

const LabelAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}api/`,
  headers: {
    'Content-Type': 'application/json',   
  },
  //withCredentials: true, // send the locale cookie on each request
  messageDisplayedOn: [500], // anything added here would trigger a snack message
  messageOverride: [
    {
      code: 500,
      message: 'System Error',
      type: 'error',
      duration: 10000,
    },
  ],
});
LabelAPI.interceptors.request.use(config => {
  const request = config;

  const authToken = Client.getToken();
  if (authToken) {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }
  }

  return request;

}, Promise.reject);

export default LabelAPI;
