import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import background from '../../assets/contact_bg.jpg';
import mail from '../../assets/mail.svg';
import smartphone from '../../assets/smartphone.svg';

export const Contact: React.FC = () => {
  const { t } = useTranslation(['main']);

  return (
    <Box>
      <Box
        display='flex'
        flexDirection='column'
        maxWidth={1056}
        mx='auto'
        justifyContent='center'
        gap={theme.spacing(5)}
        p={theme.spacing(5, 0)}
      >
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '500px',
            color: 'white',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '2em',
              paddingTop: '20px',
            }}
          >
            {t('main:contact.title')}
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '2em',
              paddingTop: '20px',
            }}
          >
            <Link
              href='mailto:hello@labelorder24.com'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography component='span' sx={{ marginTop: '30px' }}>
                <img src={mail} alt='' style={{ width: '60px' }} />
              </Typography>
              <Typography
                component='span'
                sx={{
                  marginTop: '20px',
                  color: '#fff',
                  fontSize: '1.5rem',
                }}
              >
                hello@labelorder24.com
              </Typography>
            </Link>
            <Box sx={{ marginTop: '70px' }}>
              <img src={smartphone} alt='' style={{ width: '60px' }} />
              <Typography
                sx={{
                  marginTop: '20px',
                  color: '#fff',
                  fontSize: '1.5rem',
                }}
              >
                +49 89 5505 3610
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
