import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';
import { Form, Formik, FormikValues } from 'formik';
import theme from '../../../theme';
import { useAllSuppliers, useCompany } from '../../../selectors/selectors';
import { SupplierCompany } from '../../../types/Suppliers';
import { DropdownWrapper } from '../../../ui-components/DropdownWrapper';
import { Button } from '../../../ui-components/Button';
import { AddressFields } from './AddressFields';
import { InputField } from './InputField';
import ClientService from '../../../services/ClientService';
import { SnackBar, SnackBarProps } from './SnackBar';
import { useUserLevel } from '../../../hooks/useUserLevel';

type SupplierOption = {
  name: string;
  value: string;
};

type Props = {
  companyId: number;
  handleClose?: (() => void) | undefined;
};

const initSupplier = {
  supplierAddressStreet: '',
  supplierAddressStreetNumber: '',
  supplierAddressCity: '',
  supplierAddressPostCode: '',
  supplierAddressCountry: '',
  supplierCustomerNumber: '',
  supplierPhone: '',
  supplierFax: '',
  supplierEmail: '',
  supplierEmailAlternative1: '',
  supplierEmailAlternative2: '',
  supplierEmailAlternative3: '',
};

export const SupplierForm: React.FC<Props> = ({ companyId, handleClose }) => {
  const { t } = useTranslation(['main']);
  const { data: suppliersData } = useAllSuppliers();
  const { data: companyData, refetch: refetchCompanyData } =
    useCompany(companyId);
  const isUserLevel3 = useUserLevel('level_3');
  const validEmail = 'You must provide a valid e-mail';

  const [openSnackBar, setOpenSnackBar] = useState<SnackBarProps>({
    openSnackBar: false,
  });
  const [suppliersOptions, setSuppliersOptions] = useState<SupplierOption[]>();
  const [supplierSelectValue, setSupplierSelectValue] = useState<string>('');
  const [supplierInfo, setSupplierInfo] = useState<SupplierCompany>();
  const [initialValues, setInitialValues] =
    useState<FormikValues>(initSupplier);

  const initSupplierCompany = {
    customer_number: '',
    email: '',
    email_alternative_1: '',
    email_alternative_2: '',
    email_alternative_3: '',
    phone: '',
    fax: '',
    street_name: '',
    street_number: '',
    post_code: '',
    city: '',
    country: '',
  };

  const conditionalSchema = (value: boolean) => {
    if(value) {
      return yup.object({
        supplierAddressStreet: yup.string().max(255),
        supplierAddressStreetNumber: yup.string().max(255),
        supplierAddressCity: yup.string().max(255),
        supplierAddressPostCode: yup.string().max(255),
        supplierAddressCountry: yup.string().max(255),
        supplierCustomerNumber: yup.string().max(255),
        supplierPhone: yup.string().max(255),
        supplierFax: yup.string().max(255),
        supplierEmail: yup.string().email(validEmail).required(),
        supplierEmailAlternative1: yup.string().email(validEmail),
        supplierEmailAlternative2: yup.string().email(validEmail),
        supplierEmailAlternative3: yup.string().email(validEmail),
      });
    }
    return yup.object({
      supplierAddressStreet: yup.string().max(255).required(),
      supplierAddressStreetNumber: yup.string().max(255).required(),
      supplierAddressCity: yup.string().max(255).required(),
      supplierAddressPostCode: yup.string().max(255).required(),
      supplierAddressCountry: yup.string().max(255).required(),
      supplierCustomerNumber: yup.string().max(255).required(),
      supplierPhone: yup.string().max(255).required(),
      supplierFax: yup.string().max(255).required(),
      supplierEmail: yup.string().email(validEmail).required(),
      supplierEmailAlternative1: yup.string().email(validEmail),
      supplierEmailAlternative2: yup.string().email(validEmail),
      supplierEmailAlternative3: yup.string().email(validEmail),
    });
  };

  yup.setLocale({
    mixed: {
      required: t<string>('main:profile.reqField'),
    },
  });

  useEffect(() => {
    setInitialValues(initSupplier);
  }, []);

  useEffect(() => {
    if (suppliersData) {
      setSuppliersOptions(
        suppliersData.map((supp: any) => ({
          name: supp.name,
          value: supp.id,
        })),
      );
      setSupplierSelectValue(suppliersData[0].id.toString());
    }
  }, [suppliersData]);

  useEffect(() => {
    setSupplierInfo(
      companyData?.suppliers.find(
        (sup: any) => sup.supplier_id === +supplierSelectValue,
      ) || initSupplierCompany,
    );
  }, [supplierSelectValue, companyData]);

  useEffect(() => {
    if (supplierInfo?.customer_number !== '') {
      setInitialValues({
        supplierAddressStreet: supplierInfo?.street_name || '',
        supplierAddressStreetNumber: supplierInfo?.street_number || '',
        supplierAddressCity: supplierInfo?.city || '',
        supplierAddressPostCode: supplierInfo?.post_code || '',
        supplierAddressCountry: supplierInfo?.country || '',
        supplierCustomerNumber: supplierInfo?.customer_number || '',
        supplierPhone: supplierInfo?.phone || '',
        supplierFax: supplierInfo?.fax || '',
        supplierEmail: supplierInfo?.email || '',
        supplierEmailAlternative1: supplierInfo?.email_alternative_1 || '',
        supplierEmailAlternative2: supplierInfo?.email_alternative_2 || '',
        supplierEmailAlternative3: supplierInfo?.email_alternative_3 || '',
      });
    } else {
      setInitialValues(initSupplier);
    }
  }, [supplierInfo]);

  const handleSupplierSelect = (value: any) => {
    if (companyData?.suppliers.length) {
      setSupplierInfo(
        companyData?.suppliers.find((sup: any) => sup.supplier_id === value) ||
          initSupplierCompany,
      );
    }
    setSupplierSelectValue(value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <>
      {suppliersOptions && (
        <DropdownWrapper
          data={suppliersOptions}
          onChange={(value: string) => handleSupplierSelect(value)}
          initialValue={supplierSelectValue}
        />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={conditionalSchema(isUserLevel3)}
        enableReinitialize={true}
        onSubmit={async data => {
          try {
            const submitSupplier = {
              street_name: data.supplierAddressStreet,
              street_number: data.supplierAddressStreetNumber,
              city: data.supplierAddressCity,
              post_code: data.supplierAddressPostCode,
              country: data.supplierAddressCountry,
              customer_number: data.supplierCustomerNumber,
              phone: data.supplierPhone,
              fax: data.supplierFax,
              email: data.supplierEmail,
            };
            if (supplierInfo?.id) {
              await ClientService.updateSupplierCompany(
                {
                  email_alternative_1:
                    data.supplierEmailAlternative1 !== ''
                      ? data.supplierEmailAlternative1
                      : null,
                  email_alternative_2:
                    data.supplierEmailAlternative2 !== ''
                      ? data.supplierEmailAlternative2
                      : null,
                  email_alternative_3:
                    data.supplierEmailAlternative3 !== ''
                      ? data.supplierEmailAlternative3
                      : null,
                  ...submitSupplier,
                },
                supplierInfo?.id,
              );
            } else {
              await ClientService.createSupplierCompany({
                supplier_id: supplierSelectValue,
                company_id: companyId,
                email_alternative_1:
                  data.supplierEmailAlternative1 !== ''
                    ? data.supplierEmailAlternative1
                    : undefined,
                email_alternative_2:
                  data.supplierEmailAlternative2 !== ''
                    ? data.supplierEmailAlternative2
                    : undefined,
                email_alternative_3:
                  data.supplierEmailAlternative3 !== ''
                    ? data.supplierEmailAlternative3
                    : undefined,
                ...submitSupplier,
              });
            }
            refetchCompanyData();
            setOpenSnackBar({
              openSnackBar: true,
              type: 'success',
              message: t('main:profile.successfulEdit') || '',
            });
            setTimeout(() => {
              handleClose ? handleClose() : undefined;
            }, 1200);
          } catch (error) {
            console.log(error);
            setOpenSnackBar({
              openSnackBar: true,
              type: 'error',
              message: t('main:profile.errorEdit') || '',
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              display='flex'
              gap={theme.spacing(4)}
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box
                display='flex'
                gap={theme.spacing(4)}
                width={'100%'}
                sx={{
                  flexDirection: {
                    xs: 'column',
                  },
                }}
              >
                <AddressFields
                  addressType={'supplierAddress'}
                  address={supplierInfo}
                  disabled={isUserLevel3}
                />
                <Typography variant='body2' component='p'>
                  {t<string>('main:profile.otherDetails')}
                </Typography>
                <InputField name={'supplierCustomerNumber'} fullWidth disabled={isUserLevel3} />
                <InputField name={'supplierPhone'} fullWidth disabled={isUserLevel3} />
                <InputField name={'supplierFax'} fullWidth disabled={isUserLevel3} />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gap={theme.spacing(4)}
                width={'100%'}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={theme.spacing(3)}
                >
                  <Typography variant='body2' component='p'>
                    {t<string>('main:profile.otherDetails')}
                  </Typography>
                  <InputField name={'supplierEmail'} fullWidth />
                  <InputField name={'supplierEmailAlternative1'} fullWidth />
                  <InputField name={'supplierEmailAlternative2'} fullWidth />
                  <InputField name={'supplierEmailAlternative3'} fullWidth />

                  <Button
                    variant='actionButtonGreen'
                    isLoading={isSubmitting}
                    onClick={submitForm}
                    sx={{
                      p: theme.spacing(1.5, 9),
                      alignSelf: 'center',
                    }}
                  >
                    {t('main:profile.submitButton')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {openSnackBar.openSnackBar && (
        <SnackBar
          type={openSnackBar.type}
          openSnackBar={openSnackBar.openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={openSnackBar.message}
        />
      )}
    </>
  );
};
