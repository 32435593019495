import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.custom.tableTextColor,
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.custom.tableTextColor,
  },
}));
