import React, { useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import { DropdownWrapper } from '../../ui-components/DropdownWrapper';
import { StyledInput } from '../../ui-components/StyledInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from 'dayjs';
import { getCookie, getUniqueValue } from '../../utils/genericFunctions';
import { Product, ProductService } from '../../types/Product';
import { useTranslation } from 'react-i18next';

type ProductFiltersProps = {
  deliveryDate: Dayjs | null;
  setDeliveryDate: (value: Dayjs) => void;
  palServiceId: number | undefined;
  product: Product | undefined;
  setPalServiceId: (value: number) => void;
  setMaterialNumber: (value: string) => void;
}

const minDate = dayjs().add(3, 'd');

export const ProductFilters: React.FC<ProductFiltersProps> = ({
  deliveryDate,
  palServiceId,
  product,
  setDeliveryDate,
  setPalServiceId,
  setMaterialNumber,
}) => {
  const { t } = useTranslation(['main']);
  const locale = getCookie('locale') === 'en' ? 'en-gb': 'de';

  const palOptions = useMemo(
    () => {
      const serviceGroups = (getUniqueValue(product?.services || []) as ProductService[]);

      return serviceGroups.map(pal => ({
        name: `${pal?.product_service_group?.name}${pal?.roll_length ? ` - ${pal?.roll_length}` : ''}`,
        value: `${pal?.id}`,
      }));
    },
    [product],
  );

  const handleSetPalService = (value: number) => {
    setPalServiceId(value);
  };

  return (
    <Grid container spacing={2} >
      <Grid item xs={12} md={3}>
        <StyledInput label={`${t('main:configurationPage.orderNumber1')}`} onChange={setMaterialNumber} />
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <DatePicker
            label={t('main:configurationPage.deliveryDate')}
            value={deliveryDate}
            onChange={newValue => {
              setDeliveryDate(newValue as Dayjs);
            }}
            disablePast
            minDate={minDate}
            renderInput={params => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <DropdownWrapper
          data={palOptions}
          label={`${t('main:configurationPage.service')}`}
          onChange={value => handleSetPalService(+value)}
          initialValue={palServiceId?.toString() || ''}
        />
      </Grid>
    </Grid>
  );
};
